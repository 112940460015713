// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "@angular/material/theming";
// Plus imports for other components in your app.
// @import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";

// @import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~intl-tel-input/build/css/intlTelInput.css";
@import "./css/style.css";


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$horizon-angular-primary: mat-palette($mat-indigo);
$horizon-angular-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$horizon-angular-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$horizon-angular-theme: mat-light-theme($horizon-angular-primary,
    $horizon-angular-accent,
    $horizon-angular-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($horizon-angular-theme);

/* You can add global styles to this file, and also import other style files */

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font: inherit;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */

[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

a:hover{
  cursor: pointer;
}
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */

img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */

figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */

legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #2e2e2e;
  padding: 0.2em 0;
}

* {
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  float: left;
  margin: 0;
  padding: 60px 0;
}

.inner {
  width: 1366px;
  max-width: 90%;
  float: none;
  margin: auto;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
  display: none !important;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.clearfix:after {
  clear: both;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {

  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #2e2e2e !important;
    /* Black prints faster */
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.candidate_in .create_horizon .view form button:hover,
.setp_candidate_in .create_horizon_setp .create_setp_fild {
  -webkit-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  -ms-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  -o-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more,
.popup .Change_password .body form .action-box button {
  z-index: 99;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more::after,
.popup .Change_password .body form .action-box button::after {
  content: "";
  position: absolute;
  transition: top 0.3s ease-in 0.3s, width 0.3s ease-out, height 0.3s ease-out;
  -webkit-transition: top 0.3s ease-in 0.3s, width 0.3s ease-out,
    height 0.3s ease-out;
  left: 50%;
  top: -50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #F6831F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more:hover,
.popup .Change_password .body form .action-box button:hover {
  color: #2e2e2e;
  transition: color 0.3s linear 0.3s;
  -webkit-transition: color 0.3s linear 0.3s;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button:hover::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button:hover::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear:hover::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more:hover::after,
.popup .Change_password .body form .action-box button:hover::after {
  transition: top 0.3s ease-in, width 0.3s ease-out 0.3s,
    height 0.3s ease-out 0.3s;
  -webkit-transition: top 0.3s ease-in, width 0.3s ease-out 0.3s,
    height 0.3s ease-out 0.3s;
  top: 50%;
  width: 100px;
  height: 100px;
}

.container,
header .MainNav .View_er ul.loginFor li.login_forwrd,
.horizon .Explore-By-Industries .Industries,
.horizon .Explore-By-Industries .Industries .block,
.horizon .Featured-Jobs .Jobs,
.horizon .Featured-Jobs .Jobs .block .details .postname:hover,
.horizon .Featured-Jobs .Jobs .block .moreView a:hover,
.horizon .Top-Companies .Companies,
.horizon .Hire-Now .content a:hover,
.horizon .Our-Partners .PartnersView,
.horizon .whyhorizon .whyhorizon-points,
.horizon .Subscription_Plans .Plans_View .block .Plans .details a,
.horizon .Subscription_Plans .Plans_View .block .Plans .details a:hover,
.horizon .Search-listing .Search-row .Jobs,
.horizon .Search-listing .Search-row .Jobs .block .details .postname:hover,
.horizon .Search-listing .Search-row .Jobs .block .moreView a:hover,
.horizon .Search-listing .Search-row .Top-Companies .Companies,
.horizon .Job-Detail-Page .block .details .postname:hover,
.horizon .Job-Detail-Page .block .moreView a:hover,
.horizon .Similar-Jobs-listing .Jobs,
.horizon .Similar-Jobs-listing .Jobs .block .details .postname:hover,
.horizon .Similar-Jobs-listing .Jobs .block .moreView a:hover,
.horizon .company-view .company-area .company-profile .profile ul li span a,
.horizon .company-view .company-current .job-post .Jobs,
.horizon .company-view .company-current .job-post .Jobs .block .details .postname:hover,
.horizon .company-view .company-current .job-post .Jobs .block .moreView a:hover,
.horizon .company-view .company-current .job-post .company-profile .profile ul li span a,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value label input[type="checkbox"],
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value label input[type="checkbox"],
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value label input[type="checkbox"]:checked,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value label input[type="checkbox"]:checked,
.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear:hover a,
.candidate_in .create_horizon .view form label input[type="checkbox"],
.candidate_in .create_horizon .view form label input[type="checkbox"]:checked,
.candidate_in .create_horizon .view form button,
.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label input[type="checkbox"],
.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label input[type="checkbox"]:checked,
.dashboard,
.dashboard .short_nav,
.dashboard .short_nav a.log_out,
footer .footer-in {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.btn {
  position: relative;
  z-index: 999;
  overflow: hidden;
}

.btn::after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -9;
  background: #F6821F;
  position: absolute;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: transform 0.55s cubic-bezier(0.25, 0.1, 0.25, 0.1);
  -webkit-transition: -webkit-transform 0.55s cubic-bezier(0.75, -0.5, 0, 1.75);
  transition: -webkit-transform 0.55s cubic-bezier(0.75, -0.5, 0, 1.75);
  transition: transform 0.55s cubic-bezier(0.75, -0.5, 0, 1.75);
  transition: transform 0.55s cubic-bezier(0.75, -0.5, 0, 1.75),
    -webkit-transform 0.55s cubic-bezier(0.75, -0.5, 0, 1.75);
}

.btn:hover::after {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}
.hiresNow_flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1440px) {
  .container {
    padding: 0px 7% 0px;
  }
}

@media (max-width: 1280px) {
  .container {
    padding: 0px 4% 0px;
  }
}

@media (max-width: 1280px) {
  .container {
    padding: 0 32px 0;
  }
}


@media (max-width: 600px) {
  .container {
    padding: 0 24px;
  }
}

header {
  width: 100%;
  float: left;
}

header .MainNav {
  width: 100%;
  padding: 0;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 2.5fr;
  grid-template-columns: 0.5fr 2.5fr;
  border-bottom: 1px solid #e0e0e0;
}

@media (max-width: 991px) {
  header .MainNav {
    -ms-grid-columns: 0.5fr 2fr;
    grid-template-columns: 0.5fr 2fr;
  }
}

@media (max-width: 768px) {
  header .MainNav {
    padding: 13px 0 12px;
  }
}

@media (max-width: 991px) {
  header .MainNav .contact {
    display: none;
  }
}

header .MainNav .contact .contact_way a {
  color: #2e2e2e;
  font: 14px "Poppins", sans-serif;
  text-decoration: none;
  font-weight: 600;
}

header .MainNav .contact .social_link ul li {
  width: auto;
  float: left;
  padding: 8px 20px 0 0;
  list-style: none;
}

header .MainNav .contact .social_link ul li a {
  outline: none;
}

header .MainNav .brand a {
  display: block;
  margin: auto;
  width: auto;
}

header .MainNav .brand a img {
  max-width: 220px;
  margin: auto;
  display: block;
  max-height: 100px;
}

@media (max-width: 991px) {
  header .MainNav .brand a img {
    float: left;
  }
}

@media (max-width: 768px) {
  header .MainNav .brand a img {
    float: left;
    max-width: 150px;
  }
}

@media (max-width: 414px) {
  header .MainNav .brand a img {
    max-width: 120px;
  }
}

header .MainNav .View_er {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}

@media (max-width: 768px) {
  header .MainNav .View_er {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: end;
    align-content: flex-end;
  }
}

header .MainNav .View_er .language {
  padding: 0;
  margin: 0;
  width: 150px;
  height: 40px;
}

@media (max-width: 768px) {
  header .MainNav .View_er .language {
    width: 115px;
    height: 35px;
  }
}

@media (max-width: 600px) {
  header .MainNav .View_er .language {
    display: none;
  }
}

header .MainNav .View_er .language a {
  font: 500 14px "Poppins", sans-serif;
  color: #2e2e2e;
  width: 100%;
  outline: none;
  text-decoration: none;
  float: left;
  padding: 14px 0 12px 40px;
}

header .MainNav .View_er .language a.eng {
  background: url(./assets/img/icon/icons8-usa.png) no-repeat left;
}

header .MainNav .View_er .language a.ar {
  background: url(./assets/img/icon/icons8-ar.png) no-repeat left;
}

@media (max-width: 768px) {
  header .MainNav .View_er .language a {
    font: 500 11px "Poppins", sans-serif;
  }
}

header .MainNav .View_er ul.loginFor {
  padding: 0;
  margin: 0;
  width: 150px;
  height: 40px;
  background: #fff;
}

@media (max-width: 768px) {
  header .MainNav .View_er ul.loginFor {
    height: 35px;
  }
}

@media (max-width: 320px) {
  header .MainNav .View_er ul.loginFor {
    width: 115px;
  }
}

header .MainNav .View_er ul.loginFor li.login_forwrd {
  font: 14px "Poppins", sans-serif;
  text-align: left;
  cursor: pointer;
  padding: 0;
  font-weight: 500;
  color: #fff;
  width: 100%;
  list-style: none;
  display: block;
  border-radius: 7px;
  float: left;
  border: 1px solid #F6821F;
  background: #F6821F;
  text-transform: capitalize;
  position: relative;
}

@media (max-width: 768px) {
  header .MainNav .View_er ul.loginFor li.login_forwrd {
    font-size: 12px;
  }
}

header .MainNav .View_er ul.loginFor li.login_forwrd:after {
  content: "";
  background: url(./assets/img/icon/icons8-expand_arrow.svg) no-repeat center;
  padding: 22px 19px;
  border-left: 1px solid #ebebeb;
  float: right;
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 768px) {
  header .MainNav .View_er ul.loginFor li.login_forwrd:after {
    padding: 15px 15px;
  }
}

header .MainNav .View_er ul.loginFor li.login_forwrd:hover {
  background: #fff;
  color: #F6821F;
  border: 0px solid #fff;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px 7px 0 0;
}

header .MainNav .View_er ul.loginFor li.login_forwrd:hover:after {
  background: url(./assets/img/icon/login-icons8-expand_arrow.svg) no-repeat center;
}

header .MainNav .View_er ul.loginFor li.login_forwrd:hover ul {
  visibility: visible;
  /* shows sub-menu */
  opacity: 1;
  z-index: 1;
  border-radius: 0 0 7px 7px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  background: #fff;
  -webkit-transition-delay: 0s, 0s, 0.3s;
  transition-delay: 0s, 0s, 0.3s;
  /* this removes the transition delay so the menu will be visible while the other styles transition */
}

header .MainNav .View_er ul.loginFor li.login_forwrd span {
  padding: 13px 2px 13px 2px;
  width: calc(100% - 40px);
  float: left;
  text-align: center;
}

@media (max-width: 768px) {
  header .MainNav .View_er ul.loginFor li.login_forwrd span {
    padding: 8px 4px 8px 17px;
  }
}

header .MainNav .View_er ul.loginFor li.login_forwrd ul {
  padding: 0 0 20px;
  margin: 0;
  visibility: hidden;
  /* hides sub-menu */
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-2em);
  transform: translateY(-2em);
  z-index: -1;
  -webkit-transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
  width: 100%;
  float: left;
}

header .MainNav .View_er ul.loginFor li.login_forwrd ul li {
  list-style: none;
  display: block;
  width: 100%;
  border-radius: 7px;
  float: left;
}

header .MainNav .View_er ul.loginFor li.login_forwrd ul li a {
  width: 100%;
  outline: none;
  float: left;
  text-decoration: none;
  padding: 20px 26px 0;
  color: #F6821F;
}

@media (max-width: 768px) {
  header .MainNav .View_er ul.loginFor li.login_forwrd ul li a {
    padding: 13px 15px 0;
  }
}

header .MainNav .View_er .BergerMenu {
  display: none;
}

header .MainNav .View_er .BergerMenu img {
  width: 29px;
}

@media (max-width: 991px) {
  header .MainNav .View_er .BergerMenu {
    display: block;
    margin: 0 0 0 12px;
  }
}

header .navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 100%;
  float: left;
}
.horizon {
  width: 100%;
}
.horizon .home-fold-area {
width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  
}
// Hamza custom CSS


.infinite_slider{
  margin-top: 96px;
}
@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
.slider {
  height: fit-content;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.slider::before, .slider::after {
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.slider::before {
  left: 0;
  top: 0;
}
.slider .slide-track {
  -webkit-animation: scroll 60s linear infinite;
          animation: scroll 60s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.slider .slide {
 margin-right: 120px;
 display: flex;
 align-items: center;
}
@media (max-width: 768px) {
  .horizon .home-fold-area {
    // padding: 0 10px;
    padding:  0;
  }
 
}
.btns_hero_sec {
  display: flex;
}
.btns_hero_sec > a{
  width: 205px;
  height: 59px;
  border-radius: 8px;
  border: 1px solid #F5831F;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

}
.btns_hero_sec > .btn_reg{
  border: 0;
  background-color: #F5831F;
 margin-left: 24px;
background: linear-gradient(180deg, #F6821F 0%, #F48720 100%);
box-shadow: 0px 8px 16px 0px rgba(246, 130, 31, 0.10);
cursor: pointer;
}
.btns_hero_sec > .btn_reg:hover{
  transition: all 0.3s ease-in;
  letter-spacing: 1px;
}
.btns_hero_sec  > a{
  list-style: none;
  text-decoration: none;
  color: #F6821F;
font-size: 16px;
font-weight: 400;
}
.btns_hero_sec > .btn_reg {
  color: #fff;
  font-weight: 600;
}
.horizon .home-fold-area .recruitment {
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
  margin-top: 96px;
}
.flex_content{
  display: flex;
  align-items: center;
  margin-bottom: 96px;
}
.how_we_work_left{
  margin-right: 10vw;
}
.how_we_work_left h2{
margin-bottom: 24px;
font-size: 24px;
font-weight: 600;
}
.how_we_work_left > p{
  color: #6F6F6F;
font-size: 16px;
font-weight: 400;
line-height: 25px; 
}
.btn_learn_more{
  margin-top: 24px;
}
.btn_learn_more > a{
  color: #F6821F;
font-size: 16px;
font-weight: 400;
text-decoration-line: underline;

}
iframe{
  border-radius: 4px;

box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.10);
}
@media (max-width: 600px) {
  .horizon .home-fold-area .recruitment {
    max-width: 100%;
    margin-top: 48px;
  }
}

.horizon .home-fold-area .recruitment h1 {
 
  font: 600 32px "Poppins", sans-serif;
 
}

@media (max-width: 991px) {
  .horizon .home-fold-area .recruitment h1 {
    font: 600 28px "Poppins", sans-serif;
    padding: 0 0 20px;
  }
}

@media (max-width: 768px) {
  .how_we_work_left{
    margin-right: 0;
  }
  .horizon .home-fold-area .recruitment h1 {
    font: 600 29px "Poppins", sans-serif;
    padding: 0 0 15px;
    max-width: 370px;
    margin: 0;
  }
  .btns_hero_sec {
    display: flex;
    flex-direction: column;
  }
 
  .btns_hero_sec > .btn_reg{
   margin-left: 0;
   margin-top: 16px;
  }
}

@media (max-width: 480px) {
  .horizon .home-fold-area .recruitment h1 {
    font: 600 23px "Poppins", sans-serif;
  }
}


@media (max-width: 991px) {
  .horizon .home-fold-area h1 {
    font: 600 28px "Poppins", sans-serif;
    padding: 0 0 20px;
  }
}

@media (max-width: 768px) {
  .horizon .home-fold-area h1 {
    font: 600 29px "Poppins", sans-serif;
    padding: 0 0 15px;
    max-width: 370px;
    margin: 0 auto;
  }
}
.feature_jobs_container{
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
padding: 16px;
}
.feature-jobs-divs > div{
padding: 4px 0;

}
.horizon .counder .counderView .cound .statusName {
  width: 100%;
  float: left;
  padding: 10px 0 0;
  font: 400 16px "Poppins", sans-serif;
  color: #fff;
  text-align: center;
}

@media (max-width: 768px) {
  .horizon .counder .counderView .cound .statusName {
    font: 400 14px "Poppins", sans-serif;
  }
}

.horizon .How-it-works {
  width: 100%;
  float: left;
  // background: url(./assets/img/How-it-works.png);
  background-repeat: repeat;
  padding: 40px 0;
}

@media (max-width: 991px) {
  .horizon .How-it-works {
    padding: 30px 0 80px;
  }
}

@media (max-width: 768px) {
  .horizon .How-it-works {
    padding: 30px 0 40px;
  }
}

.horizon .How-it-works h2 {
  color: #2e2e2e;
  text-align: center;
  font: 600 32px "Poppins", sans-serif;
  margin: 0;
  padding: 0px 0 80px;
}

@media (max-width: 991px) {
  .horizon .How-it-works h2 {
    padding: 10px 0 40px;
    font: 600 24px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .How-it-works h2 {
    font: 500 19px "Poppins", sans-serif;
    padding: 0px 0 23px;
  }
}

.horizon .How-it-works .it-works {
  width: 100%;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 991px) {
  .horizon .How-it-works .it-works {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
  }
}

@media (max-width: 768px) {
  .horizon .How-it-works .it-works {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .How-it-works .it-works .colm img {
  max-width: 100px;
  margin: auto;
  display: block;
}

.horizon .How-it-works .it-works .colm .title {
  text-align: center;
  width: 100%;
  color: #2e2e2e;
  font: 600 24px "Poppins", sans-serif;
  float: left;
  padding: 30px 0 25px;
}

@media (max-width: 991px) {
  .horizon .How-it-works .it-works .colm .title {
    font: 600 19px "Poppins", sans-serif;
    padding: 15px 0 13px;
  }
}

@media (max-width: 768px) {
  .horizon .How-it-works .it-works .colm .title {
    font: 500 16px "Poppins", sans-serif;
    padding: 15px 0 6px;
  }
}

.horizon .How-it-works .it-works .colm p.consept {
  width: 100%;
  margin: auto;
  text-align: center;
  max-width: 350px;
  color: #2e2e2e;
  font: 500 16px "Poppins", sans-serif;
}

@media (max-width: 1440px) {
  .horizon .How-it-works .it-works .colm p.consept {
    font-size: 15px;
    line-height: 23px;
  }
 
}

@media (max-width: 991px) {
  .horizon .How-it-works .it-works .colm p.consept {
    font: 500 14px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .How-it-works .it-works .colm p.consept {
    font: 500 13px "Poppins", sans-serif;
  }
}

.horizon .Explore-By-Industries {
  width: 100%;
  float: left;
  background: #ffffff;
  padding: 10px 0 25px 0;
}

@media (max-width: 1024px) {
  .horizon .Explore-By-Industries {
    padding: 10px 30px 50px;
  }
}

@media (max-width: 991px) {
  .horizon .Explore-By-Industries {
    padding: 20px 20px 80px;
  }
}

@media (max-width: 768px) {
  .horizon .Explore-By-Industries {
    padding: 20px 10px 40px;
  }
}

.horizon .Explore-By-Industries .title {
  padding: 10px 0 15px;
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 991px) {
  .horizon .Explore-By-Industries .title {
    padding: 10px 0 15px;
  }
}

.horizon .Explore-By-Industries .title h2 {
  color: #2e2e2e;
  text-align: left;
  font: 600 30px "Poppins", sans-serif;
  margin: 0;
  width: auto;
  float: left;
  padding: 0px 0px;
}

@media (max-width: 991px) {
  .horizon .Explore-By-Industries .title h2 {
    font: 600 22px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .Explore-By-Industries .title h2 {
    font: 600 16px "Poppins", sans-serif;
  }
}

.horizon .Explore-By-Industries .title a {
  color: #F6821F;
  display: block;
  padding: 0 0 6px;
  float: right;
  font: 300 16px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .Explore-By-Industries .title a {
    padding: 0 0 4px;
    font: 300 13px "Poppins", sans-serif;
  }
}

.horizon .Explore-By-Industries .Industries {
  width: 100%;
  float: left;
  display: -ms-grid;
  display: grid;
  grid-gap: 30px;
  -ms-grid-columns: (minmax(270px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
}

@media (max-width: 1024px) {
  .horizon .Explore-By-Industries .Industries {
    grid-gap: 25px;
  }
}

@media (max-width: 768px) {
  .horizon .Explore-By-Industries .Industries {
    grid-gap: 15px;
  }
}

.horizon .Explore-By-Industries .Industries .block {
  // background: #eaeaea;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.horizon .Explore-By-Industries .Industries .block .icon {
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
}

.horizon .Explore-By-Industries .Industries .block .details {
  padding: 0 0 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.horizon .Explore-By-Industries .Industries .block .details .postname {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 600 16px "Poppins", sans-serif;
  color: #F6821F;
}

@media (max-width: 768px) {
  .horizon .Explore-By-Industries .Industries .block .details .postname {
    font: 600 14px "Poppins", sans-serif;
  }
}

.horizon .Explore-By-Industries .Industries .block .details .countPost {
  color: #2e2e2e;
  width: 100%;
  font: 500 14px "Poppins", sans-serif;
  float: left;
  padding: 10px 0 0;
}

@media (max-width: 768px) {
  .horizon .Explore-By-Industries .Industries .block .details .countPost {
    font: 600 12 px "Poppins", sans-serif;
  }
}

.horizon .Featured-Jobs {
  width: 100%;
  float: left;
  // background: url(./assets/img/How-it-works.png);
  background-repeat: repeat;
  
}

@media (max-width: 768px) {
  .horizon .Featured-Jobs {
    padding-bottom:  24px;
  }
  
}

.horizon .Featured-Jobs .title {
  padding: 96px 0 24px;
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}


.horizon .Featured-Jobs .title h2 {
  color: #2E2E2E;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Noto Serif', serif;
}

@media (max-width: 991px) {
  .horizon .Featured-Jobs .title h2 {
    margin: 0 0 0 0;
    padding: 0;
  }
}

.horizon .Featured-Jobs .title a {
  color: #F6821F;
  padding: 0;
  float: right;
  font: 300 16px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .Featured-Jobs .title a {
    padding: 0 0 2px;
    font: 300 12px "Poppins", sans-serif;
  }
}

.horizon .Featured-Jobs .Jobs {
  width: 100%;
  display: grid;
grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 24px;
  margin-bottom: 24px;

}

@media (max-width: 1024px) {
  .horizon .Featured-Jobs .Jobs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
  }
}

@media (max-width: 576px) {
  .horizon .Featured-Jobs .Jobs {
    -ms-grid-columns: (minmax(300px, 1fr)) [auto-fill];
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
 
}

.horizon .Featured-Jobs .Jobs .block {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
  padding: 10px;
 
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 1366px) {
  .horizon .Featured-Jobs .Jobs .block {
    padding: 22px;
  }
}

@media (max-width: 768px) {
  .horizon .Featured-Jobs .Jobs .block {
    padding: 20px 15px;
  }
  .horizon .Featured-Jobs .Jobs {
    margin-bottom: 0;
  }
}

@media (max-width: 480px) {
  .horizon .Featured-Jobs .Jobs .block {
    display: block;
  }
}

.horizon .Featured-Jobs .Jobs .block .icon {
  border-radius: 5px;
}

@media (max-width: 576px) {
  .horizon .Featured-Jobs .Jobs .block .icon {
    float: left;
    padding-bottom: 12px;
  }
}

@media (max-width: 480px) {
  .horizon .Featured-Jobs .Jobs .block .icon {
    width: 75px;
  }
}

.horizon .Featured-Jobs .Jobs .block .icon img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.horizon .Featured-Jobs .Jobs .block .details {
  padding: 0 10px 0 45px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1366px) {
  .horizon .Featured-Jobs .Jobs .block .details {
    padding: 0 10px 0 30px;
  }
}

@media (max-width: 1024px) {
  .horizon .Featured-Jobs .Jobs .block .details {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .horizon .Featured-Jobs .Jobs .block .details {
    padding: 0 0 0 15px;
    width: auto;
    float: left;
  }
}

@media (max-width: 480px) {
  .horizon .Featured-Jobs .Jobs .block .details {
    width: calc(100% - 75px);
  }
}

.horizon .Featured-Jobs .Jobs .block .details .postname {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 600 18px "Poppins", sans-serif;
  color: #F6821F;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .horizon .Featured-Jobs .Jobs .block .details .postname {
    font: 500 16px "Poppins", sans-serif;
  }
}

.horizon .Featured-Jobs .Jobs .block .details .postname:hover {
  color: #2e2e2e;
}

.horizon .Featured-Jobs .Jobs .block .details .countPost,
.horizon .Featured-Jobs .Jobs .block .details .countdate {
  color: #2e2e2e;
  width: 100%;
  font: 550 15px "Poppins", sans-serif;
  float: left;
  padding: 11px 0 0;
}

@media (max-width: 768px) {

  .horizon .Featured-Jobs .Jobs .block .details .countPost,
  .horizon .Featured-Jobs .Jobs .block .details .countdate {
    font: 400 12px "Poppins", sans-serif;
  }
}

.horizon .Featured-Jobs .Jobs .block .moreView {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-content: stretch;
}

@media (max-width: 576px) {
  .horizon .Featured-Jobs .Jobs .block .moreView {
    width: auto;
    float: left;
    padding: 13px 0 0;
  }
}

@media (max-width: 480px) {
  .horizon .Featured-Jobs .Jobs .block .moreView {
    float: right;
  }
}

.horizon .Featured-Jobs .Jobs .block .moreView a {
  display: block;
  min-width: 105px;
  border-radius: 5px;
  padding: 7px 20px;
  text-decoration: none;
  color: #fff;
  font: 300 14px "Poppins", sans-serif;
  background: #F6821F;
  white-space: pre;

  @media (max-width: 1366px) {
    font-size: 12px;
  }
}

.horizon .Featured-Jobs .Jobs .block .moreView a:hover {
  text-decoration: underline;
}

.horizon .Top-Companies {
  width: 100%;
}

.horizon .Top-Companies .title {
  padding: 96px 0 24px 0;
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -ms-flex-line-pack: stretch;
  align-content: stretch;

}

@media (max-width: 991px) {
  .horizon .Top-Companies .title {
    padding:  96px 0 24px 0;
  }
}


.horizon .Top-Companies .title h2 {
  color: #2e2e2e;
  margin: 0;
  width: auto;
  float: left;
  padding: 0px 0px;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Noto Serif', serif;
}



.horizon .Top-Companies .title a {
  color: #F6821F;
  display: block;
  padding: 0 0 6px;
  float: right;
  font: 300 16px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .Top-Companies .title a {
    padding: 0 0 2px;
    font: 300 12px "Poppins", sans-serif;
  }
}

.horizon .Top-Companies .Companies {
  width: 100%;
  grid-gap: 24px;
  display: grid;
  -ms-grid-columns: (minmax(270px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  margin-bottom: 24px;
}
.explore-companies-link{
  margin-bottom: 96px;
}
@media (max-width: 1024px) {
  .horizon .Top-Companies .Companies {
    grid-gap: 25px;
  }
}

@media (max-width: 768px) {
  .horizon .Top-Companies .Companies {
    grid-gap: 15px;
  }
}

@media (max-width: 320px) {
  .horizon .Top-Companies .Companies {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.horizon .Top-Companies .Companies .Companyblock {
  background: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  min-height: 120px;
  max-height: 120px;
  border-radius: 8px;
border:  1px solid #dedede;

}

@media (max-width: 768px) {
  .horizon .Top-Companies .Companies .Companyblock {
    padding: 7px 20px 7px 10px;
  }
}

.horizon .Top-Companies .Companies .Companyblock .icon {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .horizon .Top-Companies .Companies .Companyblock .icon {
    padding: 5px;
  }
}

.horizon .Top-Companies .Companies .Companyblock .icon img {
  max-width: 100px;
  height: 100%;
}

.horizon .Top-Companies .Companies .Companyblock .details {
  padding: 0 0 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 768px) {
  .horizon .Top-Companies .Companies .Companyblock .details {
    padding: 0 0 0 5px;
  }
}

.horizon .Top-Companies .Companies .Companyblock .details .CompName {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 600 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .Top-Companies .Companies .Companyblock .details .loctn {
  color: #a1a1a1;
  width: 100%;
  font: 400 14px "Poppins", sans-serif;
  float: left;
  padding: 10px 0 0;
}

.horizon .Hire-Now {
 width: 100%;
margin-bottom: 96px;
padding: 48px;
  background: linear-gradient(91deg, #EE7400 0%, #FFC895 100%);
  border-radius: 8px;
}
@media (max-width: 1280px){
  .horizon .Hire-Now {
   padding: 48px 32px;
 
   }
}
.illustration_hiresnow > img{
  margin-right: 10vw;
}
.align_cont_center{
  display: flex;
  justify-content: center;
}
.limit_width{
  width: 1300px;
}

@media (max-width: 1290px){
  .limit_width{
    width: 100%;
  }
  .horizon .Hire-Now {
     border-radius: 0px;
   }
}
  @media (max-width: 920px) {
    .illustration_hiresnow{
      display: none;
    }
    .infinite_slider{
      display: none;
    }
    .clients_testimonials{
      display: none;
    }
  }
.horizon .Hire-Now .content h3 {
  color: #fff;
  text-align: left;
  font-family: 'Noto Serif', serif;
  font-weight: 700;
  font-size: 32px;
  margin: 0;
  padding: 0;
}
@media (max-width: 768px) {
  .horizon .Hire-Now .content h3 {
    font: 600 30px "Poppins", sans-serif;
  }
}

@media (max-width: 576px) {
  .horizon .Hire-Now .content h3 {
    font: 600 25px "Poppins", sans-serif;
  }
  
}

.horizon .Hire-Now .content p {
  margin: 16px 0 0 0;
  padding: 0;
  color: #fff;
  font: 400 16px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .Hire-Now .content p {
    font: 400 16px "Poppins", sans-serif;
  }
}

@media (max-width: 576px) {
  .horizon .Hire-Now .content p {
    font: 400 14px "Poppins", sans-serif;
  }
}

.horizon .Hire-Now .content a {
  width: 120px;
  display: block;
  border-radius: 8px;
  padding: 20px 0;
  margin: 24px 0 0 0;
  text-align: center;
  text-decoration: none;
  color:  #F6821F;
  font: 600 16px "Poppins", sans-serif;
  background: #fff;
box-shadow: 0px 8px 16px 0px rgba(255, 255, 255, 0.10);
}

@media (max-width: 768px) {
  .horizon .Hire-Now .content a {
    padding: 12px 13px;
    width: 154px;
    margin: 27px 0 0;
  }
}

@media (max-width: 576px) {
  .horizon .Hire-Now .content a {
    font: 600 16px "Poppins", sans-serif;
  }
}

.horizon .Hire-Now .content a:hover {
  opacity: 0.9;
}

.horizon .Our-Partners .ExploreView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  position: relative;
  padding: 60px 30px 60px 0;
}

@media (max-width: 1440px) {
  .horizon .Our-Partners .ExploreView {
    padding: 60px 60px 60px 0;
  }
}

@media (max-width: 1280px) {
  .horizon .Our-Partners .ExploreView {
    padding: 60px 53px;
  }
}

@media (max-width: 1024px) {
  .horizon .Our-Partners .ExploreView {
    padding: 60px 60px;
  }
}

@media (max-width: 991px) {
  .horizon .Our-Partners .ExploreView {
    padding: 53px;
  }
  .Our-Partners > .flex_content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .right_cont_expl_video{
    margin-top: 24px;
  }
}
@media (max-width: 655px) {
.right_cont_expl_video > iframe{
  width: 90vw;
}
}
@media (max-width: 576px) {
  .horizon .Our-Partners .ExploreView {
    padding: 23px;
  }
}

.horizon .Our-Partners .ExploreView .view h4 {
  color: #2e2e2e;
  text-align: left;
  font: 600 32px "Poppins", sans-serif;
  margin: 0;
  padding: 0 0 20px;
}

@media (max-width: 991px) {
  .horizon .Our-Partners .ExploreView .view h4 {
    font: 600 25px "Poppins", sans-serif;
  }
}

.horizon .Our-Partners .ExploreView .view p {
  color: #6f6f6f;
  font: 500 16px "Poppins", sans-serif;
}

@media (max-width: 576px) {
  .horizon .Our-Partners .ExploreView .view p {
    font: 500 14px "Poppins", sans-serif;
  }
}

.horizon .Our-Partners .ExploreView .view a {
  text-decoration: none;
  color: #F6821F;
  font: 400 16px "Poppins", sans-serif;
  float: left;
  margin-top: 15px;
  text-decoration: underline;
 
}

@media (max-width: 576px) {
  .horizon .Our-Partners .ExploreView .view a {
    font: 500 14px "Poppins", sans-serif;
    margin-top: 10px;
  }
}

.horizon .Our-Partners .PartnersView {
  background: #fff;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 70px;
  -ms-grid-columns: (minmax(165px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  justify-items: center !important;
}

@media (max-width: 1024px) {
  .horizon .Our-Partners .PartnersView {
    grid-gap: 25px;
  }
}

@media (max-width: 768px) {
  .horizon .Our-Partners .PartnersView {
    grid-gap: 15px;
  }
}

@media (max-width: 991px) {
  .horizon .Our-Partners .PartnersView {
    padding: 50px 30px 50px;
  }
}

@media (max-width: 768px) {
  .horizon .Our-Partners .PartnersView {
    padding: 30px 30px;
  }
}

.horizon .Our-Partners .PartnersView .PartnerLogo img {
  max-width: 100%;
  display: block;
  margin: auto;
  padding: 30px 0;
}

.horizon .whyhorizon {
  width: 100%;
  float: left;
  background: #eaeaea;
  padding: 70px 0;
}

@media (max-width: 1199px) {
  .horizon .whyhorizon {
    padding: 70px 10px;
  }
}

@media (max-width: 991px) {
  .horizon .whyhorizon {
    padding: 40px 10px;
  }
}

.horizon .whyhorizon .whyhorizon-title {
  width: 100%;
  float: left;
}

.horizon .whyhorizon .whyhorizon-title h4 {
  font: 500 35px "Poppins", sans-serif;
  text-align: center;
  padding: 20px 0 30px;
}

@media (max-width: 1199px) {
  .horizon .whyhorizon .whyhorizon-title h4 {
    padding: 0px 0 0px;
  }
}

@media (max-width: 1024px) {
  .horizon .whyhorizon .whyhorizon-title h4 {
    font: 500 28px "Poppins", sans-serif;
  }
}

@media (max-width: 1440px) {
  .horizon .whyhorizon .whyhorizon-title h4 {
    font: 500 24px "Poppins", sans-serif;
  }
}

.horizon .whyhorizon .whyhorizon-points {
  width: 100%;
  padding-top: 50px;
  float: left;
  display: -ms-grid;
  display: grid;
  grid-gap: 80px;
  -ms-grid-columns: (minmax(470px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(470px, 1fr));
}

@media (max-width: 1024px) {
  .horizon .whyhorizon .whyhorizon-points {
    grid-gap: 25px;
  }
}

@media (max-width: 991px) {
  .horizon .whyhorizon .whyhorizon-points {
    padding-top: 30px;
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .whyhorizon .whyhorizon-points {
    display: block;
  }
}

.horizon .whyhorizon .whyhorizon-points .point {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: flex-start;
  align-content: flex-start;
}

@media (max-width: 768px) {
  .horizon .whyhorizon .whyhorizon-points .point {
    padding-bottom: 20px;
  }
}

.horizon .whyhorizon .whyhorizon-points .point .icon {
  background: #6a6a6a;
  width: 180px;
  height: 145px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
}

.horizon .whyhorizon .whyhorizon-points .point .icon img {
  max-width: 150px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .horizon .whyhorizon .whyhorizon-points .point .icon img {
    max-width: 100px;
  }
}

@media (max-width: 768px) {
  .horizon .whyhorizon .whyhorizon-points .point .icon img {
    max-width: 80px;
  }
}

.horizon .whyhorizon .whyhorizon-points .point .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  padding-left: 50px;
}

@media (max-width: 991px) {
  .horizon .whyhorizon .whyhorizon-points .point .content {
    padding-left: 25px;
  }
}

.horizon .whyhorizon .whyhorizon-points .point .content .title {
  color: #fff;
  font: 500 25px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .whyhorizon .whyhorizon-points .point .content .title {
    font: 500 19px "Poppins", sans-serif;
  }
}

@media (max-width: 576px) {
  .horizon .whyhorizon .whyhorizon-points .point .content .title {
    font: 500 17px "Poppins", sans-serif;
  }
}

.horizon .whyhorizon .whyhorizon-points .point .content p {
  color: #fff;
  padding-top: 20px;
  font: 500 16px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .horizon .whyhorizon .whyhorizon-points .point .content p {
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .horizon .whyhorizon .whyhorizon-points .point .content p {
    font: 400 14px "Poppins", sans-serif;
  }
}

.horizon .whyhorizon .whyhorizon-foot {
  width: 100%;
  float: left;
  padding: 80px 0 30px;
}

@media (max-width: 768px) {
  .horizon .whyhorizon .whyhorizon-foot {
    padding: 30px 0 0px;
  }
}

.horizon .whyhorizon .whyhorizon-foot a {
  display: block;
  max-width: 280px;
  margin: auto;
  text-decoration: none;
  text-align: center;
  color: #fff;
  background: #F6821F;
  padding: 15px 20px;
  border-radius: 5px;
  font: 500 15px "Poppins", sans-serif;
}

.horizon .whyhorizon .whyhorizon-foot a:hover {
  text-decoration: underline;
}

.horizon .Subscription_Plans {
  width: 100%;
  float: left;
  // background: url(./assets/img/How-it-works.png);
  background-repeat: repeat;
  padding: 80px 0 110px;
}

.horizon .Subscription_Plans .Plans_title {
  width: 100%;
  float: left;
}

.horizon .Subscription_Plans .Plans_title h4 {
  color: #2e2e2e;
  font: 600 35px "Poppins", sans-serif;
  text-align: center;
  width: 100%;
  float: left;
  padding: 20px 0 25px;
}

@media (max-width: 1199px) {
  .horizon .Subscription_Plans .Plans_title h4 {
    padding: 0px 0 0px;
  }
}

@media (max-width: 1024px) {
  .horizon .Subscription_Plans .Plans_title h4 {
    font: 500 28px "Poppins", sans-serif;
  }
}

@media (max-width: 1440px) {
  .horizon .Subscription_Plans .Plans_title h4 {
    font: 500 24px "Poppins", sans-serif;
  }
}

.horizon .Subscription_Plans .Plans_title p {
  color: #2e2e2e;
  font: 600 20px "Poppins", sans-serif;
  text-align: center;
  width: 100%;
  float: left;
}

.horizon .Subscription_Plans .Plans_View {
  width: 100%;
  padding: 50px 50px 0;
  max-width: 1440px;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.horizon .Subscription_Plans .Plans_View .block {
  width: 100%;
  float: left;
  height: auto;
  max-width: 210px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans {
  float: left;
  width: 100%;
  height: auto;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name {
  font: 600 22px "Poppins", sans-serif;
  color: #fff;
  text-align: center;
  float: left;
  width: 100%;
  height: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 0 -75px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name.basic {
  background: url(./assets/img/1.png) no-repeat center;
  background-size: 150px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name.deluxe {
  background: url(./assets/img/2.png) no-repeat center;
  background-size: 150px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name.professional {
  background: url(./assets/img/3.png) no-repeat center;
  background-size: 150px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name.ultimate {
  background: url(./assets/img/4.jpg) no-repeat center;
  background-size: 150px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name.titanium {
  background: url(./assets/img/5.jpg) no-repeat center;
  background-size: 150px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .name.platinum {
  background: url(./assets/img/6.jpg) no-repeat center;
  background-size: 150px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .details {
  padding: 95px 0 0;
  background: #fff;
  border: 1px solid #b7b7b7;
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .details p {
  font: 600 15px "Poppins", sans-serif;
  margin: 10px 0 0;
  text-align: center;
  padding: 0;
  color: #393939;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .details span {
  text-align: center;
  color: #2e2e2e;
  margin: 23px 0 20px;
  font: 600 22px "Poppins", sans-serif;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .details a {
  color: #fff;
  text-decoration: none;
  text-align: center;
  padding: 10px 35px;
  display: block;
  border-radius: 4px;
  font: 600 13px "Poppins", sans-serif;
  background: #F6821F;
  border: 1px solid #F6821F;
  margin: 0 0 20px;
}

.horizon .Subscription_Plans .Plans_View .block .Plans .details a:hover {
  background: #fff;
  color: #F6821F;
}

.horizon .Testimonials {
  width: 100%;
  float: left;
  background: #faf7ee;
  padding: 50px 0;
}

.horizon .Testimonials .title {
  width: 100%;
  float: left;
}

.horizon .Testimonials .title h4 {
  color: #2e2e2e;
  font: 600 35px "Poppins", sans-serif;
  text-align: center;
  width: 100%;
  float: left;
  padding: 20px 0 50px;
}

@media (max-width: 1024px) {
  .horizon .Testimonials .title h4 {
    font: 500 28px "Poppins", sans-serif;
  }
}

@media (max-width: 1440px) {
  .horizon .Testimonials .title h4 {
    font: 500 24px "Poppins", sans-serif;
  }
}

.horizon .Testimonials .Testimonials_slids {
  width: 100%;
  float: left;
}

.horizon .Testimonials .Testimonials_slids .slids {
  width: 100%;
  padding: 60px 40px 60px;
  float: left;
  max-width: 780px;
  margin: 0;
  opacity: 0.4;
  background: #f36f211a;
}

.horizon .Testimonials .Testimonials_slids .slids.active {
  opacity: 0.9;
}

.horizon .Testimonials .Testimonials_slids .slids p {
  font: 500 18px "Poppins", sans-serif;
  line-height: 30px;
  color: #2e2e2e;
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
}

.horizon .Testimonials .Testimonials_slids .slids .name {
  font: 600 24px "Poppins", sans-serif;
  color: #F6821F;
  width: 100%;
  float: left;
  margin: 15px 0 0;
}

.horizon .Testimonials .Testimonials_slids .slids .postion {
  font: 600 15px "Poppins", sans-serif;
  color: #F6821F;
  width: 100%;
  margin: 7px 0 0;
  float: left;
}

.horizon .Job-Search {
  width: 100%;
  float: left;
  // background: url(./assets/img/How-it-works.png);
  background-repeat: repeat;
  padding: 32px 0;
  height: auto;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 768px) {
  .horizon .Job-Search {
    padding: 25px 32px;
  }
}

.horizon .Job-Search .search-tag {
  width: 100%;
  float: left;
  padding: 50px 0 30px;
}

@media (max-width: 991px) {
  .horizon .Job-Search .search-tag {
    padding: 33px 0 20px;
  }
}

@media (max-width: 768px) {
  .horizon .Job-Search .search-tag {
    padding: 33px 0 20px;
  }
}

.horizon .Job-Search .search-tag p {
  text-align: center;
  font: 400 16px "Poppins", sans-serif;
  color: #a1a1a1;
}

@media (max-width: 768px) {
  .horizon .Job-Search .search-tag p {
    font: 400 15px "Poppins", sans-serif;
  }
}

// .horizon .filter-mob {
//   display: none;
//   -webkit-box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.34);
//   box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.34);
//   border-radius: 20px;
//   background: #f36f21;
//   position: fixed;
//   bottom: 20px;
//   left: 20px;
//   padding: 10px 15px;
// }

// @media (max-width: 991px) {
//   .horizon .filter-mob {
//     display: block;
//   }
// }

// @media (max-width: 576px) {
//   .horizon .filter-mob {
//     left: inherit;
//     right: 20px;
//   }
// }

// .horizon .filter-mob a {
//   width: 100%;
//   outline: none;
//   height: 100%;
//   text-transform: uppercase;
//   color: #ffffff;
//   text-decoration: none;
//   font: 300 12px "Poppins", sans-serif;
// }

// .horizon .filter-mob a img {
//   max-width: 12px;
//   width: 100%;
// }


.horizon .BannerView .BannerView-title a {
  padding: 0 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  margin: auto;
  text-align: center;
  color: #ffffff;
  font: 600 20px "Poppins", sans-serif;
  text-decoration: none;
}

@media (max-width: 1024px) {
  .horizon .BannerView .BannerView-title a {
    font: 600 15px "Poppins", sans-serif;
  }
}

.horizon .BannerView .BannerView-title a img {
  margin-right: 10px;
}
.horizon .BannerView .BannerView-title{
  margin: 0 0 32px 0;

}
.horizon .BannerView .BannerView-title h2 {
  text-align: left;
  color: #2e2e2e;
  font: 600 24px "Poppins", sans-serif;
  padding: 120px 0 0 10%;
}

@media (max-width: 1024px) {
  .horizon .BannerView .BannerView-title h2 {
    font: 600 30px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .BannerView .BannerView-title h2 {
    font: 600 20px "Poppins", sans-serif;
  }
}

.horizon .contact_page {
 display: flex;
 justify-content: space-between;
}
@media (max-width: 1350px) {
  .horizon .contact_page {
  
   display: flex;
   justify-content: center;
  }
}
.horizon .contact_page .left_view {
  background: #f8f8f8;
  
}


.horizon .contact_page .left_view .Reach_us .set {
  float: left;
  margin-bottom: 32px;
}

.horizon .contact_page .left_view .Reach_us .set label {
  font: 400 20px "Poppins", sans-serif;
  float: left;
  color: #000000;
  margin-bottom: 8px;
}
.horizon .contact_page .left_view .Reach_us .set p {
  font: 500 16px "Poppins", sans-serif;
  width: 100%;
  float: left;
  color: #2e2e2e;
}
.horizon .contact_page .left_view .Reach_us .set p a {
  color: #2e2e2e;
  text-decoration: none;
}

.horizon .contact_page .left_view .Reach_us .set ul li {
  list-style: none;
  width: 50px;
  margin-right: 10px;
}

.horizon .contact_page .right_view .Get_In_Touch form label {
  font: 600 20px "Poppins", sans-serif;
  color: #2e2e2e;
  margin: 0 0 32px;
}

.horizon .contact_page .right_view .Get_In_Touch form .form-group {
  margin-bottom: 24px;
}

.horizon .contact_page .right_view .Get_In_Touch form .form-group label {
  font: 300 15px "Poppins", sans-serif;
  width: 100%;
  float: left;
  margin: 0 0 8px 0;
}

.horizon .contact_page .right_view .Get_In_Touch form .form-group input {
  width: 40vw;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #dedede;
  padding: 8px 16px;
  font: 500 15px "Poppins", sans-serif;
}

.horizon .contact_page .right_view .Get_In_Touch form .form-group textarea {
  width: 40vw;
  height: auto;
  font: 500 15px "Poppins", sans-serif;
  border-radius: 5px;
  resize: none;
  border: 1px solid #dedede;
  padding:  16px;
}

.horizon .contact_page .right_view .Get_In_Touch form .form-group button {
  float: left;
  background: #F6821F;
  border: 0;
  padding: 14px 35px;
  border-radius: 8px;
  color: #fff;
  font: 600 16px "Poppins", sans-serif;
  margin-top: 24px;
}

.horizon .contact_page .right_view .Get_In_Touch form .form-group button:hover {
  opacity: 0.8;
  transition: all 0.4s ease-in;
}

.horizon .Search-listing {
  width: 100%;
  float: left;
  // background: #f8f8f8;
  padding:  0;
}

@media (max-width: 1024px) {
  .horizon .Search-listing {
    padding: 20px 0;
  }
}

.horizon .Search-listing .Search-row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
grid-gap: 24px;
}

@media (max-width: 1024px) {
  .horizon .Search-listing .Search-row {
    grid-gap: 20px;
  }
}

@media (max-width: 991px) {
  .horizon .Search-listing .Search-row {
    display: block;
  }
}

.horizon .Search-listing .Search-row .filter {
  width: 100%;
  float: left;
  background: #fff;
  margin: 0 0 48px 0;
  padding: 32px;
  border: 1px solid #dedede;
  height: fit-content;
  border-radius: 8px;
}

@media (max-width: 1199px) {
  .horizon .Search-listing .Search-row .filter {
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .horizon .Search-listing .Search-row .filter {
    padding: 15px;
  }
}

@media (max-width: 991px) {
  .horizon .Search-listing .Search-row .filter {
    display: none;
  }
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .filter.mob-active {
    position: absolute;
    z-index: 99999;
    top: 0;
    height: auto;
    display: block;
    margin: 0;
    padding: 20px 15px 0;
    left: 0;
  }
}

.horizon .Search-listing .Search-row .filter .filter-header {
  width: 100%;
  float: left;
  padding: 0px 0 32px;
}

@media (max-width: 1024px) {
  .horizon .Search-listing .Search-row .filter .filter-header {
    padding: 0px 0 15px;
  }
}

.horizon .Search-listing .Search-row .filter .filter-header span {
  font: 400 20px "Poppins", sans-serif;
  color: #F6821F;
}

.horizon .Search-listing .Search-row .filter .filter-body {
  width: 100%;
  float: left;
}

.horizon .Search-listing .Search-row .filter .filter-body .item-block {
  width: 100%;
  float: left;
  margin: 0 0 24px;
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .filter .filter-body .item-block {
    margin: 0px 0 20px;
  }
}

.horizon .Search-listing .Search-row .filter .filter-body .item-block label {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
  width: 100%;
  float: left;
}

.horizon .Search-listing .Search-row .filter .filter-body .item-block select {
  border: 1px solid #d0d0d0;
  background: url(./assets/img/expand_icon.svg) no-repeat 95% center;
  margin: 10px 0 0;
  border-radius: 5px;
  padding: 0 10px;
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
  height: 37px;
  width: 100%;
  float: left;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.horizon .Search-listing .Search-row .filter .filter-body .item-block select option {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .Search-listing .Search-row .filter .filter-body .action-view {
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0;
  float: left;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  margin-top: 8px;
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .filter .filter-body .action-view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.horizon .Search-listing .Search-row .filter .filter-body .action-view button {
  border: 0;
  background: #F6821F;
  font: 400 16px "Poppins", sans-serif;
  padding: 10px;
  border-radius: 5px;
  min-width: 118px;
  color: #fff;
}
.horizon .Search-listing .Search-row .filter .filter-body .action-view button:hover{
  opacity: 0.8;
}


@media (max-width: 1199px) {
  .horizon .Search-listing .Search-row .Jobs {
    padding-left: 0px;
  }
}

.horizon .Search-listing .Search-row .Jobs .block {
  width: 100%;
  // background: #d9d9d9;
border: 1px solid #dedede;
border-radius: 8px;
  margin:  0 0 16px 0;
  padding: 30px;
  border-radius: 3px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 991px) {
  .horizon .Search-listing .Search-row .Jobs .block {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block {
    margin: 7px 0px 0px;
  }
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block {
    display: block;
  }
}

.horizon .Search-listing .Search-row .Jobs .block .icon {
  max-width: 100px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block .icon {
    float: left;
    padding-bottom: 12px;
  }
}

.horizon .Search-listing .Search-row .Jobs .block .icon img {
  max-width: 100%;
}

.horizon .Search-listing .Search-row .Jobs .block .details {
  padding: 0 0 0 45px;
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .Jobs .block .details {
    padding: 0 0 0 15px;
    width: auto;
    float: left;
  }
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block .details {
    padding: 0 0 0px;
  }
}

.horizon .Search-listing .Search-row .Jobs .block .details .postname {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 300 18px "Poppins", sans-serif;
  color: #F6821F;
  font-weight: 600;
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block .details .postname {
    font-size: 16px;
  }
}
.horizon .Search-listing .Search-row .Jobs .block .details .countPost,
.horizon .Search-listing .Search-row .Jobs .block .details .countdate,
.horizon .Search-listing .Search-row .Jobs .block .details .content {
  color: #6f6f6f;
  width: 100%;
  font: 400 16px "Poppins", sans-serif;
  float: left;
  padding: 11px 0 0;
}

@media (max-width: 576px) {

  .horizon .Search-listing .Search-row .Jobs .block .details .countPost,
  .horizon .Search-listing .Search-row .Jobs .block .details .countdate,
  .horizon .Search-listing .Search-row .Jobs .block .details .content {
    font: 300 13px "Poppins", sans-serif;
    padding: 7px 0 0;
  }
}

.horizon .Search-listing .Search-row .Jobs .block .moreView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: fit-content;
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block .moreView {
    width: 100%;
    float: left;
    padding: 1px 0 0;
    display: block;
  }
}

@media (max-width: 480px) {
  .horizon .Search-listing .Search-row .Jobs .block .moreView {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
}

.horizon .Search-listing .Search-row .Jobs .block .moreView a {
  display: block;
  border-radius: 5px;
  padding: 8px 32px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  border: 1px solid #F6821F;
  font: 300 14px "Poppins", sans-serif;
  background: #F6821F;
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .Jobs .block .moreView a {
    margin-bottom: 4px;
  }
}

@media (max-width: 576px) {
  .horizon .Search-listing .Search-row .Jobs .block .moreView a {
    float: left;
    margin: 13px 6px 0 0;
    max-width: 150px;
  }
}

.horizon .Search-listing .Search-row .Jobs .block .moreView a.clean {
  background: transparent;
  color: #F6821F;
}

.horizon .Search-listing .Search-row .Jobs .block .moreView a:hover {
  text-decoration: underline;
}

.horizon .Search-listing .Search-row .Jobs .load-more {
  float: left;
  width: 100%;
  margin: 20px 0 0;
}

.horizon .Search-listing .Search-row .Jobs .load-more a {
  padding: 12px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  display: block;
  border: 3px solid #F6821F;
  color: #F6821F;
  font: 600 18px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .horizon .Search-listing .Search-row .Jobs .load-more a {
    border: 1px solid #F6821F;
    font: 400 14px "Poppins", sans-serif;
  }
}

.horizon .Search-listing .Search-row .Top-Companies {
  width: 100%;
  float: left;
  background: transparent;
  padding: 0 0 48px;
}

@media (max-width: 991px) {
  .horizon .Search-listing .Search-row .Top-Companies {
    padding: 10px 7px 60px;
  }
}

.horizon .Search-listing .Search-row .Top-Companies .Companies {
  width: 100%;
  float: left;
  grid-gap: 16px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(330px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}

@media (max-width: 1024px) {
  .horizon .Search-listing .Search-row .Top-Companies .Companies {
    -ms-grid-columns: (minmax(290px, 1fr)) [auto-fill];
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    grid-gap: 25px;
  }
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .Top-Companies .Companies {
    grid-gap: 15px;
  }
}

.horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock {
  background: #fff;
  padding: 7px 20px;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock {
    padding: 7px 20px 7px 10px;
  }
}

.horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .icon {
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .icon {
    padding: 5px;
  }
}

@media (max-width: 1024px) {
  .horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .icon {
    padding: 5px 0;
  }
}

.horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .icon img {
  max-width: 100px;
}

.horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .details {
  padding: 0 0 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 768px) {
  .horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .details {
    padding: 0 0 0 5px;
  }
}

.horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .details .CompName {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 600 14px "Poppins", sans-serif;
  color: #6f6f6f;

}

.horizon .Search-listing .Search-row .Top-Companies .Companies .Companyblock .details .loctn {
  color: #6d6e71;
  width: 100%;
  text-decoration: none;
  font: 500 14px "Poppins", sans-serif;
  float: left;
  padding: 10px 0 0;
}


@media (max-width: 1024px) {
  .horizon .Job-Detail-Page {
    padding: 20px 24px;
  }
}


.horizon .Job-Detail-Page .block {
  width: 100%;
  width: 780px;
  padding: 24px;
  border-radius: 4px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 999;
  position: relative;
  background-color: #fff;
  border: 1px solid #dedede;
}

@media (max-width: 1024px) {
  .horizon .Job-Detail-Page .block {
    padding: 15px;
    margin: 0 auto ;
  }

  .horizon .Job-Detail-Page .block {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block {
    margin: 7px 0px 0px;
  }
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block {
    display: block;
  }
}

.horizon .Job-Detail-Page .block .icon {
  max-width: 100px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block .icon {
    float: left;
    padding-bottom: 12px;
  }
}

.horizon .Job-Detail-Page .block .icon img {
  max-width: 100%;
}

.horizon .Job-Detail-Page .block .details {
  padding: 0 0 0 45px;
}

@media (max-width: 768px) {
  .horizon .Job-Detail-Page .block .details {
    padding: 0 0 0 15px;
    width: auto;
    float: left;
  }
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block .details {
    padding: 0 0 0px;
  }
}

.horizon .Job-Detail-Page .block .details .postname {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 600 18px "Poppins", sans-serif;
  color: #F6821F;
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block .details .postname {
    font: 300 16px "Poppins", sans-serif;
  }
}

.horizon .Job-Detail-Page .block .details .postname:hover {
  color: #2e2e2e;
}

.horizon .Job-Detail-Page .block .details .countPost,
.horizon .Job-Detail-Page .block .details .countdate,
.horizon .Job-Detail-Page .block .details .content {
  color: #5d5d5dbf;
  width: 100%;
  font: 300 15px "Poppins", sans-serif;
  float: left;
  padding: 11px 0 0;
}

@media (max-width: 576px) {

  .horizon .Job-Detail-Page .block .details .countPost,
  .horizon .Job-Detail-Page .block .details .countdate,
  .horizon .Job-Detail-Page .block .details .content {
    font: 300 13px "Poppins", sans-serif;
    padding: 7px 0 0;
  }
}

.horizon .Job-Detail-Page .block .details .countPost,
.horizon .Job-Detail-Page .block .details .countdate {
  color: #6f6f6f;
}

.horizon .Job-Detail-Page .block .moreView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 190px;
  margin-top: auto;
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block .moreView {
    width: 100%;
    float: left;
    padding: 1px 0 0;
    display: block;
  }
}

.horizon .Job-Detail-Page .block .moreView a {
  display: block;
  border-radius: 5px;
  padding: 8px 16px;
  text-decoration: none;
  text-align: center;
  color: #F6821F;
  border: 1px solid #F6821F;
  font: 300 14px "Poppins", sans-serif;
  background: transparent;
  margin-bottom: 7px;
}

.horizon .Job-Detail-Page .block .moreView a.go-to {
  border: 1px solid #F6821F;
  color: #fff;
  background: #F6821F;
}

@media (max-width: 576px) {
  .horizon .Job-Detail-Page .block .moreView a {
    float: left;
    margin: 16px 8px 0 0;
    max-width: 150px;
  }
}

.horizon .Job-Detail-Page .block .moreView a.clean {
  background: transparent;
}

.horizon .Job-Detail-Page .block .moreView a:hover {
  text-decoration: underline;
}

.horizon .Similar-Jobs-listing {
  width: 100%;
  float: left;
  // background: url(./assets/img/How-it-works.png);
  background-repeat: repeat;
  padding: 0 0 50px;
  background-color: #f8f8f8;
}

@media (max-width: 1024px) {
  .horizon .Similar-Jobs-listing {
    padding: 20px 0;
  }
}

@media (max-width: 768px) {
  .horizon .Similar-Jobs-listing {
    padding: 0;
  }
}

.horizon .Similar-Jobs-listing .Jobs {
  width: 100%;
  max-width: 900px;
  float: left;
  padding-top: 48px;
}

@media (max-width: 1280px) {
  .horizon .Similar-Jobs-listing .Jobs {
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .Similar-Jobs-listing .Jobs {
    padding: 10px 0px;
  }
}

.horizon .Similar-Jobs-listing .Jobs .Similar-title {
  float: left;
  width: 100%;
}

.horizon .Similar-Jobs-listing .Jobs .Similar-title h3 {
  color: #2e2e2e;
  text-align: left;
  font: 600 24px "Poppins", sans-serif;
  margin: 0;
  width: auto;
  float: left;
  padding: 0px 0 32px;
}

@media (max-width: 991px) {
  .horizon .Similar-Jobs-listing .Jobs .Similar-title h3 {
    font: 600 22px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .Similar-Jobs-listing .Jobs .Similar-title h3 {
    padding: 0px 0 20px;
    font: 600 18px "Poppins", sans-serif;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block {
  width: 780px;
  background: #fff;
  margin: 16px 0px 0px;
  padding: 30px;
  border-radius: 3px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  border: 1px solid #dedede;
}

@media (max-width: 991px) {
  .horizon .Similar-Jobs-listing .Jobs .block {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block {
    margin: 7px 0px 0px;
  }
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block {
    display: block;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .icon {
  max-width: 100px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block .icon {
    float: left;
    padding-bottom: 12px;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .icon img {
  max-width: 100%;
}

.horizon .Similar-Jobs-listing .Jobs .block .details {
  padding: 0 0 0 45px;
}

@media (max-width: 768px) {
  .horizon .Similar-Jobs-listing .Jobs .block .details {
    padding: 0 0 0 15px;
    width: auto;
    float: left;
  }
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block .details {
    padding: 0 0 0px;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .details .postname {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 300 18px "Poppins", sans-serif;
  color: #F6821F;
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block .details .postname {
    font: 300 16px "Poppins", sans-serif;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .details .countPost,
.horizon .Similar-Jobs-listing .Jobs .block .details .countdate,
.horizon .Similar-Jobs-listing .Jobs .block .details .content {
  color: #6f6f6f;
  width: 100%;
  font: 300 15px "Poppins", sans-serif;
  float: left;
  padding: 11px 0 0;
}

@media (max-width: 576px) {

  .horizon .Similar-Jobs-listing .Jobs .block .details .countPost,
  .horizon .Similar-Jobs-listing .Jobs .block .details .countdate,
  .horizon .Similar-Jobs-listing .Jobs .block .details .content {
    font: 300 13px "Poppins", sans-serif;
    padding: 7px 0 0;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .moreView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 230px;
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block .moreView {
    width: 100%;
    float: left;
    padding: 1px 0 0;
    display: block;
  }
}

@media (max-width: 480px) {
  .horizon .Similar-Jobs-listing .Jobs .block .moreView {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .moreView a {
  display: block;
  border-radius: 5px;
  padding: 7px 20px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  border: 1px solid #F6821F;
  font: 300 14px "Poppins", sans-serif;
  background: #F6821F;
}

@media (max-width: 768px) {
  .horizon .Similar-Jobs-listing .Jobs .block .moreView a {
    margin-bottom: 4px;
  }
}

@media (max-width: 576px) {
  .horizon .Similar-Jobs-listing .Jobs .block .moreView a {
    float: left;
    margin: 16px 8px 0 0;
    max-width: 150px;
    display: flex;
  }
}

.horizon .Similar-Jobs-listing .Jobs .block .moreView a.clean {
  background: transparent;
  color: #F6821F;
}

.horizon .Similar-Jobs-listing .Jobs .block .moreView a:hover {
  text-decoration: underline;
}

.horizon .company-view .company-area {
  width: 100%;
  float: left;
  padding: 0;
display: grid;
grid-template-columns: 780px 1fr;
grid-gap: 24px;
margin: 24px 0;
}
@media (max-width: 1199px) {
  .horizon .company-view {
    padding: 0px 0;
  }
  .horizon .company-view .company-area {
   display: flex;
   flex-direction: column;
  }
}
@media (max-width: 1199px) {
  .horizon .company-view .company-area {
    padding: 0;
  }
}

@media (max-width: 1024px) {
  .horizon .company-view .company-area {
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .company-view .company-area {
    padding: 0px 0 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
}

.horizon .company-view .company-area .job-description {
  width: 100%;
  float: left;
  background: #fff;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description {
    padding: 0px 0px 15px;
  }
}

.horizon .company-view .company-area .job-description .content-area {
  padding: 24px;
  width: 100%;
  float: left;
  border: 1px solid #dedede;
  border-radius: 8px;
margin-bottom: 24px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description .content-area {
    padding: 20px 15px 0;
  }
}

.horizon .company-view .company-area .job-description .content-area .description {
  width: 100%;
  float: left;
}

.horizon .company-view .company-area .job-description .content-area .description h4 {
  font: 400 20px "Poppins", sans-serif;
  color: #2e2e2e;
  padding: 0 0 16px 0;
  margin: 0;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description .content-area .description h4 {
    font: 500 18px "Poppins", sans-serif;
    padding: 5px 0;
  }
}

.horizon .company-view .company-area .job-description .content-area .description .job-title {
  width: 100%;
  font: 500 20px "Poppins", sans-serif;
  color: #2e2e2e;
  padding: 10px 0;
  float: left;
  margin: 0;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description .content-area .description .job-title {
    padding: 5px 0;
    font: 500 14px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-area .job-description .content-area .description .job-title-view {
  float: left;
  width: 100%;
  margin: 0;
  font: 500 20px "Poppins", sans-serif;
  color: #2e2e2e;
  padding: 10px 0;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description .content-area .description .job-title-view {
    padding: 5px 0;
    font: 500 14px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-area .job-description .content-area .description p {
  padding: 0;
  font: 400 16px "Poppins", sans-serif;
  color: #2e2e2e;
  margin: 0;
}

.horizon .company-view .company-area .job-description .content-area .job-details {
  width: 100%;
  float: left;
}

.horizon .company-view .company-area .job-description .content-area .job-details .job-title {
  width: 100%;
  font: 600 20px "Poppins", sans-serif;
  color: #2e2e2e;
  padding: 10px 0;
  float: left;
  margin: 0;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description .content-area .job-details .job-title {
    font: 500 14px "Poppins", sans-serif;
    padding: 5px 0;
  }
}

.horizon .company-view .company-area .job-description .content-area .job-details table {
  padding: 2px;
}

.horizon .company-view .company-area .job-description .content-area .job-details table td {
  font: 400 16px "Poppins", sans-serif;
  color: #2e2e2e;
  padding: 10px 10px 10px 0px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .job-description .content-area .job-details table td {
    font: 300 13px "Poppins", sans-serif;
    padding: 5px 5px 5px 0px;
  }
}

.horizon .company-view .company-area .company-profile {
  width: 100%;
  float: left;
  background: #fff;
  padding: 0px 0;
}

.horizon .company-view .company-area .company-profile .profile {
  width: 100%;
  padding: 30px;
  float: left;
  border: 1px solid #dedede;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile {
    padding: 0 15px;
  }
}

.horizon .company-view .company-area .company-profile .profile img {
  display: block;
  width: 100%;
  max-width: 100%;
  max-height: 125px;
  object-fit: contain;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile img {
    max-width: 146px;
  }
}

.horizon .company-view .company-area .company-profile .profile span {
  width: 100%;
  float: left;
  color: #2e2e2e;
  font: 400 20px "Poppins", sans-serif;
  text-align: center;
  margin: 16px 0;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile span {
    font: 300 18px "Poppins", sans-serif;
    padding: 5px 0 10px;
    text-align: left;
  }
}

.horizon .company-view .company-area .company-profile .profile p {
  font: 500 14px "Poppins", sans-serif;
  color: #6f6f6f;
  text-align: left;
  width: 100%;
  float: left;
  line-height: 24px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile p {
    font: 300 14px "Poppins", sans-serif;
    text-align: left;
  }
}

.horizon .company-view .company-area .company-profile .profile ul {
  padding: 20px  0;
  margin:  0;
  width: 100%;
  border-top: 1px solid #dedede;
  float: left;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile ul {
    margin: 20px 0 0;
    border-top: 1px solid #7070702b;
    border-bottom: 1px solid #7070702b;
    padding: 15px 0;
  }
}

.horizon .company-view .company-area .company-profile .profile ul li {
  width: 100%;
 
}

.horizon .company-view .company-area .company-profile .profile ul li span {
  max-width: 210px;
  width: 100%;
  display: flex;
}

.horizon .company-view .company-area .company-profile .profile ul li span img {
width: 15px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile ul li span img {
    max-width: 20px;
  }
}

.horizon .company-view .company-area .company-profile .profile ul li span a {
  padding: 0 0 0 10px;
  display: block;
  color: #00a7ff;
  text-decoration: none;
  float: left;
  font: 400 14px "Poppins", sans-serif;
  text-align: left;
}

@media (max-width: 768px) {
  .horizon .company-view .company-area .company-profile .profile ul li span a {
    font: 300 14px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-area .company-profile .profile ul li span a:hover {
  color: #2e2e2e;
}

.horizon .company-view .company-current {
  width: 100%;
  float: left;
}

.horizon .company-view .company-current .job-post {
  width: 100%;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 3.7fr 1.3fr;
  grid-template-columns: 3.7fr 1.3fr;
  width: 100%;
  float: left;
  padding: 0px 0 50px;
  grid-gap: 30px;
}

@media (max-width: 1199px) {
  .horizon .company-view .company-current .job-post {
    padding: 30px 15px 50px;
  }
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post {
    padding: 0px 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
}

.horizon .company-view .company-current .job-post .Jobs {
  width: 100%;
  float: left;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .Jobs {
    padding: 0 15px;
  }
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs {
    padding: 0 8px;
  }
}

.horizon .company-view .company-current .job-post .Jobs .Current-title {
  float: left;
  width: 100%;
}

.horizon .company-view .company-current .job-post .Jobs .Current-title h3 {
  color: #2e2e2e;
  text-align: left;
  font: 600 32px 'Noto Serif', serif;
  margin: 0;
  float: left;
  padding: 0 0 48px;

}

@media (max-width: 991px) {
  .horizon .company-view .company-current .job-post .Jobs .Current-title h3 {
    font: 600 22px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .Jobs .Current-title h3 {
    padding: 15px 0;
    font: 600 18px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block {
  width: 100%;
  background: #fff;
  margin: 20px 0px 0px;
  padding: 30px;
  border-radius: 3px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 991px) {
  .horizon .company-view .company-current .job-post .Jobs .block {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block {
    margin: 7px 0px 0px;
  }
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block {
    display: block;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .icon {
  max-width: 100px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block .icon {
    float: left;
    padding-bottom: 12px;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .icon img {
  max-width: 100%;
}

.horizon .company-view .company-current .job-post .Jobs .block .details {
  padding: 0 0 0 45px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .Jobs .block .details {
    padding: 0 0 0 15px;
    width: auto;
    float: left;
  }
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block .details {
    padding: 0 0 0px;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .details .postname {
  width: 100%;
  text-decoration: none;
  float: left;
  font: 600 16px "Poppins", sans-serif;
  color: #f6821f;
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block .details .postname {
    font: 600 16px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .details .countPost,
.horizon .company-view .company-current .job-post .Jobs .block .details .countdate,
.horizon .company-view .company-current .job-post .Jobs .block .details .content {
  color: #6f6f6f;
  width: 100%;
  font: 400 15px "Poppins", sans-serif;
  float: left;
  padding: 11px 0 0;
}

@media (max-width: 576px) {

  .horizon .company-view .company-current .job-post .Jobs .block .details .countPost,
  .horizon .company-view .company-current .job-post .Jobs .block .details .countdate,
  .horizon .company-view .company-current .job-post .Jobs .block .details .content {
    font: 300 13px "Poppins", sans-serif;
    padding: 7px 0 0;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .moreView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 230px;
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block .moreView {
    width: 100%;
    float: left;
    padding: 1px 0 0;
    display: block;
  }
}

@media (max-width: 480px) {
  .horizon .company-view .company-current .job-post .Jobs .block .moreView {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .moreView a {
  display: block;
  border-radius: 5px;
  padding: 7px 20px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  border: 1px solid #F6821F;
  font: 400 14px "Poppins", sans-serif;
  background: #F6821F;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .Jobs .block .moreView a {
    margin-bottom: 4px;
  }
}

@media (max-width: 576px) {
  .horizon .company-view .company-current .job-post .Jobs .block .moreView a {
    float: left;
    margin: 13px 6px 0 0;
    max-width: 150px;
  }
}

.horizon .company-view .company-current .job-post .Jobs .block .moreView a.clean {
  background: transparent;
  color: #F6821F;
}

.horizon .company-view .company-current .job-post .Jobs .block .moreView a:hover {
  text-decoration: underline;
}

.horizon .company-view .company-current .job-post .company-profile {
  width: 100%;
  float: left;
  background: #fff;
  padding: 0;
}

.horizon .company-view .company-current .job-post .company-profile .profile {
  width: 100%;
  padding: 30px;
  float: left;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile {
    padding: 0 15px;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile img {
  max-width: 186px;
  display: block;
  width: 100%;
  margin: auto;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile img {
    max-width: 146px;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile span {
  width: 100%;
  float: left;
  color: #2e2e2e;
  font: 400 16px "Poppins", sans-serif;
  text-align: center;
  padding: 20px 0;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile span {
    font: 300 18px "Poppins", sans-serif;
    padding: 5px 0 10px;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile p {
  font: 400 14px "Poppins", sans-serif;
  color: #a1a1a1;
  width: 100%;
  float: left;
  line-height: 26px;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile p {
    font: 300 14px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile ul {
  padding: 20px 0 0;
  margin:  0 0;
  width: 100%;
  border-top: 1px solid #dedede;
  float: left;
}
.explore_companies_nav{
  display: flex;
  margin: 48px 0;
}
.explore_companies_nav > .separator{
  margin: 0 4px;
}
.explore_companies_nav > div > a{
  color: #6f6f6f;
}
@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile ul {
    margin: 20px 0 0;
    border-top: 1px solid #7070702b;
    border-bottom: 1px solid #7070702b;
    padding: 15px 0;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile ul li {
  width: 100%;
  float: left;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

 
}

.horizon .company-view .company-current .job-post .company-profile .profile ul li span {
 
  width: 100%;
}

@media (max-width: 1024px) {
  .horizon .company-view .company-current .job-post .company-profile .profile ul li span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile ul li span {
    display: block;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile ul li span img {
  width: 20px;
  float: left;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile ul li span img {
    max-width: 20px;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile ul li span a {
  padding: 0 0 0 8px;
width: 100%;
  color: #00a7ff;
  float: left;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .company-view .company-current .job-post .company-profile .profile ul li span a {
    font: 300 14px "Poppins", sans-serif;
  }
}

.horizon .company-view .company-current .job-post .company-profile .profile ul li span a:hover {
  color: #2e2e2e;
}

.horizon .dashboard_home {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100% !important;
}

.horizon .dashboard_home .Reading_View {
  width: 100%;
  float: left;
  padding: 24px;
  background: #f5f5f5;
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Reading_View {
    padding: 25px 20px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View {
    padding: 15px 13px 25px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container {
  width: 100%;
  float: left;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container {
    padding-top: 30px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head {
  width: 100%;
  float: left;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head {
    padding: 0 0 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .page_title {
  color: #2e2e2e;
font-weight: 600;
font-size: 20px;
padding: 0 0 24px 0;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .page_title {
    font: 600 16px "Poppins", sans-serif;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .page_title {
    padding: 0 0 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search {
    float: left;
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search span {
  color: #2e2e2e;
  font: 500 10px "Poppins", sans-serif;
  padding-right: 15px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search span {
    display: none;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you {
  background: #fff;
  border-radius: 4px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you {
    max-width: 500px;
    height: 40px;
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you {
    max-width: 350px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you {
    max-width: 100%;
    width: 100%;
  }
 
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input {
  background: #fff;
  border: 0;
  outline: none;
  color: #2e2e2e;
  width: 100%;
  font: 500 14px "Poppins", sans-serif;
  padding: 0 15px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input {
    padding: 0 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input:first-child {
  border-right: 1px solid #c1c1c185;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input::-webkit-input-placeholder {
  color: #8e8e93;
  font: 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input:-ms-input-placeholder {
  color: #8e8e93;
  font: 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input::-ms-input-placeholder {
  color: #8e8e93;
  font: 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you input::placeholder {
  color: #8e8e93;
  font: 10px "Poppins", sans-serif;
  line-height: 42px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you button.searchBtn {
  border: 0;
  background: #F6821F;
  height: 100%;
  width: 100%;
  max-width: 44px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Reading_head .new_search .find-you button img {
  max-width: 15px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview {
  width: 100%;
  height: auto;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 24px;
  padding-bottom: 24px;
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview {
    grid-gap: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 1440px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview {
    grid-gap: 15px;
    padding-bottom: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view {
  border-radius: 5px;
  padding: 28px 28px;
  border-top: 4px solid;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view {
    padding: 20px 20px;
  }
}

@media (max-width: 1024px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view {
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .detaild_view .title {
  width: 100%;
  height: auto;
  float: left;
  color: #6f6f6f;
  letter-spacing: 1px;
  font: 600 14px "Poppins", sans-serif;
}

@media (max-width: 1024px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .detaild_view .title {
    font: 600 11px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .detaild_view .total_count {
  width: 100%;
  height: auto;
  float: left;
  padding: 17px 0;
  font: 600 30px "Poppins", sans-serif;
  color: #2e2e2e;
}

@media (max-width: 1024px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .detaild_view .total_count {
    padding: 10px 0;
    font: 600 22px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .detaild_view .full_stretch {
  width: 100%;
  height: auto;
  float: left;
  font: 400 14px "Poppins", sans-serif;
  color: #F6821F;
letter-spacing: 1px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .status_on_circle .circle {
  width: 100px;
  height: 100px;
  border: 3px solid;
  border-radius: 50%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 1024px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .status_on_circle .circle {
    width: 70px;
    height: 70px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .status_on_circle .circle .percentage_value {
  font: 600 23px "Poppins", sans-serif;
}

@media (max-width: 1024px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view .status_on_circle .circle .percentage_value {
    font: 600 18px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.violet {
  border-color: #bc78e0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.violet .status_on_circle .circle {
  border-color: #bc78e04f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.violet .status_on_circle .circle .percentage_value {
  color: #bc78e0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.blue_wit {
  border-color: #00d7e5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.blue_wit .status_on_circle .circle {
  border-color: #00d7e557;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.blue_wit .status_on_circle .circle .percentage_value {
  color: #00d7e5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.yellow {
  border-color: #ffaf6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.yellow .status_on_circle .circle {
  border-color: #ffaf6f63;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .application_overview .card_view.yellow .status_on_circle .circle .percentage_value {
  color: #ffaf6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.9fr 0.9fr;
  grid-template-columns: 1.9fr 0.9fr;
  grid-gap: 30px;
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs {
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs {
  border-radius: 5px;
  padding: 28px 28px;
  background: #fff;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs {
    padding: 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 0 24px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .title span {
  font: 600 14px "Poppins", sans-serif;
  float: left;
  color: #6f6f6f;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .title a {
  font: 400 14px "Poppins", sans-serif;
  color: #F6821F;
  letter-spacing: 1px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view {
  width: 100%;
  height: auto;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier {
  float: left;
  width: 100%;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dedede;
  margin-bottom: 15px;

}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .view {
  float: left;
  max-width: 100px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .view {
    max-width: 60px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .view img {
  max-width: 77px;
  width: 100%;
  border-radius: 50%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details {
  max-width: 250px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details {
    max-width: 200px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate {
  padding: 0 23px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate {
    padding: 10px 0 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .name {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .position {
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 4px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .timeLevel {
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 4px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier // .details

.action .status {
  width: 60px;
  margin-bottom: 12px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier // .details

.action .status span {
  font: 500 12px "Poppins", sans-serif;
  padding: 4px 7px;
  border-radius: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier // .details

.action .status.Submitted span {
  color: #be7209;
  background-color: #fff6d0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier // .details

.action .status.Shortlisted span {
  color: #228934;
  background-color: #dbffe1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier // .details

.action .status.Rejected span {
  color: #FF4853;
  background-color: #ffedec;
  font-size: 12px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action {
  float: left;
  max-width: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action {
    max-width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action a {
  font: 500 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
  color: #F6821F;
  border-radius: 5px;
  margin-top: 12px;
  font-size: 14px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
    margin-top: 5px;
    font: 500 10px "Poppins", sans-serif;
    padding: 6px 20px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
    font: 500 9px "Poppins", sans-serif;
    padding: 6px 14px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Recently_Applied_Jobs .applier_view .applier .action a.share {
  color: #F6821F;
  font-size: 14px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers {
  border-radius: 5px;
  padding: 28px 28px;
  background: #fff;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers {
    padding: 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .title {
    padding-bottom: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .title span {
  font: 600 14px "Poppins", sans-serif;
  float: left;
  color: #6f6f6f;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .title a {
  text-decoration: none;
  font: 500 12px "Poppins", sans-serif;
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view {
  width: 100%;
  height: auto;
  float: left;
  padding: 10px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view .listings {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view .listings .candidate {
  padding: 10px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view .listings .candidate .post {
  color: #2e2e2e;
  font: 600 13px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view .listings .candidate .SourceLevel {
  color: #afafaf;
  font: 500 11px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 7px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view .listings .action a {
  color: #2e2e2e;
  padding: 0;
  font: 500 11px "Poppins", sans-serif;
  border-radius: 5px;
  margin-top: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Recently_Jobs .Jobs_Offers .offer_view .listings .end_list {
  color: #a1a1a1;
  font: 500 14px "Poppins", sans-serif;
  width: 100%;
  background: #fff;
  margin-top: 24px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.9fr 0.9fr;
  grid-template-columns: 1.9fr 0.9fr;
  grid-gap: 24px;
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer {
    grid-gap: 20px;
  }
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer {
    grid-gap: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs {
  border-radius: 5px;
  padding: 28px 28px;
  background: #fff;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs {
    padding: 22px 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 0 18px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .title {
    padding: 0px 0 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .title span {
  color: #6f6f6f;
  letter-spacing: 1px;
  font: 600 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .title a {
  font: 500 14px "Poppins", sans-serif;
  color: #F6821F;
  letter-spacing: 1px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 7px 0;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label {
  margin-right: 29px;
  font-size: 20px;
  cursor: pointer;
  font: 600 12px "Poppins", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  color: #2e2e2e;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label {
    margin-bottom: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label.Applications input[type="checkbox"].toggle:checked {
  background-color: #aa7ee6;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label.Shortlisted input[type="checkbox"].toggle:checked {
  background-color: #02dad0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label.Rejected input[type="checkbox"].toggle:checked {
  background-color: #ff3b30;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label input[type="checkbox"].toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 27px;
  height: 15px;
  background: #ddd;
  vertical-align: middle;
  border-radius: 1.6em;
  position: relative;
  outline: 0;
  margin-right: 7px;
  margin-top: -1px;
  cursor: pointer;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label input[type="checkbox"].toggle {
    margin-top: 1px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label input[type="checkbox"].toggle::after {
  content: "";
  width: 14px;
  height: 15px;
  background: white;
  position: absolute;
  border-radius: 1.2em;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 1px;
  -webkit-box-shadow: 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px rgba(0, 0, 0, 0.5);
  -webkit-transition: left 0.1s ease-in-out;
  transition: left 0.1s ease-in-out;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label input[type="checkbox"].toggle:checked {
  background: deepskyblue;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .filter label input[type="checkbox"].toggle:checked::after {
  left: 13px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .canvas {
  height: 200px;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view {
  height: auto;
  width: 100%;
  float: left;
  margin-top: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table {
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr {
  padding: 0 0 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr th {
  color: #6f6f6f;
  font: 600 14px "Poppins", sans-serif;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
  text-align: right;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr th:first-child {
  text-align: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr td {
  color: #2e2e2e;
  font: 500 12px "Poppins", sans-serif;
  padding: 15px 0;
  text-align: right;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr td {
    padding: 15px 0 0;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr td:first-child {
  text-align: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr td a {
  text-decoration: none;
  font: 600 12px "Poppins", sans-serif;
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .Overview_Employer_Jobs .Overview_full_view table tr td a:hover {
  color: #2e2e2e;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view {
  width: 100%;
  float: left;
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view {
    display: block;
  }
   
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions {
  width: 100%;
  float: left;
  border-radius: 5px;
  height: auto;
  background: #fff;
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions {
    width: 50%;
    margin-right: 10px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions {
    width: 100%;
    margin-right: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  padding: 24px 20px 12px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .title span {
  color: #6f6f6f;
  font: 600 14px "Poppins", sans-serif;
  letter-spacing: 1px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .title a {
  font: 400 12px "Poppins", sans-serif;
  color: #a1a1a1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view {
  width: 100%;
  height: auto;
  float: left;
  padding: 24px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings {
  width: 100%;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 14px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings.Applications .icon {
  background: #958ae3;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings.Shortlisted .icon {
  background: #00dad0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings.For_Interview .icon {
  background: #ffcc00;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings.Rejected .icon {
  background: #ff3b30;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings .candidate {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings .candidate .icon {
  width: 17px;
  height: 12px;
  border-radius: 15px;
  margin-right: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings .candidate .SourceLevel {
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  float: left;
  padding:  0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .Acquisitions .body_view .listings span {
  color: #2e2e2e;
  font: 600 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications {
  float: left;
  width: 100%;
  border-radius: 5px;
  height: auto;
  background: #fff;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications {
    width: 50%;
    margin-top: 0px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications {
    width: 100%;
    margin-top: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebebeb;
  padding: 24px 20px 12px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .title span {
  color: #6f6f6f;
  font: 600 14px "Poppins", sans-serif;
  letter-spacing: 1px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .title a {
  font: 400 12px "Poppins", sans-serif;
  color: #a1a1a1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view {
  width: 100%;
  height: auto;
  float: left;
  padding: 15px 20px 15px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings {
  width: 100%;
  text-decoration: none;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 0 15px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings:hover .name {
  opacity: 0.7;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings .photo {
  width: 34px;
  height: 29px;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
  overflow: hidden;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings .photo img {
  width: 100%;
  height: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings .candidate {
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings .candidate .name {
  color: #2e2e2e;
  font: 600 12px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Overview_Employer .right_view .New_Applications .body_view a.listings .candidate .SourceLevel {
  color: #8e8e93;
  font: 600 10px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 2px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs {
  float: left;
  width: 100%;
  border-radius: 5px;
  height: auto;
  background: #fff;

  padding: 30px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs {
    margin-top: 10px;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs {
    padding: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 20px;
  float: left;
  width: 100%;
  padding: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .title span {
  color: #6f6f6f;
  float: left;
  font: 600 16px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .title span a {
  font: 600 16px "Poppins", sans-serif;
  float: left;
  background: #F5831F;
  color: #fff;
  border-radius: 4px;
  display: block;
  text-decoration: none;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .title span a {
    font: 600 11px "Poppins", sans-serif;
    padding: 10px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .title a {
  font: 600 12px "Poppins", sans-serif;
  color: #F6821F;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .title a {
    font: 600 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs {
  float: left;
  width: 100%;
  padding-bottom: 30px;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs {
    overflow-y: auto;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: #f5f5f5;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs .title {
  padding: 24px 0px 16px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr {
  width: 100%;
  padding: 0 10px;
  border-radius: 5px;
  border: solid 1px #dedede;
  text-align: left;
  margin-top: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 1fr 1fr 1fr 0.5fr 1fr 0.5fr 0.5fr;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 0.5fr 1fr 0.5fr 0.5fr;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr {
    padding: 0 4px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr.Payment_head {
  margin-top: 0px;
  border: solid 0px;
  background: #f5f5f5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr.Payment_head th {
  padding: 10px 0;
  color: #6f6f6f;
  font: 600 12px "Poppins", sans-serif;
  float: left;
  width: fit-content;
  // text-align: center;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr.Payment_head th {
    font: 600 9px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td {
  float: left;
  color: #2e2e2e;
  font: 400 14px "Poppins", sans-serif;
  // display: -ms-grid;
  // display: grid;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // align-items: center;
  // -webkit-box-pack: left;
  // -ms-flex-pack: left;
  // justify-content: left;
  // min-width: 60px;
  width: fit-content;
  padding: 10px 0;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td {
    padding: 10px;
    font: 600 10px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td {
    padding: 8px 5px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .TotalView a {
text-decoration: underline;
  color: #F6831F;

}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .Status {
  padding: 6px 12px;
  font: 600 10px "Poppins", sans-serif;
  text-align: center;
  border-radius: 18px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .Status {
    padding: 3px 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .Status.active {
  color: #228934;
  background-color: #dbffe1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .Status.in_active {
  color: #ff3b30;
  background-color: #ffe7e6;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .action a.edit {
  background: url(./assets/img/edit.svg) center no-repeat;
  background-size: 20px;
  width: 40px;
  height: 20px;
  float: left;
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Job-Listed-Jobs .Listed-Jobs table tr td .action a.view {
  background: url(./assets/img/eye.svg) center no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  float: left;
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .title a {
  color: #F6821F;
  font: 400 14px "Poppins", sans-serif;
  text-decoration: underline;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .title a {
    font: 600 11px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value {
  -webkit-box-align: self-start;
  -ms-flex-align: self-start;
  align-items: self-start;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value.text_area textarea {
  float: left;
  width: 100%;
  height: 102px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value.block {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value.block input {
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status {
  float: left;
  width: 100%;
  margin: 15px 0 0;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status {
    margin: 15px 0 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify {
  float: left;
  margin-right: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify .status,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify .un_verified {
  float: left;
  text-decoration: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify .un_verified {
  padding: 3px 28px;
  color: #fff;
  font: 600 10px "Poppins", sans-serif;
  border-radius: 59px;
  background: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify .un_verified:hover {
  opacity: 0.9;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify .status {
  padding: 3px 28px;
  font: 600 10px "Poppins", sans-serif;
  text-align: center;
  border-radius: 18px;
  color: #228934;
  background-color: #dbffe1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify.not_verified .status {
  display: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify.not_verified .un_verified {
  display: inline-block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify.verified .status {
  display: inline-block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .conect-status .verify.verified .un_verified {
  display: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .value_get {
  height: 41px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  min-width: 175px;
  border-radius: 0px;
  border-bottom: solid 1px #efefef;
  color: #2e2e2e;
  padding: 12px 0 0;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .value_get {
    max-width: 450px;
    clear: both;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .value_get .change {
  float: right;
  color: #F6821F;
  text-transform: capitalize;
 
  display: block;
  font: 400 14px "Poppins", sans-serif;
  padding-left: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .value_get .change:hover {
  text-decoration: underline;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload {
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload-preview {
  float: left;
  width: 130px;
  height: 130px;
  object-fit: contain;
  border-radius: 5px;
  background: #f0f0f0;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload-preview {
    width: 100px;
    height: 120px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload-preview.user {
  background: url(./assets/img/icon/icons-8-user.png) #f0f0f0 center no-repeat;
  background-size: 50px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload {
  width: 200px;
  float: left;
  padding-left: 20px;
  margin: 10px 0;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload {
    padding-left: 0px;
    padding-top: 6px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload {
    padding-left: 20px;
    padding-top: 0px;
    width: 200px;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload {
    padding-left: 10px;
    padding-top: 0px;
    width: 188px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload .upload_field {
  width: 100%;
  height: 30px;
  float: left;
  position: relative;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload .upload_field .upload_field_text {
  margin: 0;
  text-align: center;
  padding: 8px 38px;
  background: #f0f0f0;
  color: #787e8c;
  font: 600 10px "Poppins", sans-serif;
  height: 30px;
  border-radius: 30px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload .upload_field input[type="file"] {
  opacity: 0;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload label {
  margin: 0;
  padding: 5px 0;
  font: 600 10px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload .upload-btn {
  margin-top: 5px;
  padding: 6px 19px;
  font: 600 11px "Poppins", sans-serif;
  border-radius: 5px;
  border: 1px solid #de5c0e;
  color: #fff;
  background: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .colm .get_field_value .view-on-upload .upload .upload-btn:hover {
  opacity: 0.9;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .action-box .change {
  color: #F6821F;
  font: 600 14px "Poppins", sans-serif;
  text-decoration: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .action-box .change:hover {
  color: #6d6e71;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section {
  width: 100%;
  float: left;
  border: 1px solid #dedede;
  padding: 24px;
  border-radius: 8px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .titlePlan {
  color: #8e8e93;
  font: 600 14px "Poppins", sans-serif;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  float: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view {
    flex-direction: column;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan {
  width: 50%;
  float: left;
}

@media (max-width: 900px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan {
    width: 100%;
    margin-bottom: 16px;
  }
}

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show {
//   width: 140px;
//   height: 140px;
//   float: left;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   color: #fff;
//   font: 600 18px "Poppins", sans-serif;
// }

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show.basic {
//   border-radius: 8px 8px 0 0;
//   background: linear-gradient(92deg, #2E8100 1.48%, #AFFF82 99.79%);
//   width: 100%;
//   height: 42px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show.deluxe {
//   background: linear-gradient(91deg, #005388 3.01%, #81C5FC 99.79%);

// }

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show.professional {
//   background: url(./assets/img/3.png) no-repeat center;
//   background-size: 150px;
// }

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show.ultimate {
//   background: url(./assets/img/4.jpg) no-repeat center;
//   background-size: 150px;
// }

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show.titanium {
//   background: url(./assets/img/5.jpg) no-repeat center;
//   background-size: 150px;
// }

// .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .plan_show.platinum {
//   background: url(./assets/img/6.jpg) no-repeat center;
//   background-size: 150px;
// }

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .upgrade {
  float: left;
  padding: 10px 0 0 30px;
  height: auto;
  width: 207px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .upgrade .price {
  color: #2e2e2e;
  font: 600 22px "Poppins", sans-serif;
  float: left;
  width: 100%;
  padding-bottom: 5px;
}

@media (max-width: 1366px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .upgrade .price {
    font-size: 16px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .upgrade .plan_date {
  font: 500 14px "Poppins", sans-serif;
  float: left;
  letter-spacing: -0.01px;
  color: #8e8e93;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .upgrade .upgrade_plan {
  display: block;
  float: left;
  margin-top: 20px;
  width: 100%;
  border-radius: 4px;
  padding: 14px 16px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font: 600 14px "Poppins", sans-serif;
  background: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Current_Plan .upgrade .upgrade_plan:hover {
  background: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Plan_valid {

  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 0.9fr;
  grid-template-columns: 1fr 0.9fr;
  grid-gap: 20px;
}

@media (max-width: 900px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Plan_valid {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Plan_valid .box-view span {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Plan_valid .box-view span.head {
  font: 500 14px "Poppins", sans-serif;
  letter-spacing: -0.01px;
  color: #8e8e93;
  padding-bottom: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Plan_valid .box-view span.value {
  color: #2e2e2e;
  font: 400 16px "Poppins", sans-serif;
}

@media (max-width: 1366px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .plan_section .plan_view .Plan_valid .box-view span.value {
    font-size: 16px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History {
  float: left;
  width: 100%;
  padding-bottom: 30px;

  @media (max-width: 480px) {
    overflow-x: auto;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #d0d0d0;
  text-align: left;
  margin-top: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr.Payment_head {
  margin-top: 0px;
  border: solid 0px;
  background: #efefef;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr.Payment_head th {
  padding: 15px;
  color: #8e8e93;
  font: 600 12px "Poppins", sans-serif;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr td {
  float: left;
  padding: 25px 15px;
  color: #a1a1a1;
  font: 600 12px "Poppins", sans-serif;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr td .Payment-Status {
  padding: 3px 28px;
  font: 600 10px "Poppins", sans-serif;
  text-align: center;
  border-radius: 18px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr td .Payment-Status.success {
  color: #228934;
  background-color: #dbffe1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr td .Payment-Status.failed {
  color: #ff3b30;
  background-color: #ffe7e6;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account.force_view .field_section .data_get_field .Payment_History table tr td a.dwnld_Invoice {
  background: url(./assets/img/download.jpg) center no-repeat;
  width: 40px;
  height: 20px;
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 5px;
  float: left;
  overflow: hidden;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section {
  float: left;
  width: 90%;
  margin-bottom: 24px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .title,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .title {
  padding:  0 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .title,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .title {
    padding: 10px 20px 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .title span,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .title span {
  color: #6f6f6f;
  font: 600 16px "Poppins", sans-serif;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .title span,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .title span {
    font: 600 16px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .title a,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .title a {
  text-decoration: none;
  font: 500 12px "Poppins", sans-serif;
  color: #F6821F;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .title a,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .title a {
    font: 500 12px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field {
  float: left;
  width: 100%;
  padding: 24px;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field {
    padding: 20px;
  }
}

@media (max-width: 600px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field {
    padding: 15px 15px 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm {
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: self-start;
    -ms-flex-align: self-start;
    align-items: self-start;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block {
  float: left;
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block.text_area,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block.text_area {
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block textarea,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block textarea,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block select {
  width: 96%;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block textarea,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value.block select,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block textarea,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value.block select {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value .range,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value .range {
  float: left;
  width: 48%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value .range,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value .range {
    width: 100%;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value .range select,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value .range select {
    width: 48%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea {
  height: 41px;
  margin-bottom: 20px;
  float: left;
  width: 48%;
  min-width: 175px;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #2e2e2e;
  padding: 0 10px;
  font: 400 14px "Poppins", sans-serif;
  &::placeholder {
    line-height: 40px !important;
  }
}

@media (max-width: 991px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea {
    font: 600 10px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-webkit-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea:-ms-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-ms-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

@media (max-width: 991px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::-webkit-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-webkit-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::-webkit-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-webkit-input-placeholder {
    font: 600 10px "Poppins", sans-serif;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input:-ms-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea:-ms-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input:-ms-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea:-ms-input-placeholder {
    font: 600 10px "Poppins", sans-serif;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::-ms-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-ms-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::-ms-input-placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-ms-input-placeholder {
    font: 600 10px "Poppins", sans-serif;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value input::placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value input::placeholder,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::placeholder {
    font: 600 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea {
  height: 163px;
  float: right;
  padding: 15px;
  resize: none;
  -webkit-box-shadow: inset 0 0.5px 2px 0 rgba(96, 97, 112, 0.29);
  box-shadow: inset 0 0.5px 2px 0 rgba(96, 97, 112, 0.29);
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-webkit-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea:-ms-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::-ms-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value textarea::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value textarea::placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
  line-height: normal !important;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value select {
  margin-bottom: 20px;
  height: 41px;
  padding: 0 10px;
  float: left;
  width: 48%;
  min-width: 175px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  background: url(./assets/img/expand_icon.svg)  right 5% center  no-repeat;
  background-size: 10px;
}

@media (max-width: 991px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value select,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value select {
    font: 600 10px "Poppins", sans-serif;
    min-width: 142px;
  }
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value select,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value select {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value select option,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value select option {
  padding: 5px 0;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 991px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value select option,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value select option {
    font: 600 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value label,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value label {
  margin: 5px 0 0;
  color: #6f6f6f;
  line-height: 22px;
  font: 14px "Poppins", sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value label,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value label {
    margin: 5px 0 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value label input[type="checkbox"],
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value label input[type="checkbox"] {
  border: 1px solid #F6821F;
  min-width: auto;
  border-radius: 5px;
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px 10px 0 0;
  cursor: pointer;
  padding: 0;
  background: transparent;
  position: relative;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .data_get_field .colm .get_field_value label input[type="checkbox"]:checked,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .data_get_field .colm .get_field_value label input[type="checkbox"]:checked {
  border: 1px solid #ce5a16;
  background: url(./assets/img/checkbox.jpg) #F6821F no-repeat;
  background-size: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box {
  width: 100%;
  max-width: 1000px;
  padding:  0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  flex-direction: row;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box {
    padding: 0 0px 0 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button {
  z-index: 99999;
  border-radius: 5px;
  padding: 14px 19px;
  width: 160px;
  overflow: hidden;
  position: relative;
  border: 1px solid #F6831F;
  outline: none;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button {
    padding: 10px 19px;
    width: 120px;
    font: 600 11px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button.reset{
  border: 1px solid #a1a1a1;
  background-color: transparent;
  color: #a1a1a1;
  margin-right: 24px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button.reset {
  margin-right: 15px;
  background: #fff;
  border:1px solid #F6831F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button.reset:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button.reset:hover {
  color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button.search,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button.search {
  background: #F6831F;
  color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button.search:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button.search:hover {
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button.search::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button.search::after {
  background: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button::after {
  width: 0px;
  height: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .job_field .field_section .action-box button:hover::after,
.horizon .dashboard_home .Reading_View .Reading_View_Container .My_Account .field_section .action-box button:hover::after {
  width: 170px;
  height: 150px;
  z-index: -99;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 5px;
  float: left;
  padding: 27px 30px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer {
    padding: 18px 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 0 18px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title {
    flex-direction: column;
    align-items: center;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title select {
  margin-bottom: 0px;
  height: 41px;
  padding: 0 10px;
  float: left;
  width: 100%;
  max-width: 370px;
  min-width: 175px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  background: url(./assets/img/expand_icon.svg) right 5% center no-repeat;
  background-size: 12px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title select {
    margin-bottom: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title select option {
  padding: 8px 0;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title span {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title span a {
  color: #6f6f6f;
  text-decoration: none;
  font: 400 14px "Poppins", sans-serif;
  float: left;
  outline: none;
  background: url(./assets/img/backIcon.svg) no-repeat left center;
  background-size: 8px;
  padding-left: 16px;
  padding-bottom: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title a {
  font: 600 12px "Poppins", sans-serif;
  color: #a1a1a1;
  float: right;
  padding-bottom: 12px;
  cursor: pointer;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .title p.search-count {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section {
  width: 100%;
  height: auto;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view {
  padding: 16px;
  width: 100%;
  height: auto;
  float: left;
border: 1px solid #dedede;
  border-radius: 4px;
  margin-bottom: 12px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view {
    padding: 18px 18px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm {
  width: 100%;
  height: auto;
  // height: 130px;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm {
    display: block;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_ptoho {
  // width: 130px;
  width: 70px;
  // min-width: 130px;
  min-width: 70px;
  float: left;
  overflow: hidden;
  border-radius: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_ptoho img {
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details {
  width: 100%;
  float: left;
  padding: 0 25px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details {
    padding: 0 0 0 25px;
    width: calc(100% - 130px);
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details {
    padding: 0 0 0 15px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details {
    width: 100%;
    padding: 15px 0 0;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view .box-view {

  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view .box-view .name {
  font: 600 16px "Poppins", sans-serif;
  color: #2e2e2e;
  float: left;
  width: 100%;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view .box-view .name {
    font: 600 15px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view .box-view .position {
  font: 400 14px "Poppins", sans-serif;
  color: #2e2e2e;
  float: left;
  width: 100%;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view .box-view .position {
    font: 600 12px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .name-card-view .box-view p {
  color: #a1a1a1;
  text-align: right;
  font: 500 12px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .details-card-view {
  width: 100%;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -webkit-column-gap: 15px;
  column-gap: 15px;
  grid-gap: 15px;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .details-card-view {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .details-card-view .block {
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .details-card-view .block .ttl {
  font: 400 12px "Poppins", sans-serif;
  color: #6f6f6f;
  margin-bottom: 5px;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .details-card-view .block .value {
  font: 400 14px "Poppins", sans-serif;
  color: #2e2e2e;
  width: 100%;
  float: left;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .employe_details .details-card-view .block .value {
    font: 600 12px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech {
  width: 133px;
  float: left;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 0 0;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech a {
  display: block;
  background: transparent;
  width: 133px;
  color: #F6821F;
  padding: 8px ;
  border: 1px solid #F6821F;
  font: 400 14px "Poppins", sans-serif;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 8px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech a {
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech a {
    margin-bottom: 10px;
    float: left;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech a {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech a:hover {
  text-decoration: underline;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .shortlist_viewer .shortlist_section .employee_view .colm .action_tech a.Shortlist {
  background: #F6821F;
  color: #fff;
  cursor: pointer;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox {
  width: 100%;
  height: 79vh;
  background: #fff;
  border-radius: 5px;
  float: left;
  padding: 18px;
  overflow: hidden;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox {
    padding: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox .chat_View {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 0;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside {
  width: 300px;
  border-radius: 8px;
  height: auto;
  background-color: #f3f3f3;
  display: inline-block;
  font-size: 15px;
  vertical-align: top;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside {
    width: 100%;
    height: 79vh;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main {
  width: calc(100% - 320px);
  float: right;
  height: 100%;
  border-radius: 8px;
  padding: 10px;
  display: inline-block;
  margin-left: 0px;
  font-size: 15px;
  vertical-align: top;
  border: 1px solid #9e9e9e2e;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox .chat_View {
    position: relative;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main {
    margin-left: 0px;
    width: 100%;
    display: none !important;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main.active {
    display: block !important;
    position: absolute;
    top: 0;
    background: #fff;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside .search_person {
  padding: 15px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside input {
  width: 100%;
  height: 30px;
  line-height: 50px;
  padding: 0 10px 0;
  border-radius: 5px;
  color: #2e2e2e;
  font: 400 12px "Poppins", sans-serif;
  background: url(./assets/img/search.jpg) #fff right;
  background-repeat: no-repeat;
  background-size: 30px;
  border: solid 1px #dbdbdb;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside input::-webkit-input-placeholder {
  color: #c7c7cc;
  font: 300 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside input:-ms-input-placeholder {
  color: #c7c7cc;
  font: 300 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside input::-ms-input-placeholder {
  color: #c7c7cc;
  font: 300 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside input::placeholder {
  color: #c7c7cc;
  font: 300 10px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside ul {
  padding-left: 0;
  width: 100%;
  margin: 0;
  list-style-type: none;
  max-height: 700px;
  float: left;
  overflow-y: scroll;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside ul::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li {
  padding: 15px 0;
  border-bottom: 1px solid #9e9e9e42;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li:last-child {
  border-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li:hover {
  cursor: pointer;
  background-color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li:hover .name {
  color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li:hover .nofiy {
  color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li:hover .nofiy.active {
  color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li .photo {
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 11px;
  max-width: 43px;
  overflow: hidden;
  -webkit-box-shadow: -2px 2px 6px 1px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 6px 1px rgba(0, 0, 0, 0.1);
  border: 2px solid #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li .photo img {
  max-width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li .name {
  font: 400 14px "Poppins", sans-serif;
  color: #2e2e2e;
  margin-bottom: 5px;
  margin-top: 4px;
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li .nofiy {
  font-size: 12px;
  color: #7e818a;
  margin-top: 1px;
  font-weight: normal;
  float: left;
  font: 500 9px "Poppins", sans-serif;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox aside li .nofiy.active {
  color: #00a91d;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area > h5{
  font-size: 14px;
  color: #a1a1a1;
}
.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .go_back {
  display: none;
  width: 22px;
  height: 36px;
  background: url(./assets/img/icon/left-arrow.svg) no-repeat left;
  background-size: 16px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .go_back {
    display: block;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person {
  height: auto;
  padding: 23px 20px 30px;
  border-bottom: solid 1px #dbdbdb70;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
}

@media (max-width: 1024px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person {
    padding: 5px 10px 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .photo {
  width: 50px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .photo img {
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .person_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 25px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .person_details span {
  float: left;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #2e2e2e;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .person_details span.name {
  padding-bottom: 6px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .person_details span.name p {
  color: #009688;
  float: left;
  padding-right: 4px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .person_details span.name span {
  color: #2e2e2e;
  float: left;
  width: auto;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_area .person .person_details span.nofiy {
  font: 500 10px "Poppins", sans-serif;
  color: #6d6e71bf;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details {
  height: calc(100% - 105px);
  overflow: hidden;
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 2560px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details {
    height: calc(100% - 72px);
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat {
  padding-left: 0;
  margin: 0;
  height: 100%;
  float: left;
  width: 100%;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 10px 0;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat {
    padding: 0 10px 0 0;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e2e2e2;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li {
  font: 400 12px "Poppins", sans-serif;
  color: #2e2e2e;
  padding: 17px 26px;
  margin-top: 20px;
  border-radius: 5px;
  max-width: 400px;
  clear: both;
  line-height: 19px;
  position: relative;

  .txtContent {
    overflow-wrap: break-word;

  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li:first-child {
    margin-top: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li::first-letter {
  text-transform: uppercase;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li.client {
  float: left;
  background: #f3f3f3;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li.client::after {
  right: -20px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li.admin {
  float: right;
  background: #fff9eb;
  padding-right: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li.admin::after {
  left: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .chat_details .chat li span.time {
  position: absolute;
  left: 0;
  bottom: -15px;
  font: 400 8px "Poppins", sans-serif;
  color: #ccc;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area {
  width: 100%;
  float: left;
  padding: 0px;
  background-color: #f5f5f5;
  position: relative;
  height: 58px;
  border-radius: 4px;
  // overflow: hidden;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .chat_input {
  border: none;
  float: left;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #f5f5f5;
  padding: 10px;
  height: 46px;
  font: 300 13px "Poppins", sans-serif;
  grid-area: 1 / 1 / 2 / 10;
  overflow: auto;
  resize: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .chat_input::-webkit-input-placeholder {
  color: #c7c7cc;
  font: 300 11px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .chat_input:-ms-input-placeholder {
  color: #c7c7cc;
  font: 300 11px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .chat_input::-ms-input-placeholder {
  color: #c7c7cc;
  font: 300 11px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .chat_input::placeholder {
  color: #c7c7cc;
  font: 300 11px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .post {
  grid-area: 1 / 11 / 2 / 12;
  right: 0;
  z-index: 0;
  top: 0;
  width: 113px;
  position: absolute;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .post a.send_chat {
  text-decoration: none;
  margin-left: 0px;
  height: 63px;
  overflow: hidden;
  height: 46px;
  overflow: hidden;
  width: 63px;
  margin-top: 0px;
  display: block;
  background: url(./assets/img/icon/icons-8-sent.png) #9d9d9d no-repeat center;
  background-size: 20px;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .post input {
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 46px;
  width: 50px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Mailbox main .action_area .post input::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 46px;
  cursor: pointer;
  z-index: 99;
  width: 50px;
  background: url(./assets/img/icon/icons-8-attach.png) #f5f5f5 no-repeat center;
  background-size: 30%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view {
  width: 100%;
  height: auto;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications {
  border-radius: 5px;
  padding: 28px 28px;
  background: #fff;
  width: 100%;
  height: auto;
  float: left;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications {
    padding: 20px 20px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications {
    padding: 20px 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise {
  width: 100%;
  height: auto;
  float: left;
  margin-bottom: 20px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView {
  width: 100%;
  height: auto;
  float: left;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView {
    padding: 0 0 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col {
  width: 50%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span {
  display: block;
  font: 600 13px "Poppins", sans-serif;
  color: #F6821F;
  display: block;
  float: right;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span {
    font: 600 11px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.date {
  padding: 7px 30px;
  background: #fcf6ea;
  border-radius: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear {
  padding: 7px 10px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  z-index: 99;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear:hover a {
  color: #fff;
  position: relative;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .headView .col span.clear a {
  text-decoration: none;
  color: #F6821F;
  z-index: 99;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list {
  width: 100%;
  height: auto;
  float: left;
  padding: 0;
  margin: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li {
  list-style: none;
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li {
    margin-bottom: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .photo {
  width: 40px;
  height: 40px;
  float: left;
  overflow: hidden;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .photo {
    height: 35px;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .photo {
    height: 33px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message {
  width: 100%;
  float: left;
  margin-left: 0;
  border-bottom: 1px solid #e6e6e6;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message {
    margin-left: 13px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message span {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message span.name {
  font: 600 16px "Poppins", sans-serif;
  color: #2e2e2e;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message span.name {
    font: 600 14px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message span.point_view {
  font: 500 14px "Poppins", sans-serif;
  margin-top: 10px;
  color: #8e8e93;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view .allNotifications .day_wise .Notify_list li .message span.point_view {
    font: 500 13px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications {
  width: 100%;
  height: auto;
  display: -ms-grid;
  display: grid;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs {
  border-radius: 5px;
  padding: 28px 28px;
  background: #fff;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs {
    padding: 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 0 18px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .title span {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .title a {
  text-decoration: none;
  font: 500 12px "Poppins", sans-serif;
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view {
  width: 100%;
  height: auto;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier {
  float: left;
  width: 100%;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 5px;
  border: 1px solid #9797975c;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view {
  float: left;
  max-width: 100px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view {
    margin: 4px 0 5px;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view {
    max-width: 70px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view img {
  max-width: 77px;
  width: 100%;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view img {
    border: 2px solid #ed6c20;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details {
    margin-top: 11px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details {
  max-width: 250px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate {
  padding: 0 23px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate {
    padding: 0 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .name {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .position {
  color: #afafaf;
  font: 400 11px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 4px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .timeLevel {
  color: #afafaf;
  font: 400 11px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 4px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status {
  margin-bottom: 7px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status span {
  font: 500 9px "Poppins", sans-serif;
  padding: 4px 7px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status span {
    float: right;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status.Submitted span {
  color: #be7209;
  background-color: #fff6d0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status.Shortlisted span {
  color: #228934;
  background-color: #dbffe1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status.Rejected span {
  color: #d50b00;
  background-color: #ffedec;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action {
  float: left;
  max-width: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action {
    max-width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a {
  font: 500 11px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
  border: 1px solid #F6821F;
  padding: 5px 10px;
  color: #F6821F;
  border-radius: 5px;
  margin-top: 13px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
    margin-top: 5px;
    font: 500 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.share {
  color: #2e2e2e;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area {
  width: 100%;
  height: auto;
  float: left;
  border-radius: 10px;
  background-color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view {
  width: 100%;
  height: auto;
  float: left;
  border-bottom: solid 1px #ebebeb;
  padding-left: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view {
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 5px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view {
    padding-top: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul {
  padding: 0;
  margin: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li {
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a {
  color: #F6821F;
  position: relative;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a::after {
  position: absolute;
  height: 2px;
  left: 0;
  content: "";
  bottom: 0;
  background-color: #F6821F;
  width: 100%;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a::after {
    display: none;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a:hover {
  opacity: 0.9;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a {
  padding: 20px 0px 12px 0;
  margin-right: 40px;
  display: block;
  text-decoration: none;
  color: #8e8e93;
  text-align: left;
  font: 500 14px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a {
    padding: 10px 0px 12px 0;
    font: 500 12px "Poppins", sans-serif;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a {
    padding: 5px 0px 12px 0;
    margin-right: 18px;
    font: 500 10px "Poppins", sans-serif;
    color: #2e2e2e;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a:hover {
  opacity: 0.8;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form {
  float: left;
  width: 100%;
  height: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section {
  float: left;
  width: 100%;
  height: auto;
  display: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.active {
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw {
  background: #f3f3f3;
  padding: 5px 15px 0;
  margin-bottom: 10px;
  border-radius: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw.add_edu {
  background: transparent;
  padding: 5px 0px 0;
  margin-bottom: 0px;
  border-radius: 0px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp {
    margin-bottom: 17px;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp {
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp a.edit {
    margin-bottom: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp:last-child {
  border-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 30px 10px;
  width: 100%;
  float: left;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section {
    padding: 0px 20px 10px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section {
    margin-bottom: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section span {
  color: #2e2e2e;
  font: 600 18px "Poppins", sans-serif;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section span {
    font: 600 16px "Poppins", sans-serif;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section span {
    font: 600 14px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section a {
  font: 500 14px "Poppins", sans-serif;
  color: #F6821F;
  background-size: 20px;
  text-align: right;
  padding-left: 30px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section a {
    font: 500 10px "Poppins", sans-serif;
    background-size: 18px;
    height: 18px;
    padding-left: 22px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .Projects_View {
  width: 100%;
  float: left;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .Projects_View {
    padding: 11px 14px 0;
    background: #f3f3f3;
    border-radius: 5px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view {
  float: left;
  width: 100%;
  padding: 10px 30px 20px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view {
    padding: 10px 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw {
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 30px;
  column-gap: 30px;
  -ms-grid-columns: 1fr 1fr 0.7fr;
  grid-template-columns: 1fr 1fr 0.7fr;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu {
  border-top: 1px solid #ebebeb;
  display: block;
  width: 100%;
  float: left;
  padding: 10px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
  float: left;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 0.5fr 0.6fr;
  grid-template-columns: 0.5fr 0.5fr 0.6fr;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
    -ms-grid-columns: 0.5fr 0.7fr;
    grid-template-columns: 0.5fr 0.7fr;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .add,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .add,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .add {
  display: block;
  float: left;
  border-radius: 4px;
  color: #fff;
  background: #F6821F;
  font: 600 16px "Poppins", sans-serif;
  padding: 16px 24px;
  margin-bottom: 16px;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .add,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .add,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .add {
    max-width: 100%;
    padding: 11px 5px;
    font: 600 12px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .from_grp,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .from_grp,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .from_grp {
  margin-top: 8px;
  margin-bottom: 18px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .from_grp label,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .from_grp label,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .from_grp label {
  color: #2e2e2e;
  font: 500 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .from_grp .edit_field select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .from_grp .edit_field select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .from_grp .edit_field select {
  font: 500 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Year select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Year select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Year select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .order_priority select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .order_priority select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .order_priority select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Year,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Major,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Year,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Major,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Year,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Major {
  margin-top: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .or,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .or,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .or {
  width: inherit;
  max-width: 32px;
  text-align: center;
  float: initial;
  color: #2e2e2e;
  font: 600 12px "Poppins", sans-serif;
  padding: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Grade,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Grade,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Grade {
  margin-top: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Grade .edit_field input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Grade .edit_field input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Grade .edit_field input {
  max-width: 100px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .forword,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .forword,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .forword {
  display: block;
  float: left;
  border-radius: 3px;
  margin-top: 10px;
  border: 1px solid #F6821F;
  text-transform: capitalize;
  background: #F6821F;
  color: #fff;
  font: 500 14px "Poppins", sans-serif;
  padding: 9px 40px;
  margin-bottom: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .forword:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .forword:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .forword:hover {
  background: #fff;
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.fullView {
  display: block;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.fullView .from_grp label {
  color: #2e2e2e;
  font: 600 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration {
  width: 100%;
  float: left;
  padding: 0 0 30px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration {
    padding: 0 0 12px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration label {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration p {
  font: 500 14px "Poppins", sans-serif;
  float: left;
  color: #2e2e2e;
  width: 100%;
  padding: 10px 0 0;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration p {
    font: 500 12px/19px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp {
  float: left;
  margin-bottom: 30px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp {
    margin-bottom: 10px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
    margin-top: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp.deactivate {
  opacity: 0.5;
  pointer-events: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp label {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span {
  font: 600 16px "Poppins", sans-serif;
  color: #2e2e2e;
  float: left;
  width: 100%;
  padding-top: 10px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span {
    font: 600 13px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp .clint {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp .clint .Project_location {
  font: 500 14px "Poppins", sans-serif;
  float: left;
  width: auto;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp .clint p {
  font: 500 14px "Poppins", sans-serif;
  float: left;
  text-transform: capitalize;
  color: #2e2e2e;
  width: auto;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp a.edit {
  display: block;
  float: left;
  border-radius: 3px;
  margin-top: 10px;
  color: #F6821F;
  font: 500 11px "Poppins", sans-serif;

}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp a.edit:hover {
  text-decoration: underline;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select {
  max-width: 220px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  font: 500 15px "Poppins", sans-serif;
  color: #2e2e2e;
  border: 1px solid #ccc;
  padding: 0 10px;
}

@media (max-width: 600px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select {
    max-width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::-webkit-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select:-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets/img/icon/icons8-expand_arrow.png) right center no-repeat;
  background-size: 30px;
  appearance: none;
  color: #2e2e2e;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp p {
  font: 500 13px "Poppins", sans-serif;
  color: #6d6e71;
  float: left;
  width: 100%;
  line-height: 21px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info {
  width: 100%;
  float: left;
  padding-top: 8px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 0 0 0 20px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info {
    padding: 0 0 0 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .company-Icon {
  width: 90px;
  float: left;
  background: url(./assets/img/icon/group-4.png) no-repeat top left;
  background-size: 40px;
  height: 60px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .company-Icon {
    width: 50px;
    background-size: 30px;
    height: 45px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .company-Icon {
    display: none;
    width: 40px;
    background-size: 20px;
    height: 36px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info {
  width: 100%;
  float: left;
  padding-bottom: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #e6e9ec;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info .companyDetails_view {
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span {
  color: #303030a6;
  width: 100%;
  clear: left;
  float: left;
  padding-bottom: 8px;
  text-transform: capitalize;
  font: 400 13px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span span {
  width: auto;
  float: left;
  text-transform: capitalize;
  padding-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span p {
  float: left;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.Employed p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.Duration p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.job_city p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.job_state p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.job_country p {
  color: #303030e0;
  padding-left: 3px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.positiont-visually {
  font: 500 17px "Poppins", sans-serif;
  color: #2e2e2e;
  padding-bottom: 8px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.company-visually {
  font: 600 13px "Poppins", sans-serif;
  color: #2e2e2e;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.description p {
  color: #303030e0;
  width: 100%;
  text-align: left;
  float: left;
  display: block;
  font: 500 13px/23px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info .action {
  width: 100%;
  float: left;
  padding: 10px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info .action a {
  color: #F6821F;
  text-transform: capitalize;
  margin-right: 10px;
  float: left;
  display: block;
  font: 500 14px "Poppins", sans-serif;
  text-decoration: underline;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp {
  float: left;
  margin-bottom: 30px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp {
    margin-top: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp label {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
  border: 1px solid #ebebeb;
  margin-top: 20px;
  border-radius: 5px;
  width: 220px;
  height: 100px;
  cursor: pointer;
  float: left;
  background: url(./assets/img/uploader.jpg) no-repeat center;
  background-size: 98px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
    width: 220px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
    margin-top: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  opacity: 0;
  float: left;
  height: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
  // width: 220px;
  height: auto;
  float: left;
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    width: 220px;
    clear: left;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    padding-top: 6px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions p {
  font: 500 13px "Poppins", sans-serif;
  color: #6d6e71;
  float: left;
  line-height: 21px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions a {
  font: 500 13px "Poppins", sans-serif;
  color: #F6821F;
  text-decoration: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv button {
  color: #F6821F;
  text-transform: capitalize;
  margin-right: 10px;
  width: 125px;
  float: left;
  border-radius: 3px;
  margin: 0px 0;
  padding: 10px 0px;
  display: block;
  border: 1px solid #F6821F;
  text-decoration: none;
  font: 600 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 7px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.3fr 0.3fr 0.5fr;
  grid-template-columns: 0.3fr 0.3fr 0.5fr;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill {
    -ms-grid-columns: 0.53fr 0.5fr 0.3fr;
    grid-template-columns: 0.53fr 0.5fr 0.3fr;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp {
  float: left;
  margin-bottom: 20px;
  margin-top: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp.order_priority select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp button {
  color: #F6821F;
  text-transform: capitalize;
  width: 125px;
  float: left;
  border-radius: 3px;
  margin: 0px 0;
  padding: 10px 0px;
  display: block;
  border: 1px solid #F6821F;
  text-decoration: none;
  font: 600 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp button {
    padding: 7px 0px;
    font: 600 12px "Poppins", sans-serif;
    width: 100px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp label {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span {
  margin-top: 20px;
  border-radius: 5px;
  padding-right: 5px;
  width: 100%;
  cursor: pointer;
  float: left;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span {
    margin-top: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select {
  max-width: 220px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  font: 500 15px "Poppins", sans-serif;
  color: #2e2e2e;
  border: 1px solid #ccc;
  padding: 0 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::-webkit-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select:-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::placeholder {
  font: 400 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select {
    max-width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets/img/icon/icons8-expand_arrow.png) right center no-repeat;
  background-size: 30px;
  appearance: none;
  color: #2e2e2e;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing {
  width: 100%;
  float: left;
  padding: 30px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  clear: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp:last-child {
  border-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp label {
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  width: 100%;
  max-width: 220px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp button {
  font: 400 11px "Poppins", sans-serif;
  float: left;
  background: #fff;
  color: #F6821F;
  border-radius: 2px;
  margin: 0px 0;
  padding: 3px 8px;
  display: block;
  border: 1px solid #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 7px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.3fr 0.3fr 0.5fr;
  grid-template-columns: 0.3fr 0.3fr 0.5fr;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp {
  float: left;
  margin-bottom: 20px;
  margin-top: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp.order_priority select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span {
  margin-top: 20px;
  border-radius: 5px;
  padding-right: 5px;
  width: 100%;
  float: left;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span {
    margin-top: 10px;
    padding-right: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select {
  max-width: 220px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  font: 500 15px "Poppins", sans-serif;
  color: #2e2e2e;
  border: 1px solid #ccc;
  padding: 0 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::-webkit-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select:-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::placeholder {
  font: 400 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select {
    max-width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets/img/icon/icons8-expand_arrow.png) right center no-repeat;
  background-size: 30px;
  appearance: none;
  color: #2e2e2e;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span.upload {
  border: 1px solid #ebebeb;
  margin-top: 0px;
  border-radius: 5px;
  width: 200px;
  cursor: pointer;
  height: 88px;
  float: left;
  background: url(./assets/img/uploader.jpg) no-repeat center;
  background-size: 98px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span.upload input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
  opacity: 0;
  float: left;
  height: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp button {
  color: #F6821F;
  text-transform: capitalize;
  width: 125px;
  float: left;
  border-radius: 3px;
  margin: 0px 0;
  padding: 10px 0px;
  display: block;
  border: 1px solid #F6821F;
  text-decoration: none;
  font: 600 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp button {
    padding: 7px 0px;
    font: 600 12px "Poppins", sans-serif;
    width: 100px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp label {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing {
  width: 100%;
  float: left;
  padding: 30px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp {
  width: 300px;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  clear: left;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp {
    max-width: 100%;
    width: 300px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp label {
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  width: 100%;
  text-transform: capitalize;
  max-width: 150px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp button {
  font: 400 11px "Poppins", sans-serif;
  float: left;
  background: #fff;
  color: #F6821F;
  border-radius: 2px;
  margin: 0px 0;
  padding: 3px 8px;
  display: block;
  border: 1px solid #F6821F;
}

.horizon .dashboard_home .Profile_view_right {
  width: 100%;
  max-width: 300px;
  height: 100%;
  min-height: 100vh;
  background: #fff;
  position: relative;
}

@media (max-width: 1440px) {
  .horizon .dashboard_home .Profile_view_right {
    max-width: 260px;
  }
}

@media (max-width: 1366px) {
  .horizon .dashboard_home .Profile_view_right {
    max-width: 222px;
  }
}

@media (max-width: 1280px) {
  .horizon .dashboard_home .Profile_view_right {
    max-width: 230px;
  }
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Profile_view_right {
    max-width: 0px;
  }

  .horizon .dashboard_home .Profile_view_right::after {
    content: "";
    position: absolute;
    left: -50px;
    cursor: pointer;
    z-index: 9;
    top: 217px;
    height: 44px;
    width: 50px;
    background: url(assets/img/icon/icons-8-male-user-off.png) no-repeat center #ccc;
    background-size: 20px;
    border-radius: 20px 0 0 20px;
  }
}

@media (max-width: 1189px) and (max-width: 768px) {
  .horizon .dashboard_home .Profile_view_right::after {
    display: none;
  }
}

@media (max-width: 1189px) and (max-width: 1024px) {
  .horizon .dashboard_home .Profile_view_right::after {
    top: 188px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Profile_view_right.active .short_Profile_view {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

.horizon .dashboard_home .Profile_view_right:hover .short_Profile_view {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
}

.horizon .dashboard_home .Profile_view_right .profile_burger {
  position: absolute;
  left: -49px;
  cursor: pointer;
  z-index: 9;
  top: 13px;
  height: 30px;
  width: 30px;
  background: url(./assets/img/profile.svg) no-repeat center;
  background-size: 30px;
  border-radius: 20px 0 0 20px;
  display: none;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Profile_view_right .profile_burger {
    display: block;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Profile_view_right .profile_burger {
    top: 10px;
  }
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view {
  float: left;
  width: 100%;
  max-width: 300px;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  z-index: 99;
  overflow-y: auto;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #fff;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view::-webkit-scrollbar {
  width: 0px;
  background-color: #fff;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

@media (max-width: 1440px) {
  .horizon .dashboard_home .Profile_view_right .short_Profile_view {
    max-width: 260px;
  }
}

@media (max-width: 1366px) {
  .horizon .dashboard_home .Profile_view_right .short_Profile_view {
    max-width: 222px;
  }
}

@media (max-width: 1280px) {
  .horizon .dashboard_home .Profile_view_right .short_Profile_view {
    max-width: 230px;
  }
}

@media (max-width: 1189px) {
  .horizon .dashboard_home .Profile_view_right .short_Profile_view {
    -webkit-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -ms-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -o-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    max-width: 230px;
    width: 100%;
    background: #fff;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: -webkit-transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: -webkit-transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      -webkit-transform 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view {
  width: 100%;
  height: auto;
  float: left;
  padding: 28px 10px;
  // background: url(./assets/img/How-it-works.png);
  background-repeat: repeat;
  background-size: 5%;
  border-bottom: 1px solid #dedede;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img {
  text-align: center;
  display: block;
  overflow: hidden;
  margin: auto;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img .Profile_pic {
  position: relative;
  margin: auto;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img .Profile_pic img {
  border: 4px solid #4caf50;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status {
  position: absolute;
  font: 500 8px "Poppins", sans-serif;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  bottom: 0;
  height: 40px;
  text-transform: capitalize;
  width: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status.basic {
  background: url(./assets/img/1.png) no-repeat center;
  background-size: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status.deluxe {
  background: url(./assets/img/2.png) no-repeat center;
  background-size: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status.professional {
  background: url(./assets/img/3.png) no-repeat center;
  background-size: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status.ultimate {
  background: url(./assets/img/4.jpg) no-repeat center;
  background-size: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status.titanium {
  background: url(./assets/img/5.jpg) no-repeat center;
  background-size: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_img span.status.platinum {
  background: url(./assets/img/6.jpg) no-repeat center;
  background-size: 40px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  float: left;
  padding: 5px 0 8px;
  font: 500 13px "Poppins", sans-serif;
  text-align: center;
  width: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_status::before {
  width: 16px;
  height: 15px;
  float: left;
  margin-right: 3px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_status.completed {
  color: #00B227;
  font-size: 14px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_status.completed::before {
  background: url(./assets/img/icon/icons-8-checked.png) no-repeat;
  background-size: 14px;
  content: "";
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_status.in_completed {
  color: #F6821F;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_status.in_completed::before {
  background: url(./assets/img/checkbox.jpg) no-repeat;
  background-size: 14px;
  content: "";
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_name {
  float: left;
  color: #2e2e2e;
  width: 100%;
  text-align: center;
  font: 600 16px "Poppins", sans-serif;
  padding: 0px 0 5px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .Profile_view .Profile_designation {
  float: left;
  width: 100%;
  text-align: center;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view {
  float: left;
  width: 100%;
  padding: 32px 24px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .title {
  float: left;
  width: 100%;
  padding: 5px 0 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .title span {
  font: 600 14px "Poppins", sans-serif;
  color: #2e2e2e;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .title .icon.active {
  position: relative;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .title .icon.active::after {
  content: "";
  width: 5px;
  height: 5px;
  right: -3px;
  position: absolute;
  z-index: 999;
  top: 3px;
  background-color: #F6821F;
  border-radius: 20px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .title .icon img {
  width: 15px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body {
  float: left;
  width: 100%;
  margin-top: 8px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #cccccc7a;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body .chat_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body .chat_block .photo {
  width: 37px;
  float: left;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body .chat_block .photo img {
  border-radius: 20px;
  width: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body .chat_block .detail {
  width: 100%;
  float: left;
  padding-left: 10px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body .chat_block .detail span {
  font: 600 12px "Poppins", sans-serif;
  color: #444444e0;
  width: 100%;
  padding-bottom: 2px;
  padding-top: 1px;
  float: left;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .chat_View .body .chat_block .detail p {
  font: 400 11px "Poppins", sans-serif;
  color: #afafaf;
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .title {
  float: left;
  width: 100%;
  padding: 5px 0 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .title span {
  font: 600 16px "Poppins", sans-serif;
  color: #2e2e2e;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .title .icon.active {
  position: relative;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .title .icon.active::after {
  content: "";
  width: 5px;
  height: 5px;
  right: -3px;
  position: absolute;
  z-index: 999;
  top: 3px;
  background-color: #F6821F;
  border-radius: 20px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .title .icon img {
  width: 15px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body {
  float: left;
  width: 100%;
  margin-top: 8px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .icon {
  width: 32px;
  float: left;
  height: 32px;
  background-size: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .icon.aletr {
  background: url(./assets/img/aletr.jpg) no-repeat;
  background-size: 100%;
  margin-right: 8px;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .icon.new {
  background: url(./assets/img/new.jpg) no-repeat;
  background-size: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .icon.reject {
  background: url(./assets/img/reject.jpg) no-repeat;
  background-size: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .detail {
  width: 100%;
  float: left;
  outline: none;
  text-decoration: none;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .detail:hover p {
  color: #8e8e8e;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .detail span {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
  width: 100%;
  padding:0 0  8px 0;
  float: left;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .detail span::first-letter {
  text-transform: uppercase;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .detail p {
  font: 400 14px "Poppins", sans-serif;
  color: #a1a1a1;
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Profile_view_right .short_Profile_view .info_view .Notifications .body .Notifi_block .detail p::first-letter {
  text-transform: uppercase;
}


@media (max-width: 991px) {
  .candidate_in {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}



@media (max-width: 991px) {
  .candidate_in .view_horizon {
    padding: 0px 0px 80px;
    height: auto;
    background-size: 47%;
  }
}

.candidate_in .view_horizon .view {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  max-width: 450px;
  padding: 80px 0 10px;
}

@media (max-width: 991px) {
  .candidate_in .view_horizon .view {
    padding: 20px 0 5px;
    max-width: 270px;
  }
}

.candidate_in .view_horizon .view .logo {
  max-width: 200px;
  display: block;
  margin: auto;
  outline: none;
}

.candidate_in .view_horizon .view .logo img {
  max-width: 200px;
}

@media (max-width: 991px) {
  .candidate_in .view_horizon .view .logo img {
    max-width: 170px;
  }
}

.candidate_in .view_horizon .view .title {
  font: 500 25.5px "Poppins", sans-serif;
  color: #545454;
  text-align: center;
  padding: 50px 0 0;
}

@media (max-width: 991px) {
  .candidate_in .view_horizon .view .title {
    font: 500 14.5px "Poppins", sans-serif;
    padding: 10px 0 0px;
  }
}

.candidate_in .view_horizon .view p {
  color: #F6821F;
  padding: 20px 0 0;
  text-align: center;
  font: 14px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .candidate_in .view_horizon .view p {
    font: 500 12px "Poppins", sans-serif;
    padding: 8px 0 0;
  }
}

.candidate_in .view_horizon .view_horizon_ligth {
  float: left;
  width: 100%;
  height: auto;
  padding: 80px 0 20px;
  max-width: 450px;
}

@media (max-width: 991px) {
  .candidate_in .view_horizon .view_horizon_ligth {
    display: none;
  }
}

.candidate_in .view_horizon .view_horizon_ligth label {
  font: 500 12px "Poppins", sans-serif;
  color: #ccc;
  margin-top: 30px;
}

.candidate_in .view_horizon .view_horizon_ligth ul {
  margin: 0;
  float: left;
  padding: 10px 0;
  width: 100%;
}

.candidate_in .view_horizon .view_horizon_ligth ul li {
  list-style: none;
  color: #2e2e2e;
  width: 100%;
  font: 500 14px "Poppins", sans-serif;
  padding: 10px 0;
}

.candidate_in .create_horizon {
  background: #fff;
  float: left;
  width: 100%;
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 991px) {
  .candidate_in .create_horizon {
    padding: 0px 14px 20px;
    height: auto;
  }
}

.candidate_in .create_horizon .view {

  max-width: 350px;
 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

@media (max-width: 991px) {
  .candidate_in .create_horizon .view {
    padding: 20px 14px 10px;
  }
}

.candidate_in .create_horizon .view form {
  float: left;
  width: 100%;
}

.candidate_in .create_horizon .view form a.toback {
  font: 500 14px "Poppins", sans-serif;
  color: #F6821F;
}

@media (max-width: 991px) {
  .candidate_in .create_horizon .view form a.toback {
    font: 500 12px "Poppins", sans-serif;
  }
}

.candidate_in .create_horizon .view form a.toback.black {
  color: #6f6f6f;
}

.candidate_in .create_horizon .view form a.toback img {
  margin-right: 3px;
}


.candidate_in .create_horizon .view form h5.black {
  color: #2e2e2e;
  padding: 48px 0;
}

.candidate_in .create_horizon .view form input {
  width: 100%;
  height: 55px;
  float: left;
  border-radius: 3px;
  padding: 17px;
  margin: 0 0 15px;
  border: 0;
  font: 15.5px "Poppins", sans-serif;
  background: #fff;
  border: 1px solid #dedede;
}

@media (max-width: 991px) {
  .candidate_in .create_horizon .view form input {
    padding: 12px 17px;
    height: 45px;
  }
}

.candidate_in .create_horizon .view form input::-webkit-input-placeholder {
  color: #a1a1a1;
  font: 400 14.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form input:-ms-input-placeholder {
  color: #a1a1a1;
  font: 400 14.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form input::-ms-input-placeholder {
  color: #a1a1a1;
  font: 400 14.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form input::placeholder {
  color: #a1a1a1;
  font: 400 14.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form .view-on-upload {
  width: 100%;
  float: left;
  margin: 0 0 15px;
}

.candidate_in .create_horizon .view form .view-on-upload .upload-preview {
  float: left;
  width: 131px;
  height: 108px;
  border-radius: 5px;
  background: #f0f0f0;
}

.candidate_in .create_horizon .view form .view-on-upload .upload-preview.company {
  background: url(./assets/img/icon/group-4.png) #f0f0f0 center no-repeat;
  background-size: 50px;
}

.candidate_in .create_horizon .view form .view-on-upload .upload-preview.user {
  background: url(./assets/img/icon/icons-8-user.png) #f0f0f0 center no-repeat;
  background-size: 50px;
}

.candidate_in .create_horizon .view form .view-on-upload .upload {
  width: 200px;
  float: left;
  padding-left: 20px;
  margin: 10px 0;
}

.candidate_in .create_horizon .view form .view-on-upload .upload .upload_field {
  width: 100%;
  height: 30px;
  float: left;
  position: relative;
}

.candidate_in .create_horizon .view form .view-on-upload .upload .upload_field .upload_field_text {
  margin: 0;
  text-align: center;
  padding: 8px 38px;
  background: #f0f0f0;
  color: #787e8c;
  font: 600 10px "Poppins", sans-serif;
  height: 30px;
  border-radius: 30px;
}

.candidate_in .create_horizon .view form .view-on-upload .upload .upload_field input[type="file"] {
  opacity: 0;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.candidate_in .create_horizon .view form .view-on-upload .upload label {
  margin: 0;
  color: #626262;
  padding: 5px 0;
  font: 600 10px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.candidate_in .create_horizon .view form .uploader_view {
  width: 100%;
  height: auto;
  float: left;
  padding: 0px;
  margin: 0 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.candidate_in .create_horizon .view form .uploader_view .logo_privew {
  width: 131px;
  float: left;
  overflow: hidden;
  border-radius: 5px;
}

.candidate_in .create_horizon .view form .uploader_view .logo_privew img {
  width: 100%;
}

.candidate_in .create_horizon .view form .uploader_view .upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 13px 0 13px 15px;
}

.candidate_in .create_horizon .view form .uploader_view .upload .fileToUpload {
  height: 28px;
  width: 181px;
  float: left;
  background: #fff;
  margin: 0;
  padding: 0;
  border-radius: 15px;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: initial;
  appearance: none;
}

.candidate_in .create_horizon .view form .uploader_view .upload .fileToUpload::after {
  content: "Click to upload logo *";
  font: 500 10px "Poppins", sans-serif;
  color: #787e8c;
  background: #fff;
  padding: 8px 0px;
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.candidate_in .create_horizon .view form .uploader_view .upload label {
  font: 500 11px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.candidate_in .create_horizon .view form label {
  margin: 5px 0 0;
  color: #787e8c;
  line-height: 22px;
  font: 13.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form label.save_data {
  color: #2e2e2e;
  font: 500 13.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form label.save_data input[type="checkbox"] {
  margin: 2px 7px 0 0;
}

.candidate_in .create_horizon .view form label.save_data input[type="checkbox"]:checked {
  background: url(./assets/img/checkbox.jpg) #F6821F no-repeat;
  background-size: 100%;
}

.candidate_in .create_horizon .view form label a {
  color: #787e8c;
  font: 13.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form label input[type="checkbox"] {
  border: 1px solid #F6821F;
  width: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 5px 10px 0 0;
  height: 14px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  position: relative;
}

.candidate_in .create_horizon .view form label input[type="checkbox"]:checked {
  border: 1px solid #ce5a16;
  background: url(./assets/img/checkbox.jpg) #F6821F no-repeat;
  background-size: 100%;
}

.candidate_in .create_horizon .view form .Forgot_password {
  float: right;
  color: #2e2e2e;
  font: 500 13.5px "Poppins", sans-serif;
}

.candidate_in .create_horizon .view form .Forgot_password:hover {
  text-decoration: none;
}

.candidate_in .create_horizon .view form button {
  border-radius: 8px;
  background: linear-gradient(180deg, #F6821F 0%, #F48720 100%);
  box-shadow: 0px 8px 16px 0px rgba(246, 130, 31, 0.10);
  height: 48px;
  color: #fff;
  font: 16px "Poppins", sans-serif;
  font-weight: 600;
  width: 100%;
  border: 0;
  margin: 48px  0;
}

@media (max-width: 991px) {
  .candidate_in .create_horizon .view form button {
    font: 14.5px "Poppins", sans-serif;
    height: 45px;
    margin: 18px 0 0;
  }
}

.candidate_in .create_horizon .account_sign {
  width: 100%;
 
}

@media (max-width: 991px) {
  .candidate_in .create_horizon .account_sign {
    padding: 22px 0 0;
  }
}

.candidate_in .create_horizon .account_sign p {
  font: 16px "Poppins", sans-serif;
  color: #6f6f6f;
}

@media (max-width: 991px) {
  .candidate_in .create_horizon .account_sign p {
    font: 14px "Poppins", sans-serif;
  }
}

.candidate_in .create_horizon .account_sign p a {
  color: #F6821F;
  font-weight: 400;
  margin-left: 8px;
  font-size: 16px;
}

.setp_candidate_in {
  float: left;
  background: #fff;
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.setp_candidate_in .create_horizon_setp {
  width: 100%;
  margin: 0 auto;
  padding: 30px 0;
  height: auto;
  max-width: 888px;
}

.setp_candidate_in .create_horizon_setp .horizon_logo {
  float: left;
  width: 100%;
  margin: 0 0 50px;
}

.setp_candidate_in .create_horizon_setp .horizon_logo .logo {
  max-width: 200px;
  margin: auto;
  display: block;
  outline: none;
}

.setp_candidate_in .create_horizon_setp .horizon_logo .logo img {
  max-width: 200px;
  margin: auto;
}

.setp_candidate_in .create_horizon_setp .setp_view {
  float: left;
  width: 100%;
}

.setp_candidate_in .create_horizon_setp .setp_view ul {
  padding: 0 0 40px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li {
  float: left;
  padding: 0 15px;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li span {
  background: transparent;
  font: 600 15px "Poppins", sans-serif;
  color: #a1a1a1;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li.complet span {
  border: 2px solid #F6821F;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li.complet label {
  color: #8e8e93;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li.dashed {
  border-bottom: 2px dashed #adadad;
  width: 150px;
  height: 1px;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li.active span {
  background: #F6821F;
  color: #fff;
  font: 600 16px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li.active label {
  color: #F6821F;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
  border: 2px solid #a1a1a1;
}

.setp_candidate_in .create_horizon_setp .setp_view ul li label {
  float: left;
  display: block;
  font: 600 15px "Poppins", sans-serif;
  color: #a1a1a1;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild {
  float: left;
  width: 100%;
  background: #fff;
  border-radius: 5px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form {
  float: left;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 48px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block:last-child {
  -webkit-box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild {
  width: 100%;
  float: left;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group {
  width: 100%;
  float: left;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group p {
  font: 600 12px "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: #787e8c;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label {
  margin: 16px 0 0;
  color: #787e8c;
  line-height: 22px;
  font: 13.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label a {
  color: #787e8c;
  font: 13.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label input[type="checkbox"] {
  border: 1px solid #F6821F;
  width: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 1px 10px 0 0;
  border-radius: 2px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  position: relative;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label input[type="checkbox"]:checked {
  border: 1px solid #ce5a16;
  background: url(./assets/img/checkbox.jpg) #F6821F no-repeat;
  background-size: 100%;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild label {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
  width: 100%;
  float: left;
  margin-bottom: 48px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload {
  width: 100%;
  float: left;
  margin-top: 10px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload-preview {
  float: left;
  width: 131px;
  height: 108px;
  border-radius: 5px;
  background: #f0f0f0;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload-preview.user {
  background: url(./assets/img/icon/icons-8-user.png) #f0f0f0 center no-repeat;
  background-size: 50px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload {
  width: 200px;
  float: left;
  padding-left: 20px;
  margin: 10px 0;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload .upload_field {
  width: 100%;
  height: 30px;
  float: left;
  position: relative;
  padding-bottom: 3px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload .upload_field .upload_field_text {
  margin: 0;
  text-align: center;
  padding: 8px 38px;
  background: #f0f0f0;
  color: #787e8c;
  font: 600 10px "Poppins", sans-serif;
  height: 30px;
  border-radius: 30px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload .upload_field input[type="file"] {
  opacity: 0;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .view-on-upload .upload label {
  margin: 0;
  padding: 5px 0;
  font: 500 11px "Poppins", sans-serif;
  width: 100%;
  float: left;
  color: #626262;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild input {
  width: 100%;
  height: 55px;
  float: left;
  padding: 17px;
  margin: 15px 0 0;
  border: 0;
  font: 400 14px "Poppins", sans-serif;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #dedede;
  color: #6f6f6f;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .quill {
  width: 100%;
  float: left;
  padding: 17px;
  margin: 15px 0 0;
  border: 0;
  font: 400 14px "Poppins", sans-serif;
  border-radius: 5.5px;
}
.ql-container.ql-snow{
 color: #2e2e2e;
 border-bottom-left-radius: 5.5px;
 border-bottom-right-radius: 5.5px;
}
.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild input::-webkit-input-placeholder {
  color: #a1a1a1;
  font: 400 14px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild input:-ms-input-placeholder {
  color: #a1a1a1;
  font: 400 14px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild input::-ms-input-placeholder {
  color: #a1a1a1;
  font: 400 14px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild input::placeholder {
  color: #a1a1a1;
  font: 400 14px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild select {
  width: 100%;
  height: 55px;
  float: left;
  padding: 17px;
  margin: 15px 0 0 0;
  border: 1px solid #dedede;
  font: 400 14px "Poppins", sans-serif;
  border-radius: 5.5px;
  background: url(./assets/img/expand_icon.svg) #fff 95% center no-repeat;
  background-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #6f6f6f;
  cursor: pointer;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild select option {
  padding: 5px 0;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .date_range {
  width: 100%;
  height: auto;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 15px;
  column-gap: 15px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .date_range input {
  width: 100%;
  float: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #787e8c;
  font: 600 14.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .date_range input::-webkit-input-placeholder {
  color: #787e8c;
  font: 600 14.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .date_range input:-ms-input-placeholder {
  color: #787e8c;
  font: 600 14.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .date_range input::-ms-input-placeholder {
  color: #787e8c;
  font: 600 14.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .date_range input::placeholder {
  color: #787e8c;
  font: 600 14.5px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .uploader_view {
  width: 100%;
  height: auto;
  float: left;
  padding: 0px;
  margin: 15px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .uploader_view .upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 0 0;
  position: relative;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .uploader_view .upload .fileToUpload {
  height: 100%;
  width: 100%;
  margin: 0;
  position: absolute;
  z-index: 99;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .uploader_view .upload .placeholder {
  background: url(./assets/img/fileToUpload.jpg) #f0f0f0 no-repeat center;
  background-size: 98px;
  padding: 0px 0px;
  text-align: center;
  border-radius: 5px;
  left: 0;
  right: 0;
  margin: auto;
  height: 110px;
  width: 130px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view {
  width: 100%;
  float: left;
  margin-top: 17px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view .Attachments {
  width: 100%;
  float: left;
display: flex;
flex-direction: column;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view .Attachments .upload {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 0 0 16px 0;
  position: relative;
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #dedede;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view .Attachments .upload .fileToUpload {
  height: 50%;
  width: 100%;
  margin: 0;
  position: absolute;
  z-index: 99;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view .Attachments .upload .placeholder {
  background: #fff no-repeat center;
  background-size: 98px;
  padding: 0px 0px;
  text-align: center;
  border-radius: 5px;
  left: 0;
  right: 0;
  height: 145px;
  width: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view .Attachments .upload .placeholder img {
  width: 19px;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .Attachment_view .Attachments .upload .placeholder label {
  color: #2e2e2e;
  margin-top: 4px;
  line-height: 20px;
  margin-bottom: 0px;
  display: block;
  width: auto;
  font: 400 12px "Poppins", sans-serif;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block button .btn {
  width: 100%;
  float: left;
  background: #F6821F;
  font: 400 18px "Poppins", sans-serif;
  padding: 18px 50px;
  border-radius: 5px;
  border: 0;
  margin: 0;
  color: #fff;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block button:hover {
  background: #fff;
}

.M-app {
  width: 100%;
  float: left;
  background: url(./assets/img/app-section-bg.jpg) repeat-x;
  max-height: 574px;
}

@media (max-width: 768px) {
  .M-app {
    max-height: inherit;
    background-size: contain;
    padding-bottom: 50px;
  }
}

.M-app .content-area {
  padding-top: 80px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 4fr 1fr;
  grid-template-columns: 4fr 1fr;
}

@media (max-width: 1024px) {
  .M-app .content-area {
    padding-top: 50px;
  }
}

@media (max-width: 1280px) {
  .M-app .content-area {
    padding: 50px 53px 0;
  }
}

@media (max-width: 768px) {
  .M-app .content-area {
    display: block;
    padding: 50px 23px 0;
  }
}

.M-app .content-area .app-link-area {
  float: left;
  color: #fff;
  padding: 0px 0 0px;
}

@media (max-width: 768px) {
  .M-app .content-area .app-link-area {
    padding: 0px 0 20px;
  }
}

.M-app .content-area .app-link-area h6 {
  font: 600 32px "Poppins", sans-serif;
  padding: 0px 0 17px;
  float: left;
  width: 100%;
}

@media (max-width: 991px) {
  .M-app .content-area .app-link-area h6 {
    font: 600 25px "Poppins", sans-serif;
    padding: 0 0 15px;
  }
}

.M-app .content-area .app-link-area span {
  font: 400 20px "Poppins", sans-serif;
  padding: 0 0 20px;
  float: left;
  width: 100%;
}

@media (max-width: 991px) {
  .M-app .content-area .app-link-area span {
    font: 400 16px "Poppins", sans-serif;
    padding: 0 0 10px;
  }
}

.M-app .content-area .app-link-area .linkToapp {
  width: 100%;
  float: left;
  padding: 30px 0 0px;
}

@media (max-width: 1024px) {
  .M-app .content-area .app-link-area .linkToapp {
    padding: 20px 0 0px;
  }
}

@media (max-width: 576px) {
  .M-app .content-area .app-link-area .linkToapp {
    padding: 5px 0px 0;
  }
}

.M-app .content-area .app-link-area .linkToapp a {
  display: block;
  float: left;
  padding: 0 41px 0 0;
  margin-bottom: 10px;
}

@media (max-width: 1199px) {
  .M-app .content-area .app-link-area .linkToapp a {
    padding: 0 21px 0 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .M-app .content-area .app-link-area .linkToapp a {
    padding: 0 21px 10px 0;
  }
}

@media (max-width: 1366px) {
  .M-app .content-area .app-link-area .linkToapp a img {
    max-width: 200px;
  }
}

@media (max-width: 1199px) {
  .M-app .content-area .app-link-area .linkToapp a img {
    max-width: 200px;
  }
}

@media (max-width: 991px) {
  .M-app .content-area .app-link-area .linkToapp a img {
    max-width: 150px;
  }
}

.M-app .content-area .app-qr {
  width: 100%;
  float: left;
  z-index: 99;
}

.M-app .content-area .app-qr img {
  margin: 0 0 -52px;
}

@media (max-width: 768px) {
  .M-app .content-area .app-qr img {
    max-width: 309px;
    width: 100%;
  }
}
// infinite cross slider
.clients_testimonials{
  margin-bottom: 96px;
}
.headline_testim > h2{
  margin: 0;
  color : #6f6f6f;
font-size: 20px;
font-style: italic;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}
.testim_group,
.testim_group_2{
  display: flex;
  margin-top: 24px;
  margin: auto;
  overflow: hidden;
  position: relative;
 width: max-content;
}

@-webkit-keyframes scrollTrack1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scrollTrack1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}
// track animation 2
@-webkit-keyframes scrollTrack2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(250px * 7));
  }
}

@keyframes scrollTrack2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(250px * 7));
  }
}
.testim_group,
.testim_group_2 {
  background: white;
  min-height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.testim_group::before, .testim_group::after {
  content: "";
  position: absolute;
  width: 492px;
min-height: 250px;
  z-index: 2;
}
.testim_group::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.testim_group::before {
  left: 0;
  top: 0;
}

.testim_group_2::before, .testim_group::after {
  content: "";
  position: absolute;
  width: 492px;
min-height: 250px;
  z-index: 2;
}
.testim_group_2::after {
  left: 0;
  top: 0;
  transform: rotateZ(180deg);
}
.testim_group_2::before {
  right: 0;
  top: 0;
}

.testim_group .track_1 {
  -webkit-animation: scrollTrack1 80s linear infinite;
          animation: scrollTrack1 80s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.testim_group .single_testim {
  width: 500px;
margin-top: 24px;
border-radius: 8px;
border: 1px solid #dedede;
padding: 24px;
margin-left: 24px;
height: 300px;
position: relative;
}

.client_credit{
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 24px;
}
.single_testim > p{
  color: #6f6f6f;
font-size: 14px;
line-height: 26px;
font-weight: 300;
}
.cred_name{
  margin-left: 8px;
}
.cred_name > p{
  font-size: 16px;
  font-weight: 600;
  color: #6f6f6f;
}
.dashboard {
  max-width: 300px;
  width: 100%;
  float: left;
  min-height: 100vh;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

@media (max-width: 1440px) {
  .dashboard {
    max-width: 260px;
  }
}

@media (max-width: 1366px) {
  .dashboard {
    max-width: 230px;
  }
}

@media (max-width: 1280px) {
  .dashboard {
    max-width: 60px;
  }

  .dashboard:hover .short_nav {
    width: 219px;
    -webkit-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -ms-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -o-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  }

  .dashboard:hover .short_nav ul.side_menu li.logo .logo_lrg {
    display: block;
    margin: 2px 20px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }

  .dashboard:hover .short_nav ul.side_menu li.logo .logo_icon {
    display: none;
    margin: 2px 9px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }

  .dashboard:hover .short_nav ul.side_menu li a {
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    padding: 14px 0px 14px 0px;
  }

  .dashboard:hover .short_nav a.log_out {
    th: 100%;
    padding: 8px 28px;
    font: 600 14px "Poppins", sans-serif;
    border-radius: 25px;
  }
}

@media (max-width: 991px) {
  .dashboard.active .short_nav {
    z-index: 9999;
  }
}

@media (max-width: 768px) {
  .dashboard {
    max-width: 0px;
  }

  .dashboard.active .short_nav {
    width: 260px;
    max-width: 260px;
  }
}

.dashboard .Mburger {
  width: 30px;
  height: 25px;
  position: absolute;
  top: 14px;
  left: 20px;
  border-radius: 4px;
  z-index: 10;
  display: none;
}

@media (max-width: 768px) {
  .dashboard .Mburger {
    display: block;
  }
}

.dashboard .Mburger.clicked span {
  background-color: transparent;
}

.dashboard .Mburger.clicked span::before {
  -webkit-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}

.dashboard .Mburger.clicked span::after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.dashboard .Mburger span {
  position: relative;
  margin-top: 9px;
  background: #F6821F;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 10px;
  left: 0;
  margin-left: 0px;
  margin-top: 0px;
}

.dashboard .Mburger span::before,
.dashboard .Mburger span::after {
  background: #F6821F;
  position: absolute;
  content: "";
}

.dashboard .Mburger span::before {
  top: -9px;
}

.dashboard .Mburger span::after {
  top: 9px;
}

.dashboard .Mburger span,
.dashboard .Mburger span::before,
.dashboard .Mburger span::after {
  background: #F6821F;
  display: block;
  width: 25px;
  height: 2.4px;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  transition-property: background-color, -webkit-transform;
  transition-property: background-color, transform;
  transition-property: background-color, transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.dashboard .Mburger:hover {
  cursor: pointer;
}

.dashboard .short_nav {
  float: left;
  width: 100%;
  height: 100vh;
  max-width: 300px;
  padding-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #fff;
  left: 0;
  top: 0;
  position: fixed;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  overflow-y: auto;
}

@media (max-width: 1440px) {
  .dashboard .short_nav {
    max-width: 260px;
  }
}

@media (max-width: 1366px) {
  .dashboard .short_nav {
    max-width: 230px;
  }
}

@media (max-width: 1280px) {
  .dashboard .short_nav {
    width: 60px;
    overflow-x: hidden;
    z-index: 99;
  }
}

@media (max-width: 768px) {
  .dashboard .short_nav {
    max-width: 0px;
    -webkit-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -ms-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    -o-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
    box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  }
}

.dashboard .short_nav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px;
  background-color: #fff;
}

.dashboard .short_nav::-webkit-scrollbar {
  width: 0px;
  background-color: #fff;
}

.dashboard .short_nav::-webkit-scrollbar-thumb {
  border-radius: 0px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.dashboard .short_nav ul.side_menu {
  width: 100%;
}

.dashboard .short_nav ul.side_menu li {
  width: 100%;
  float: left;
  padding: 0 0 0 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo:hover{
background-color: #fff !important;
}
@media (max-width: 1280px) {
  .dashboard .short_nav ul.side_menu li {
    padding: 0 0 0 22px;
    width: 219px;
  }
}

@media (max-width: 768px) {
  .dashboard .short_nav ul.side_menu li {
    width: 100%;
    padding: 0 0 0 22px;
  }
}

.dashboard .short_nav ul.side_menu li:hover {
  background: #fef5ee;
}

@media (max-width: 1280px) {
  .dashboard .short_nav ul.side_menu li.logo {
    padding: 30px 0 22px;
  }
}

.dashboard .short_nav ul.side_menu li.logo .logo_lrg {
 margin: 32px 0;
}

@media (max-width: 1280px) {
  .dashboard .short_nav ul.side_menu li.logo .logo_lrg {
    display: none;
    max-width: 155px;
    margin: 2px 5px;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
  }
}

@media (max-width: 768px) {
  .dashboard .short_nav ul.side_menu li.logo .logo_lrg {
    display: block;
    margin: 2px 20px;
  }
}

.dashboard .short_nav ul.side_menu li.logo .logo_icon {
  max-width: 50px;
  margin: 2px 5px;
  display: none;
}

@media (max-width: 1280px) {
  .dashboard .short_nav ul.side_menu li.logo .logo_icon {
    display: block;
  }
}

@media (max-width: 768px) {
  .dashboard .short_nav ul.side_menu li.logo .logo_icon {
    display: none;
  }
}

.dashboard .short_nav ul.side_menu li.active {
  background: #fef5ee;
}

.dashboard .short_nav ul.side_menu li.active span .active {
  display: block;
}

.dashboard .short_nav ul.side_menu li.active span .off {
  display: none;
}

.dashboard .short_nav ul.side_menu li.active a {
  color: #F6821F;
}

.dashboard .short_nav ul.side_menu li span {
  float: left;
  padding: 12px 1px 12px 0px;
}

.dashboard .short_nav ul.side_menu li span img {
  max-width: 15px;
  margin: 0 15px 0 0;
}

.dashboard .short_nav ul.side_menu li span .active {
  display: none;
}

.dashboard .short_nav ul.side_menu li span .off {
  display: block;
}

.dashboard .short_nav ul.side_menu li a {
  font: 600 14px "Poppins", sans-serif;
  color: #6f6f6f;
  outline: none;
  float: left;
  text-decoration: none;
  width: 100%;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  text-transform: capitalize;
  display: block;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  padding: 14px 0px 14px 0px;
}

@media (max-width: 1280px) {
  .dashboard .short_nav ul.side_menu li a {
    overflow: hidden;
    padding: 14px 0px 14px 8px;
  }
}

@media (max-width: 768px) {
  .dashboard .short_nav ul.side_menu li a {
    padding: 14px 0px 14px 0px;
  }
}

.dashboard .short_nav a.log_out {
  background: #f7f7f7;
  font: 600 14px "Poppins", sans-serif;
  color: #6f6f6f;
  text-decoration: none;
  padding: 8px 28px;
  border-radius: 25px;
  -width: 122px;
  margin: 0 auto;

}

.dashboard .short_nav a.log_out:hover {
  color: #2e2e2e;
}

@media (max-width: 1280px) {
  .dashboard .short_nav a.log_out {
    font: 600 0px "Poppins", sans-serif;
    padding: 13px 25px 13px 17px;
    border-radius: 50%;
  }
}

@media (max-width: 768px) {
  .dashboard .short_nav a.log_out {
    width: 100%;
    padding: 8px 28px;
    font: 600 14px "Poppins", sans-serif;
    border-radius: 25px;
  }
}

.dashboard .short_nav a.log_out img {
  width: 15px;
  margin-right: 5px;
}

.popup {
  top: 0;
  left: 0;
  position: absolute;
  z-index: 99999;
  background-color: #0000006e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 100vh;
}

.popup .alin_frm {
  padding: 30px 40px 40px;
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  background: #fff;
  border-radius: 5px;
  max-width: 800px;
}

.popup .Change_password {
  height: auto;
  width: 450px;
  background: #fff;
  border-radius: 8px;
}

.popup .Change_password .head {
  padding: 25px 30px 5px;
  height: auto;
  float: left;
  width: 100%;
  border-bottom: solid 1px #e4e4eb;
  position: relative;
}

.popup .Change_password .head .title {
  font: 600 19px "Poppins", sans-serif;
  color: #2e2e2e;
}

.popup .Change_password .head .close {
  position: absolute;
  right: -16px;
  background: url(./assets/img/icon/close.jpg) #fff center no-repeat;
  top: -16px;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.33);
  -ms-shadow: 2px 2px 43px 0px rgba(0, 0, 0, 0.33);
  -o-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.33);
  box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.33);
  z-index: 99999999999;
  height: 40px;
  width: 40px;
}

.popup .Change_password .body {
  padding: 15px 30px;
  height: auto;
  float: left;
  width: 100%;
}

.popup .Change_password .body form {
  height: auto;
  width: 100%;
}

.popup .Change_password .body form input {
  height: 41px;
  -webkit-box-shadow: inset 0 0.5px 2px 0 rgba(96, 97, 112, 0.29);
  box-shadow: inset 0 0.5px 2px 0 rgba(96, 97, 112, 0.29);
  margin-bottom: 20px;
  float: left;
  width: 100%;
  min-width: 175px;
  border-radius: 8px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 0 10px;
  font: 600 12px "Poppins", sans-serif;
}

.popup .Change_password .body form input::-webkit-input-placeholder {
  font: 600 12px "Poppins", sans-serif;
  color: #8f90a6;
}

.popup .Change_password .body form input:-ms-input-placeholder {
  font: 600 12px "Poppins", sans-serif;
  color: #8f90a6;
}

.popup .Change_password .body form input::-ms-input-placeholder {
  font: 600 12px "Poppins", sans-serif;
  color: #8f90a6;
}

.popup .Change_password .body form input::placeholder {
  font: 600 12px "Poppins", sans-serif;
  color: #8f90a6;
}

.popup .Change_password .body form .action-box {
  width: 100%;
  max-width: 1000px;
  padding: 0 30px 0 0;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 25px;
  margin-top: 15px;
}

.popup .Change_password .body form .action-box button {
  z-index: 99999;
  border-radius: 5px;
  padding: 14px 19px;
  width: 160px;
  overflow: hidden;
  position: relative;
  border: 1px solid #F6821F;
  outline: none;
  font: 600 13px "Poppins", sans-serif;
}

.popup .Change_password .body form .action-box button.abote {
  margin-right: 15px;
  background: #fff;
  color: #F6821F;
}

.popup .Change_password .body form .action-box button.abote:hover {
  color: #fff;
}

.popup .Change_password .body form .action-box button.search {
  background: #F6821F;
  color: #fff;
}

.popup .Change_password .body form .action-box button.search:hover {
  color: #F6821F;
}

.popup .Change_password .body form .action-box button.search::after {
  background: #fff;
}

.popup .Change_password .body form .action-box button::after {
  width: 0px;
  height: 0px;
}

.popup .Change_password .body form .action-box button:hover::after {
  width: 170px;
  height: 150px;
  z-index: -99;
}

.pe-s-form {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
  max-width: 800px;
}

.pe-s-form .headView {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0px 0px 20px;
  margin: 0px 0px 20px;
  border-bottom: 1px solid #e6e9ec;
}

.pe-s-form .headView span.name {
  font: 600 18px "Poppins", sans-serif;
  color: #2e2e2e;
  text-align: left;
}

.pe-s-form .headView .close {
  width: 18px;
  height: 20px;
  background: url(./assets/img/icon/close.jpg) no-repeat;
  background-size: 18px;
  cursor: pointer;
}

.pe-s-form .position-title,
.pe-s-form .position-company-name,
.pe-s-form .geo-location-name {
  float: left;
  width: 100%;
}

.pe-s-form .position-title label,
.pe-s-form .position-company-name label,
.pe-s-form .geo-location-name label {
  font: 400 15px "Poppins", sans-serif;
  margin-bottom: 8px;
  float: left;
}

.pe-s-form .position-title .pe-artdeco,
.pe-s-form .position-title .stack-position-company,
.pe-s-form .position-title .search-location,
.pe-s-form .position-company-name .pe-artdeco,
.pe-s-form .position-company-name .stack-position-company,
.pe-s-form .position-company-name .search-location,
.pe-s-form .geo-location-name .pe-artdeco,
.pe-s-form .geo-location-name .stack-position-company,
.pe-s-form .geo-location-name .search-location {
  float: left;
  width: 100%;
}

.pe-s-form .position-title .pe-artdeco input,
.pe-s-form .position-title .stack-position-company input,
.pe-s-form .position-title .search-location input,
.pe-s-form .position-company-name .pe-artdeco input,
.pe-s-form .position-company-name .stack-position-company input,
.pe-s-form .position-company-name .search-location input,
.pe-s-form .geo-location-name .pe-artdeco input,
.pe-s-form .geo-location-name .stack-position-company input,
.pe-s-form .geo-location-name .search-location input {
  height: 41px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  min-width: 175px;
  border-radius: 4px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 0 10px;
  font: 400 16px "Poppins", sans-serif;
}

.pe-s-form .position-title .pe-artdeco input::-webkit-input-placeholder,
.pe-s-form .position-title .stack-position-company input::-webkit-input-placeholder,
.pe-s-form .position-title .search-location input::-webkit-input-placeholder,
.pe-s-form .position-company-name .pe-artdeco input::-webkit-input-placeholder,
.pe-s-form .position-company-name .stack-position-company input::-webkit-input-placeholder,
.pe-s-form .position-company-name .search-location input::-webkit-input-placeholder,
.pe-s-form .geo-location-name .pe-artdeco input::-webkit-input-placeholder,
.pe-s-form .geo-location-name .stack-position-company input::-webkit-input-placeholder,
.pe-s-form .geo-location-name .search-location input::-webkit-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .position-title .pe-artdeco input:-ms-input-placeholder,
.pe-s-form .position-title .stack-position-company input:-ms-input-placeholder,
.pe-s-form .position-title .search-location input:-ms-input-placeholder,
.pe-s-form .position-company-name .pe-artdeco input:-ms-input-placeholder,
.pe-s-form .position-company-name .stack-position-company input:-ms-input-placeholder,
.pe-s-form .position-company-name .search-location input:-ms-input-placeholder,
.pe-s-form .geo-location-name .pe-artdeco input:-ms-input-placeholder,
.pe-s-form .geo-location-name .stack-position-company input:-ms-input-placeholder,
.pe-s-form .geo-location-name .search-location input:-ms-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .position-title .pe-artdeco input::-ms-input-placeholder,
.pe-s-form .position-title .stack-position-company input::-ms-input-placeholder,
.pe-s-form .position-title .search-location input::-ms-input-placeholder,
.pe-s-form .position-company-name .pe-artdeco input::-ms-input-placeholder,
.pe-s-form .position-company-name .stack-position-company input::-ms-input-placeholder,
.pe-s-form .position-company-name .search-location input::-ms-input-placeholder,
.pe-s-form .geo-location-name .pe-artdeco input::-ms-input-placeholder,
.pe-s-form .geo-location-name .stack-position-company input::-ms-input-placeholder,
.pe-s-form .geo-location-name .search-location input::-ms-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .position-title .pe-artdeco input::placeholder,
.pe-s-form .position-title .stack-position-company input::placeholder,
.pe-s-form .position-title .search-location input::placeholder,
.pe-s-form .position-company-name .pe-artdeco input::placeholder,
.pe-s-form .position-company-name .stack-position-company input::placeholder,
.pe-s-form .position-company-name .search-location input::placeholder,
.pe-s-form .geo-location-name .pe-artdeco input::placeholder,
.pe-s-form .geo-location-name .stack-position-company input::placeholder,
.pe-s-form .geo-location-name .search-location input::placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .position-employment-type {
  float: left;
  width: 100%;
}

.pe-s-form .position-employment-type label {
  font: 400 15px "Poppins", sans-serif;
  margin-bottom: 8px;
  float: left;
}

.pe-s-form .position-employment-type .position-employment {
  float: left;
  width: 100%;
}

.pe-s-form .position-employment-type .position-employment select {
  height: 41px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  background: url(./assets/img/icon/icons8-expand_arrow.png) no-repeat right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 175px;
  border-radius: 4px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 0 10px;
  font: 400 16px "Poppins", sans-serif;
}

.pe-s-form .position-employment-type .position-employment select option {
  font: 400 13px "Poppins", sans-serif;
}

.pe-s-form .time-period-secondary {
  float: left;
  width: 100%;
}

.pe-s-form .time-period-secondary .time-period-form {
  float: left;
  width: 100%;
}

.pe-s-form .time-period-secondary .time-period-form .currently-works-here {
  padding: 2px 0 2px;
  float: left;
  width: 100%;
}

@media (max-width: 991px) {
  .pe-s-form .time-period-secondary .time-period-form .currently-works-here {
    padding-bottom: 10px;
  }
}

.pe-s-form .time-period-secondary .time-period-form .currently-works-here label {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .time-period-secondary .time-period-form .currently-works-here input[type="checkbox"].period_ongoing {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 27px;
  height: 15px;
  background: #ddd;
  vertical-align: middle;
  border-radius: 1.6em;
  position: relative;
  outline: 0;
  margin-right: 7px;
  margin-top: -1px;
  cursor: pointer;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}

.pe-s-form .time-period-secondary .time-period-form .currently-works-here input[type="checkbox"].period_ongoing::after {
  content: "";
  width: 14px;
  height: 15px;
  background: white;
  position: absolute;
  border-radius: 1.2em;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 1px;
  -webkit-box-shadow: 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px rgba(0, 0, 0, 0.5);
  -webkit-transition: left 0.1s ease-in-out;
  transition: left 0.1s ease-in-out;
}

.pe-s-form .time-period-secondary .time-period-form .currently-works-here input[type="checkbox"].period_ongoing:checked {
  background: #F6821F;
}

.pe-s-form .time-period-secondary .time-period-form .currently-works-here input[type="checkbox"].period_ongoing:checked::after {
  left: 13px;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container {
  float: left;
  width: 100%;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice {
  float: left;
  width: 100%;
  padding-top: 10px;
  max-width: 390px;
}

@media (max-width: 991px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice {
    padding-top: 0px;
  }
}

@media (max-width: 768px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice {
    max-width: 100%;
  }
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice.disable {
  display: none;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date label.Date,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice label.Date {
  font: 400 15px "Poppins", sans-serif;
  margin-bottom: 8px;
  float: left;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period {
  padding-right: 25px;
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period {
    padding-right: 0px;
  }
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period {
  float: left;
  width: 170px;
}

@media (max-width: 768px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period {
    width: 100%;
    max-width: 235px;
  }
}

@media (max-width: 576px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period {
    width: 100%;
    max-width: 200px;
  }
}

@media (max-width: 480px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period {
    max-width: 130px;
  }
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period.disable,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period.disable {
  opacity: 0.5;
  pointer-events: none;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period label,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period label {
  font: 400 13px "Poppins", sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
  float: left;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period select,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period select {
  height: 41px;
  margin-bottom: 12px;
  float: left;
  width: 100%;
  background: url(./assets/img/icon/icons8-expand_arrow.png) no-repeat right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 165px;
  border-radius: 4px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 0 10px;
  font: 400 14px "Poppins", sans-serif;
}

.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period select option,
.pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period select option {
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 600px) {

  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_month_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_Start_year_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_month_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_date .field_position_time_period .date_unit_End_year_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_month_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_Start_year_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_month_period select,
  .pe-s-form .time-period-secondary .time-period-form .timePeriod_container .time_period_start_notice .field_position_time_period .date_unit_End_year_period select {
    min-width: 100%;
  }
}

.pe-s-form .field_Location {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 768px) {
  .pe-s-form .field_Location {
    display: block;
  }
}

.pe-s-form .field_Location .job_city,
.pe-s-form .field_Location .job_state,
.pe-s-form .field_Location .job_country {
  max-width: 232px;
  width: 100%;
  float: left;
}

@media (max-width: 991px) {

  .pe-s-form .field_Location .job_city,
  .pe-s-form .field_Location .job_state,
  .pe-s-form .field_Location .job_country {
    max-width: 202px;
  }
}

@media (max-width: 768px) {

  .pe-s-form .field_Location .job_city,
  .pe-s-form .field_Location .job_state,
  .pe-s-form .field_Location .job_country {
    max-width: 100%;
  }
}

@media (max-width: 600px) {

  .pe-s-form .field_Location .job_city span,
  .pe-s-form .field_Location .job_state span,
  .pe-s-form .field_Location .job_country span {
    width: 100% !important;
    max-width: 100%;
  }
}

.pe-s-form .field_Location label {
  font: 400 13px "Poppins", sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
  float: left;
}

.pe-s-form .field_Location span input {
  height: 41px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
  min-width: 175px;
  border-radius: 4px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 0 10px;
  font: 400 16px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .pe-s-form .field_Location span input {
    max-width: 100%;
  }
}

.pe-s-form .field_Location span input::-webkit-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .field_Location span input:-ms-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .field_Location span input::-ms-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .field_Location span input::placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .field_Location span select {
  height: 41px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
  background: url(./assets/img/expand_icon.svg) right 5% center no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 165px;
  border-radius: 4px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 0 10px;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .pe-s-form .field_Location span select {
    max-width: 100%;
  }
}

.pe-s-form .field_Location span select option {
  font: 400 14px "Poppins", sans-serif;
}

.pe-s-form .description-floating {
  float: left;
  width: 100%;
}

.pe-s-form .description-floating label {
  font: 400 13px "Poppins", sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
  float: left;
}

.pe-s-form .description-floating .position-description {
  height: 80px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  min-width: 175px;
  border-radius: 4px;
  border: solid 1px #e4e4eb;
  color: #2e2e2e;
  padding: 10px;
  font: 400 16px "Poppins", sans-serif;
}

.pe-s-form .description-floating .position-description::-webkit-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .description-floating .position-description:-ms-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .description-floating .position-description::-ms-input-placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .description-floating .position-description::placeholder {
  font: 400 12px "Poppins", sans-serif;
}

.pe-s-form .footer_save {
  float: left;
  width: 100%;
}

.pe-s-form .footer_save button {
  border-radius: 4px;
  background: #F6821F;
  border: 0;
  font: 600 16px "Poppins", sans-serif;
  color: #fff;
  float: right;
  padding: 11px 50px;
}

@media (max-width: 768px) {
  .pe-s-form .footer_save button {
    font: 600 14px "Poppins", sans-serif;
  }
}

/*# sourceMappingURL=style.css.map */

html,
body {
  height: 100%;
}

body {
  margin: 0;
   font-family : 'Poppins', sans-serif;
}

/*CUSTOM STYLES*/
.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view {
  width: 100%;
  height: auto;
  background: #fff;
  padding: 30px 0;
  border-radius: 5px;
  float: left;
  overflow: hidden;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view {
    padding: 15px 0;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view {
  padding: 0 30px 0;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view {
    padding: 0 20px 0;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view {
    padding: 0 15px 0;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value {
  width: 100%;
  float: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -webkit-column-gap: 15px;
  column-gap: 15px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input {
  height: 41px;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  min-width: 140px;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #6f6f6f;
  padding: 0 10px;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input {
    font: 600 10px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input {
    margin-bottom: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::-webkit-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input:-ms-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::-ms-input-placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::placeholder {
  font: 400 14px "Poppins", sans-serif;
  color: #6f6f6f;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::-webkit-input-placeholder {
    font: 600 10px "Poppins", sans-serif;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input:-ms-input-placeholder {
    font: 600 10px "Poppins", sans-serif;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::-ms-input-placeholder {
    font: 600 10px "Poppins", sans-serif;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col input::placeholder {
    font: 600 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col select {
  margin-bottom: 20px;
  height: 41px;
  padding: 0 10px;
  float: left;
  width: 100%;
  min-width: 175px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: solid 1px #dedede;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  background: url(./assets/img/expand_icon.svg) right 5% center no-repeat;
  background-size: 10px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col select {
    font: 600 10px "Poppins", sans-serif;
    min-width: 142px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col select {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col select {
    margin-bottom: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col select option {
  padding: 5px 0;
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col select option {
    font: 600 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col a {
  z-index: 99;
  border-radius: 4px;
  padding: 12px 24px;
  text-decoration: none;
  overflow: hidden;
  background: #F6821F;
  color: #fff;
  position: relative;
  border: 0;
  outline: none;
  font: 400 14px "Poppins", sans-serif;
  text-transform: capitalize;
}
.reports_btns button{
  z-index: 99;
  border-radius: 4px;
  padding: 12px 24px;
  text-decoration: none;
  overflow: hidden;
  background: #F6821F;
  color: #fff;
  position: relative;
  border: 0;
  outline: none;
  font: 400 14px "Poppins", sans-serif;
  text-transform: capitalize;
}
@media (max-width: 576px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col button,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .filtter_view .get_field_value .col a {
    padding: 9.6px 5px;
    font: 600 10px "Poppins", sans-serif;
    width: 82px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate {
  float: left;
  width: 100%;
  border-radius: 5px;
  height: auto;
  background: #fff;
  padding: 30px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate {
    margin-top: 10px;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate {
    padding: 15px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs {
  float: left;
  width: 100%;
  padding-bottom: 30px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs {
    overflow-y: auto;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-color: #f5f5f5;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs::-webkit-scrollbar {
    width: 4px;
    height: 6px;
    background-color: #f5f5f5;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs .title {
  padding: 25px 0px 15px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table {
  float: left;
  width: 100%;
  border-collapse: collapse;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr {
  width: 100%;
  padding: 0px;
  border-radius: 0px;
  text-align: center;
  margin-top: 0px;
  border: 0;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr {
    padding: 0 4px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr:nth-child(odd) {
  background: #f5f5f5;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr.Payment_head {
  margin-top: 0px;
  border: solid 0px;
  background: #efefef;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr.Payment_head th {
  padding: 10px;
  color: #8e8e93;
  font: 600 12px "Poppins", sans-serif;
  min-width: 75px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr.Payment_head th {
    font: 600 9px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr td {
  padding: 15px 10px;
  color: #2e2e2e;
  font: 400 14px "Poppins", sans-serif;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr td {
    padding: 10px;
    font: 600 10px "Poppins", sans-serif;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .report_view .Listed-candidate .Listed-Jobs table tr td {
    padding: 8px 5px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications {
  width: 100%;
  height: auto;
  display: -ms-grid;
  display: grid;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs {
  border-radius: 5px;
  padding: 28px 28px;
  background: #fff;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs {
    padding: 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 0 18px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .title span {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .title a {
  text-decoration: none;
  font: 500 12px "Poppins", sans-serif;
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view {
  width: 100%;
  height: auto;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier {
  float: left;
  width: 100%;
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  border-radius: 5px;
  border: 1px solid #9797975c;
  margin-bottom: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view {
  float: left;
  max-width: 100px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view {
    margin: 4px 0 5px;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view {
    max-width: 70px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view img {
  max-width: 77px;
  width: 100%;
  border-radius: 50%;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .view img {
    border: 2px solid #ed6c20;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details {
  float: left;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details {
    margin-top: 11px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details {
  max-width: 250px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate {
  padding: 0 23px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate {
    padding: 0 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .name {
  color: #2e2e2e;
  font: 600 16px "Poppins", sans-serif;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .position {
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 4px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .View_Details .candidate .timeLevel {
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
  width: 100%;
  float: left;
  padding: 4px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status {
  margin-bottom: 7px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status span {
  font: 500 9px "Poppins", sans-serif;
  padding: 4px 7px;
  border-radius: 5px;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status span {
    float: right;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status.Submitted span {
  color: #be7209;
  background-color: #fff6d0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status.Shortlisted span {
  color: #228934;
  background-color: #dbffe1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .details .status.Rejected span {
  color: #d50b00;
  background-color: #ffedec;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action {
  float: left;
  max-width: 100px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action {
    max-width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a {
  font: 500 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
  border: 1px solid #F6821F;
  padding: 5px 10px;
  color: #F6821F;
  border-radius: 5px;
  margin-top: 13px;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.view_more {
    margin-top: 5px;
    font: 500 10px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Saved_Applications .Recently_Applied_Jobs .applier_view .applier .action a.share {
  color: #2e2e2e;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area {
  width: 100%;
  height: auto;
  float: left;
  border-radius: 10px;
  background-color: #fff;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view {
  width: 100%;
  height: auto;
  float: left;
  border-bottom: solid 1px #ebebeb;
  padding-left: 30px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view {
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 5px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view {
    padding-top: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul {
  padding: 0;
  margin: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li {
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a {
  color: #F6821F;
  position: relative;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a::after {
  position: absolute;
  height: 2px;
  left: 0;
  content: "";
  bottom: 0;
  background-color: #F6821F;
  width: 100%;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a::after {
    display: none;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li.active a:hover {
  opacity: 0.9;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a {
  padding: 20px 0px 12px 0;
  margin-right: 40px;
  display: block;
  text-decoration: none;
  color: #6f6f6f;
  text-align: left;
  font: 600 14px "Poppins", sans-serif;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a {
    padding: 10px 0px 12px 0;
    font: 500 12px "Poppins", sans-serif;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a {
    padding: 5px 0px 12px 0;
    margin-right: 18px;
    font: 500 10px "Poppins", sans-serif;
    color: #2e2e2e;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area .head_view ul li a:hover {
  opacity: 0.8;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form {
  float: left;
  width: 100%;
  height: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section {
  float: left;
  width: 100%;
  height: auto;
  display: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.active {
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw {
  background: #f9f9f9;
  padding: 5px 15px 0;
  margin-bottom: 10px;
  border-radius: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw.add_edu {
  background: transparent;
  padding: 5px 0px 0;
  margin-bottom: 0px;
  border-radius: 0px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp {
    margin-bottom: 17px;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp {
    margin-bottom: 15px;
    margin-top: 10px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp {
    margin-bottom: 0px;
    margin-top: 10px;
  }

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp a.edit {
    margin-bottom: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section.Education .field_view .rpw .from_grp:last-child {
  border-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0px 30px 10px;
  width: 100%;
  float: left;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section {
    padding: 0px 20px 10px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section {
    margin-bottom: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section span {
  color: #2e2e2e;
  font: 600 18px "Poppins", sans-serif;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section span {
    font: 600 16px "Poppins", sans-serif;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section span {
    font: 600 14px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section a {
  font: 500 14px "Poppins", sans-serif;
  color: #F6821F;
  background-size: 20px;
  text-align: right;
  padding-left: 24px;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .title_section a {
    font: 500 10px "Poppins", sans-serif;
    background-size: 18px;
    height: 18px;
    padding-left: 22px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .Projects_View {
  width: 100%;
  float: left;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .Projects_View {
    padding: 11px 14px 0;
    background: #f3f3f3;
    border-radius: 5px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view {
  float: left;
  width: 100%;
  padding: 10px 30px 20px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view {
    padding: 10px 20px 20px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw {
  display: -ms-grid;
  display: grid;
  -webkit-column-gap: 24px;
  column-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu {
  border-top: 1px solid #ebebeb;
  display: block;
  width: 100%;
  float: left;
  padding: 10px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
  float: left;
  width: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.5fr 0.5fr 0.6fr;
  grid-template-columns: 0.5fr 0.5fr 0.6fr;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
    -ms-grid-columns: 0.5fr 0.7fr;
    grid-template-columns: 0.5fr 0.7fr;
  }
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .add,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .add,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .add {
  display: block;
  float: left;
  border-radius: 4px;
  border: 1px solid #F6821F;
  color: #fff;
  background: #F6821F;
  font: 600 14px "Poppins", sans-serif;
  padding: 16px 24px;
  max-width: 160px;
}

@media (max-width: 768px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .add,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .add,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .add {
    max-width: 100%;
    padding: 11px 5px;
    font: 600 12px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .from_grp,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .from_grp,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .from_grp {
  margin-top: 8px;
  margin-bottom: 18px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .from_grp label,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .from_grp label,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .from_grp label {
  color: #2e2e2e;
  font: 500 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .from_grp .edit_field select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .from_grp .edit_field select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .from_grp .edit_field select {
  font: 500 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Year select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Year select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Year select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .order_priority select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .order_priority select,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .order_priority select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Year,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Major,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Year,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Major,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Year,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Major {
  margin-top: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .or,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .or,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .or {
  width: inherit;
  max-width: 32px;
  text-align: center;
  float: initial;
  color: #2e2e2e;
  font: 600 12px "Poppins", sans-serif;
  padding: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Grade,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Grade,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Grade {
  margin-top: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .Grade .edit_field input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .Grade .edit_field input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .Grade .edit_field input {
  max-width: 100px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .forword,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .forword,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .forword {
  display: block;
  float: left;
  border-radius: 3px;
  margin-top: 10px;
  border: 1px solid #F6821F;
  text-transform: capitalize;
  background: #F6821F;
  color: #fff;
  font: 500 14px "Poppins", sans-serif;
  padding: 9px 40px;
  margin-bottom: 5px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addProject-row .forword:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .addEducation-row .forword:hover,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.add_edu .add-row .forword:hover {
  background: #fff;
  color: #F6821F;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.fullView {
  display: block;
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw.fullView .from_grp label {
  color: #6f6f6f;
  font: 400 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration {
  width: 100%;
  float: left;
  padding: 0 0 30px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration {
    padding: 0 0 12px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration label {
  width: 100%;
  font: 400 14px "Poppins", sans-serif;
  float: left;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration p {
  line-height: 24px;
  font: 400 14px "Poppins", sans-serif;
  float: left;
  color: #2e2e2e;
  width: 100%;
  padding: 0 0 0;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .declaration p {
    font: 500 12px/19px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp {
  float: left;
  margin-bottom: 30px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp {
    margin-bottom: 10px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
    margin-top: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp.deactivate {
  opacity: 0.5;
  pointer-events: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp label {
  width: 100%;
  font: 400 14px "Poppins", sans-serif;
  float: left;
  color: #6f6f6f;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span {
  font: 400 16px "Poppins", sans-serif;
  color: #2e2e2e;
  float: left;
  width: 100%;
  padding-top: 8px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span {
    font: 600 13px "Poppins", sans-serif;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp .clint {
  width: 100%;
  font: 500 12px "Poppins", sans-serif;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp .clint .Project_location {
  font: 500 14px "Poppins", sans-serif;
  float: left;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp .clint p {
  font: 500 14px "Poppins", sans-serif;
  color: #2e2e2e;

}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp a.edit {
  display: block;
  float: left;
  border-radius: 3px;
  margin-top: 10px;
  color: #F6821F;
  font: 500 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp a.edit:hover {
  text-decoration: underline;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select {
  max-width: 220px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  font: 500 15px "Poppins", sans-serif;
  color: #2e2e2e;
  border: 1px solid #ccc;
  padding: 0 10px;
}

@media (max-width: 600px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select {
    max-width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::-webkit-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select:-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select::placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets//img/expand_icon.svg) right 5% center no-repeat;
  background-size: 12px;
  appearance: none;
  color: #2e2e2e;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp span.edit_field textarea {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpw .from_grp p {
  font: 400 16px "Poppins", sans-serif;
  color: #2e2e2e;
  float: left;
  width: 100%;
  line-height: 21px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view {
  float: left;
  width: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info {
  width: 100%;
  float: left;
  padding-top: 8px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info {
  width: 100%;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  padding: 0 0 0 20px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info {
    padding: 0 0 0 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .company-Icon {
  width: 90px;
  float: left;
  background: url(./assets//img/icon/group-4.png) no-repeat top left;
  background-size: 40px;
  height: 60px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .company-Icon {
    width: 50px;
    background-size: 30px;
    height: 45px;
  }
}

@media (max-width: 600px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .company-Icon {
    display: none;
    width: 40px;
    background-size: 20px;
    height: 36px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info {
  width: 100%;
  float: left;
  padding-bottom: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #e6e9ec;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info .companyDetails_view {
  width: 100%;
  float: left;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span {
  color: #6f6f6f;
  width: 100%;
  clear: left;
  float: left;
  padding-bottom: 8px;
  text-transform: capitalize;
  font: 400 14px "Poppins", sans-serif;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span {
    width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span span {
  width: auto;
  float: left;
  text-transform: capitalize;
  padding-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span p {
  float: left;
  text-transform: capitalize;
  padding: 0;
  margin: 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.Employed p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.Duration p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.job_city p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.job_state p,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.job_country p {
  color: #2e2e2e;
  padding-left: 8px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.positiont-visually {
  font: 600 16px "Poppins", sans-serif;
  color: #2e2e2e;
  padding-bottom: 8px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.company-visually {
  font: 400 16px "Poppins", sans-serif;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info span.description p {
  color: #303030e0;
  width: 100%;
  text-align: left;
  float: left;
  display: block;
  font: 500 13px/23px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info .action {
  width: 100%;
  float: left;
  padding: 10px 0 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .ExperienceField_view .Experience_details_info .field-summary-info .companyDetails_info .action a {
  color: #F6821F;
  text-transform: capitalize;
  float: left;
  display: block;
  font: 500 14px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp {
  float: left;
  margin-bottom: 30px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp {
    margin-top: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp label {
  width: 100%;
  font: 500 14px "Poppins", sans-serif;
  float: left;
  color: #a1a1a1;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
  border: 1px solid #ebebeb;
  margin-top: 12px;
  border-radius: 5px;
  width: 220px;
  height: 100px;
  cursor: pointer;
  float: left;
  background: url(./assets//img/uploader.jpg) no-repeat center;
  background-size: 98px;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
    width: 220px;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span {
    margin-top: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp span input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  opacity: 0;
  float: left;
  height: 100%;
}

@media (max-width: 2035px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    width: 220px;
    height: auto;
    float: left;
    padding-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    width: 220px;
    clear: left;
  }
}

@media (max-width: 576px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions {
    padding-top: 6px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions p {
  font: 500 13px "Poppins", sans-serif;
  color: #6d6e71;
  float: left;
  line-height: 21px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv .from_grp .actions a {
  font: 500 13px "Poppins", sans-serif;
  color: #F6821F;
  text-decoration: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .rpv button {
  color: #F6821F;
  text-transform: capitalize;
  margin-right: 10px;
  width: 125px;
  float: left;
  border-radius: 3px;
  margin: 0px 0;
  padding: 10px 0px;
  display: block;
  border: 1px solid #F6821F;
  text-decoration: none;
  font: 600 13px "Poppins", sans-serif;
  background-color: transparent;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 7px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.3fr 0.3fr 0.5fr;
  grid-template-columns: 0.3fr 0.3fr 0.5fr;
}

@media (max-width: 768px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill {
    -ms-grid-columns: 0.53fr 0.5fr 0.3fr;
    grid-template-columns: 0.53fr 0.5fr 0.3fr;
  }
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp {
  float: left;
  margin-bottom: 20px;
  margin-top: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp.order_priority select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp button {
  color: #F6821F;
  text-transform: capitalize;
  width: 125px;
  float: left;
  border-radius: 3px;
  margin: 0px 0;
  padding: 10px 0px;
  display: block;
  border: 1px solid #F6821F;
  text-decoration: none;
  font: 400 14px "Poppins", sans-serif;
  background-color: #fff;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp button {
    padding: 7px 0px;
    font: 600 12px "Poppins", sans-serif;
    width: 100px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp label {
  width: 100%;
  font: 500 14px "Poppins", sans-serif;
  float: left;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span {
  margin-top: 20px;
  border-radius: 5px;
  padding-right: 5px;
  width: 100%;
  cursor: pointer;
  float: left;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span {
    margin-top: 10px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select {
  max-width: 220px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  font: 500 15px "Poppins", sans-serif;
  color: #2e2e2e;
  border: 1px solid #ccc;
  padding: 0 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::-webkit-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select:-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select::placeholder {
  font: 400 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select {
    max-width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_rep .rpSkill .from_grp span select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets/img/expand_icon.svg) right 5% center no-repeat;
  background-size: 12px;
  appearance: none;
  color: #2e2e2e;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing {
  width: 100%;
  float: left;
  padding:  0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp {
  width: 90%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 16px 0;
display: grid;
grid-template-columns: 2fr 1fr 1fr;
grid-gap: 24px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp:last-child {
  border-bottom: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp label {
  font: 500 14px "Poppins", sans-serif;
  float: left;
  color: #6f6f6f;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .Skill_listing .from_grp button {
  font: 400 14px "Poppins", sans-serif;
  float: left;
  background: #fff;
  color: #FF4853;
  border-radius: 2px;
  margin:  0;
  border: 0;
  text-decoration: underline;
  display: block;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 0 0 7px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 0.3fr 0.3fr 0.5fr;
  grid-template-columns: 0.3fr 0.3fr 0.5fr;
}

@media (max-width: 991px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp {
  float: left;
  margin-bottom: 20px;
  margin-top: 0px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp.order_priority select {
  width: 83px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span {
  margin-top: 20px;
  border-radius: 5px;
  padding-right: 5px;
  width: 100%;
  float: left;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span {
    margin-top: 10px;
    padding-right: 0px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select {
  max-width: 220px;
  width: 100%;
  height: 32px;
  border-radius: 3px;
  font: 500 15px "Poppins", sans-serif;
  color: #2e2e2e;
  border: 1px solid #ccc;
  padding: 0 10px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::-webkit-inner-spin-button,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::-webkit-inner-spin-button {
  opacity: 0;
  -webkit-appearance: none;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::-webkit-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::-webkit-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input:-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select:-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::-ms-input-placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::-ms-input-placeholder {
  font: 400 13px "Poppins", sans-serif;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input::placeholder,
.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select::placeholder {
  font: 400 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {

  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span input,
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select {
    max-width: 100%;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets//img/icon/icons8-expand_arrow.png) right center no-repeat;
  background-size: 30px;
  appearance: none;
  color: #2e2e2e;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span.upload {
  border: 1px solid #ebebeb;
  margin-top: 0px;
  border-radius: 5px;
  width: 200px;
  cursor: pointer;
  height: 88px;
  float: left;
  background: url(./assets/img/uploader.jpg) no-repeat center;
  background-size: 98px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp span.upload input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
  opacity: 0;
  float: left;
  height: 100%;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp button {
  color: #F6821F;
  text-transform: capitalize;
  width: 125px;
  float: left;
  border-radius: 3px;
  margin: 0px 0;
  padding: 10px 0px;
  display: block;
  border: 1px solid #F6821F;
  text-decoration: none;
  font: 600 13px "Poppins", sans-serif;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp button {
    padding: 7px 0px;
    font: 600 12px "Poppins", sans-serif;
    width: 100px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_rep .rpCertificate .from_grp label {
  width: 100%;
  font: 500 14px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  text-transform: capitalize;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing {
  width: 100%;
  float: left;
  padding: 30px 0;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp {
  width: 300px;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  clear: left;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media (max-width: 480px) {
  .horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp {
    max-width: 100%;
    width: 300px;
  }
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp label {
  font: 500 12px "Poppins", sans-serif;
  float: left;
  color: #6d6e71;
  width: 100%;
  text-transform: capitalize;
  max-width: 150px;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .MyResume_area form .section .field_view .certificate_listing .from_grp button {
  font: 400 11px "Poppins", sans-serif;
  float: left;
  background: #fff;
  color: #FF4853;
  border-radius: 2px;
  margin: 0px 0;
  padding: 3px 8px;
  display: block;
  border: 1px solid #FF4853;
}

// Breakpoints
@mixin breakpoint($point) {
  @if $point==ex_large_laptop {
    @media (max-width: 1920px) {
      @content;
    }
  }

  @if $point==ex_large_desktop {
    @media (max-width: 1820px) {
      @content;
    }
  }

  @if $point==large_to_xl_laptop {
    @media (min-width: 1601px) {
      @content;
    }
  }

  @if $point==large_laptop {
    @media (max-width: 1440px) {
      @content;
    }
  }

  @if $point==standard_desktop {
    @media (max-width: 1366px) {
      @content;
    }
  }

  @if $point==medium_laptop {
    @media (max-width: 1310px) {
      @content;
    }
  }

  @if $point==macbook_pro {
    @media (max-width: 1280px) {
      @content;
    }
  }

  @if $point==med_lapi {
    @media (max-width: 1199px) {
      @content;
    }
  }

  @if $point==macbook {
    @media (max-width: 1189px) {
      @content;
    }
  }

  @if $point==ipad_pro {
    @media (max-width: 1024px) {
      @content;
    }
  }

  @if $point==medium_device {
    @media (max-width: 991px) {
      @content;
    }
  }

  @if $point==small_device {
    @media (max-width: 920px) {
      @content;
    }
  }

  @if $point==ipad {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $point==extra_small_device {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $point==mobile_landscape {
    @media only screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
      @content;
    }
  }

  @if $point==small_tabs {
    @media (max-width: 600px) {
      @content;
    }
  }

  @if $point==large_mobile {
    @media (max-width: 576px) {
      @content;
    }
  }

  @if $point==mobile {
    @media (max-width: 480px) {
      @content;
    }
  }

  @if $point==med_mobile {
    @media (max-width: 375px) {
      @content;
    }
  }

  @if $point==small_mobile {
    @media (max-width: 320px) {
      @content;
    }
  }
}

$primary-font: "Poppins", sans-serif;
$theme-color: #F6821F;
$color: #2e2e2e;

%shadow {
  -webkit-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  -ms-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  -o-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 2px 43px 0px rgba(0, 0, 0, 0.1);
}

%hover {
  z-index: 99;

  &::after {
    content: "";
    position: absolute;
    transition: top 0.3s ease-in 0.3s, width 0.3s ease-out, height 0.3s ease-out;
    -webkit-transition: top 0.3s ease-in 0.3s, width 0.3s ease-out,
      height 0.3s ease-out;
    left: 50%;
    top: -50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $theme-color;
  }

  &:hover {
    color: $color;
    transition: color 0.3s linear 0.3s;
    -webkit-transition: color 0.3s linear 0.3s;

    &::after {
      transition: top 0.3s ease-in, width 0.3s ease-out 0.3s,
        height 0.3s ease-out 0.3s;
      -webkit-transition: top 0.3s ease-in, width 0.3s ease-out 0.3s,
        height 0.3s ease-out 0.3s;
      top: 50%;
      width: 100px;
      height: 100px;
    }
  }
}

%speed {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

.horizon .dashboard_home .Reading_View .Reading_View_Container .Notifications_view {
  width: 100%;
  height: auto;
  float: left;

  .allNotifications {
    border-radius: 5px;
    padding: 24px;
    background: #fff;
    width: 100%;
    height: auto;
    float: left;

    @include breakpoint(large_mobile) {
      padding: 20px 20px;
    }

    @include breakpoint(large_mobile) {
      padding: 20px 15px;
    }

    .day_wise {
      width: 100%;
      height: auto;
      float: left;
      margin-bottom: 20px;

      .headView {
        width: 100%;
        height: auto;
        float: left;

        @include breakpoint(large_mobile) {
          padding: 0 0 10px;
        }

        .col {
          width: 100%;
          float: left;

          span {
            display: block;
            font: 600 13px $primary-font;
            color: $theme-color;
            float: right;

            @include breakpoint(large_mobile) {
              font: 600 11px $primary-font;
            }

            &.date {
              padding: 7px 30px;
              background: #fcf6ea;
              border-radius: 10px;
            }

            &.clear {
             
              position: relative;
              @extend %hover;
              overflow: hidden;
              border-radius: 4px;
              z-index: 99;

              &:hover {
                a {
                  color: #fff;
                  position: relative;
                  @extend %speed;
                }
              }

              a {
         
                color: #a1a1a1;
                z-index: 99;
                font-size: 14px;
                font-weight: 400;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .Notify_list {
        width: 100%;
        height: auto;
        float: left;
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          width: 100%;
          float: left;
          display: flex;
          flex-direction: row;
       margin-bottom: 16px;

          @include breakpoint(ipad) {
            margin-bottom: 0px;
          }

          .photo {
            width: 40px;
            height: 40px;
            float: left;
            overflow: hidden;
            border-radius: 50%;

            @include breakpoint(ipad) {
              height: 35px;
            }

            @include breakpoint(mobile) {
              height: 33px;
            }

            img {}
          }

          .time_view {
            color: #bbbbbb;
            border-bottom: 1px solid #e6e6e6;
            font: 500 13px $primary-font;

            @include breakpoint(large_mobile) {
              font: 500 11px $primary-font;
            }

            span {
              margin-bottom: 4px;
              float: left;
              text-align: end;
              width: 100%;
              color: #a1a1a1;
              font-size: 14px;
            }
          }

          .message {
            width: 100%;
            float: left;
            border-bottom: 1px solid #e6e6e6;

            @include breakpoint(large_mobile) {
              margin-left: 13px;
            }

            @include breakpoint(mobile) {}

            span {
              float: left;
              width: 100%;

              &.name {
                font: 400 14px $primary-font;
                color: #6f6f6f !important;

                @include breakpoint(large_mobile) {
                  font: 600 14px $primary-font;
                }
              }
            }
          }
        }
      }

      // Notify_list
      .load {
        display: block;
        font: 600 13px $primary-font;
        color: $theme-color;
        padding: 7px 10px;
        text-transform: capitalize;
        margin: auto;
        position: relative;
        overflow: hidden;
        border-radius: 4px;
        border: 0;
        background: transparent;
        z-index: 99999;
        position: relative;
        @extend %hover;

        &::after {
          z-index: -99;
        }

        &:hover {
          color: #fff;
          @extend %speed;
        }
      }
    }
  }
}

// Notifications

.load {
  display: block;
  font: 400 14px #F6821F;
  color: $theme-color;
  padding: 7px 10px;
  text-transform: capitalize;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 0;
  background: transparent;
  z-index: 99999;
  position: relative;
  @extend %hover;
  text-decoration: underline;

  &::after {
    z-index: -99;
  }

  &:hover {
    color: #fff;
    @extend %speed;
  }
}

.error-custom {
  font-size: 14px;
  color: red;
  margin-bottom: 12px;
}

.error-custom-checkbox {
  border: 1.5px solid red !important;
}

// have cut register styles IMP NOTE
.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group input[type="checkbox"].toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 32px;
  height: 18px;
  background: #ddd;
  vertical-align: middle;
  border-radius: 1.6em;
  position: relative;
  outline: 0;
  margin-right: 7px;
  margin-top: -1px;
  cursor: pointer;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label input[type="checkbox"] {
  border: 1px solid #F6821F;
  width: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 1px 10px 0 0;
  border-radius: 2px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  background: transparent;
  position: relative;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group input[type="checkbox"].toggle::after {
  content: "";
  top: -2px;
  width: 20px;
  height: 20px;
  background: white;
  position: absolute;
  border-radius: 1.2em;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: -2px;
  -webkit-box-shadow: 0 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px rgba(0, 0, 0, 0.5);
  -webkit-transition: left 0.1s ease-in-out;
  transition: left 0.1s ease-in-out;
}

.setp_candidate_in .create_horizon_setp .create_setp_fild form .block .fild .form-group label input[type="checkbox"] {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
}

// end of job search css
.Mburger.clicked {
  left: 14rem;
  z-index: 9999;
}

.horizon .filter-mob {
  z-index: 9999999999;
}

// start aman css

.error-custom-w {
  color: red;
  position: relative;
  top: 15px;
}


// end of job search css
.Mburger.clicked {
  left: 14rem;
  z-index: 99999;
}

.horizon .filter-mob {
  z-index: 9999999999;
  display: none;
}

// job search

.horizon {
  .Search-listing {
    .Search-row {
      .Jobs .block {
        justify-content: space-between;

        .details {
          padding: 0 5px 0 45px;
          flex: 1;

          @include breakpoint(large_mobile) {
            padding: 0 5px 0 0px;
            max-width: 100%;
          }
        }

        .moreView {
          justify-content: flex-start;

          @include breakpoint(large_mobile) {
            max-width: 100%;
          }

          a {
            margin-bottom: 8px;
          }
        }
      }
    }
  }
}

// end of job search

//start of job details page

.horizon {
  .Job-Detail-Page {
    .block {
      .details {
        max-width: 480px;
        padding: 0 5px 0 45px;

        @include breakpoint(large_mobile) {
          padding: 0 5px 0 0px !important;
          max-width: 100%;
        }
      }

      .moreView {
        // max-width: 190px;
        // justify-content: flex-start;

        @include breakpoint(large_mobile) {
          max-width: 100%;
        }

        a {
          margin-bottom: 5px;
        }
      }
    }
  }

  .Similar-Jobs-listing {
    .Jobs {
      .block {
        justify-content: space-between;

        .details {
          max-width: 480px;
          padding: 0 5px 0 45px;

          @include breakpoint(ipad) {
            padding: 0 5px 0 20px;
          }

          @include breakpoint(large_mobile) {
            padding: 0 5px 0 0px;
            max-width: 100%;
          }
        }

        .moreView {
          max-width: 190px;
          justify-content: flex-start;

          @include breakpoint(large_mobile) {
            max-width: 100%;
          }

          a {
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

//end of job details page

//start of candidate home page
.horizon {

  .How-it-works {
    .it-works {
      .colm {
        img {
          @include breakpoint(ipad) {
            max-width: 58px;
          }
        }
      }
    }
  }

  .Our-Partners {
    .PartnersView {
      .PartnerLogo {
        img {
          @include breakpoint(ipad) {
            padding: 15px 0;
          }
        }
      }
    }
  }
}

//end  of candidate home page

// start of contact us
.horizon {
  .contact_page {
    @include breakpoint(ipad) {
      grid-template-columns: 1fr;
    }

    .left_view {
      @include breakpoint(ipad) {
        padding: 40px 40px;
      }
    }

    .right_view {
      @include breakpoint(ipad) {
        padding: 40px 40px;
      }
    }
  }
}

// end of contact us

// start of my resume

.horizon {
  .dashboard_home {
    .Reading_View {
      .Reading_View_Container {
        .MyResume_area {
          form {
            .section {
              .field_view {
                .rpw {
                  .from_grp {
                    @include breakpoint(small_tabs) {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// end of resume

//  start of current openings

.horizon {
  .company-view {
    .company-current {
      .job-post {
        .Jobs {
          .block {
            justify-content: space-between;
              border: 1px solid #dedede;
            .details {
              padding: 0 5px 0 45px;

              @include breakpoint(large_mobile) {
                padding: 0 5px 0 0px;
                max-width: 100%;
              }
            }

            .moreView {
              max-width: 190px;
              justify-content: flex-start;

              @include breakpoint(large_mobile) {
                max-width: 100%;
              }

              a {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}

//  end of current openings

.candidate_in .view_horizon .view {
  padding: 0px 0 108px !important;
}


.loader-custom {
  float: right;
  margin-right: 5px;
  border: 3px solid #bcbcc2;
  border-radius: 50%;
  border-top: 3px solid #f47e38;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 0.5s linear infinite;
  animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  background-image: linear-gradient(to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%) !important;
  background-repeat: no-repeat !important;
  background-size: 800px 104px !important;
  display: inline-block !important;
  position: relative !important;
  -webkit-animation-duration: 1s !important;
  -webkit-animation-fill-mode: forwards !important;
  -webkit-animation-iteration-count: infinite !important;
  -webkit-animation-name: placeholderShimmer !important;
  -webkit-animation-timing-function: linear !important;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0% 0;
  }
}

// end of aman css

// start of jazeem css

form .block .fild textarea {
  color: #787e8c;
}

form .block .fild input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f0f0f0 inset !important;
  -webkit-text-fill-color: #787e8c !important;
}

form .block .fild input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #f0f0f0 inset !important;
  -webkit-text-fill-color: #787e8c !important;
}

.pagination-main {
  font: 15px "roboto", sans-serif;
}

a:active,
a:focus {
  outline: none;
}

.center {
  display: flex;
  justify-content: center;
  float: left;
  width: 100%;
  background-color: white;
}

@media (max-width: 577px) {
  .center img {
    width: 100%;
  }
}

.cmodal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  justify-content: center;
  align-items: center;
  padding: 50px;

  &.active {
    display: flex;
  }

  .cmodal-inner {
    width: 90%;
    max-width: 500px;
    min-height: 20vh;
    background: #fff;
    padding: 16px;

    .btn-row {
      display: flex;
      justify-content: center;
      width: 100%;

      button {
        max-width: 280px;
        text-decoration: none;
        text-align: center;
        border-radius: 5px;
        font: 500 15px "Poppins", sans-serif;
      }

      .crop {
        color: #fff;
        background: #F6821F;
        padding: 15px 20px;
        border: none;
      }

      .cancel {
        padding: 15px 20px;
        color: #F6821F;
        background: #fff;
        border: 1px solid #F6821F;
      }
    }
  }
}

.image-cropper-wrapper {
  width: 200px;
  margin: 0 auto 15px;
}

// end of jazeem css
.explore-more-link > a{
  color: #F6821F;
}
@media (max-width: 1140px){
  .job_actions{
    display: flex;
    flex-direction: column;
  }
  .btn_actions{
    margin-top: 24px;
  }
}
@media (max-width: 990px){
  .job_actions{
    display: flex;
    flex-direction: row;
  } 
}
@media (max-width: 480px){
  .job_filters_list{
    display: none;
  }
}
@media (max-width: 530px){
  .home-icon{
    display: none;
  }
}
@media (max-width: 860px){
  .horizon .Similar-Jobs-listing .Jobs .block{
    width: 100%;
    margin:  16px 0;
  }
}
.mat-expansion-panel{
  margin: 0 !important;
}
