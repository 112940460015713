/* iti custom css */
/* .iti {
  display: block !important;
  margin-bottom: 20px;
} */

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 8px;
  margin-left: 0;
  width: 220px;
}

.iti #phone {
  padding-left: 42px !important;
}

.iti .iti__country {
  display: flex;
  justify-content: space-between;
}

.iti .iti__country li {
  padding: 6px 0 !important;
}

.iti .iti__country-name {
  flex-grow: 1;
}

.iti .iti__country-name,
.iti .iti__dial-code {
  font-size: 12px !important;
}

.iti__flag-box,
.iti__country-name,
.iti__dial-code {
  align-items: center;
  align-content: center;
  align-self: center;
  padding-top: 0 !important;
  font-weight: 500 !important;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 12px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 12px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}
/* iti custom css */
/* .iti {
  display: block !important;
  margin-bottom: 20px;
} */

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 8px;
  margin-left: 0;
  width: 220px;
}

.iti #phone {
  padding-left: 42px !important;
  width: 350px !important;
  color: #2e2e2e;
  font-size: 16px;
}
@media (max-width: 400px){
  .iti #phone {
    width: 300px !important;
  }
}
.iti .iti__country {
  display: flex;
  justify-content: space-between;
}

.iti .iti__country li {
  padding: 6px 0 !important;
}

.iti .iti__country-name {
  flex-grow: 1;
}

.iti .iti__country-name,
.iti .iti__dial-code {
  font-size: 12px !important;
}

.iti__flag-box,
.iti__country-name,
.iti__dial-code {
  align-items: center;
  align-content: center;
  align-self: center;
  padding-top: 0 !important;
  font-weight: 500 !important;
}

.iti__flag-container.open + input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container input {
  font-size: 12px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 12px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

/* Custom CSS for custom checkbox */

.custom-select {
  width: 100%;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.select-box {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.options {
  display: none;
  position: unset;
  border: 1px solid #ccc;
  max-height: 300px;
  padding: 10px;
  overflow-y: auto;
  border-radius: 5px;
}

.option {
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 20px;
}

.checkbox {
  margin-right: 10px;
}

.get_field_value label > input[type="checkbox"] {
  min-width: 18px !important;
}

#checkboxes > label {
  padding: 4px;
}

#checkboxes > label:hover {
  background-color: #f36f21 !important;
  color: white;
}


